import React, { Component } from 'react';
import { Button, Col, Row, Form, Input } from 'reactstrap';
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomTable } from '../../Base/Common/CustomTable';
import { getAPI } from "../../Base/API";

class RecordsByEntityType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            records: [],
            columns: []
        }
    }

    componentDidMount() {
    }

    getRecords = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, records: data });
            }
        }, `/api/Rate/DashBoard/recordsbyEntityType?entityName=${this.state.entityName}&colName=${this.state.colName}&colValue=${this.state.colValue}`);
    }

    handleCombo = (field, columns, combo) => {
        this.setState({ [field]: combo ? combo.value : null });

        if (field === 'entityName' && combo) {
            this.createColumns(combo.value, columns);
        }
    }

    createColumns = (entityName, array) => {
        const colNames = array.find(entity => entity.value === entityName).columns;
        let columns = [];

        colNames && colNames.forEach(col => {
            columns.push({
                dataField: col.value,
                text: col.label,
                sort: true
            })
        });

        this.setState({ columns, colName: '', records: [] });
    }

    handleChange = (evt) => {
        this.setState({ [evt.target.name]: evt.target.value.trim() ?? null });
    }

    render() {

        const columnsByEntity = [
            {
                value: 'beconfig',
                label: 'BE Config',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'ReminderAbandonedMails', label: 'Reminder Abandoned Mails' },
                    { value: 'ReminderAbandonedMinutes', label: 'Reminder Abandoned Minutes' },
                    { value: 'CancelAfterHours', label: 'Cancel After Hours' },
                    { value: 'SendPendingReservationsToPms', label: 'Send Pending Reservations To Pms' },
                    { value: 'ConsiderReservationsPaidBankTransferOrCustomAsPending', label: 'Consider Reservations Paid Bank Transfer Or Custom As Pending' }
                ]
            },
            {
                value: 'beconfigaudit',
                label: 'BE Config Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'ReminderAbandonedMails', label: 'Reminder Abandoned Mails' },
                    { value: 'ReminderAbandonedMinutes', label: 'Reminder Abandoned Minutes' },
                    { value: 'CancelAfterHours', label: 'Cancel After Hours' },
                    { value: 'SendPendingReservationsToPms', label: 'Send Pending Reservations To Pms' },
                    { value: 'ConsiderReservationsPaidBankTransferOrCustomAsPending', label: 'Consider Reservations Paid Bank Transfer Or Custom As Pending' }
                ]
            },
            {
                value: 'cancellationPolicy',
                label: 'Cancellation Policy',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'Name', label: 'Name' },
                    { value: 'PercentageBefore', label: 'PercentageBefore' },
                    { value: 'DaysBefore', label: 'DaysBefore' },
                    { value: 'PercentageAfter', label: 'PercentageAfter' },
                    { value: 'CancelUntil', label: 'CancelUntil' },
                    { value: 'NoShowPenalty', label: 'NoShowPenalty' },
                    { value: 'Type', label: 'Type' },
                    { value: 'Active', label: 'Active' },
                    { value: 'HotelId', label: 'HotelId' }
                ]
            },
            {
                value: 'channelconfig',
                label: 'Channel Config',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Name', label: 'Name' },
                    { value: 'Code', label: 'Code' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Source', label: 'Source' },
                    { value: 'AppId', label: 'App Id' },
                    { value: 'LimitMaxStays', label: 'Limit Max Stays' },
                    { value: 'MaxDays', label: 'Max Days' },
                    { value: 'Urlprices', label: 'Url Prices' },
                    { value: 'Urlavailability', label: 'Url Availability' },
                    { value: 'Urlreservations', label: 'Url Reservations' },
                    { value: 'UrlreservationsModif', label: 'Url Reservations Modif' },
                    { value: 'UrlReservationsConfirmation', label: 'Url Reservations Confirmation' },
                    { value: 'ExtraUrl1', label: 'Extra Url 1' },
                    { value: 'ExtraUrl2', label: 'Extra Url 2' },
                    { value: 'ExtraUrl3', label: 'Extra Url 3' },
                    { value: 'Certificate', label: 'Certificate' },
                    { value: 'Supported', label: 'Supported' },
                    { value: 'IsCreatable', label: 'Is Creatable' },
                    { value: 'IsIcal', label: 'Is Ical' },
                    { value: 'Deleted', label: 'Deleted' }
                ]
            },
            {
                value: 'channelconfigaudit',
                label: 'Channel Config Audit',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Name', label: 'Name' },
                    { value: 'Code', label: 'Code' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Source', label: 'Source' },
                    { value: 'AppId', label: 'App Id' },
                    { value: 'LimitMaxStays', label: 'Limit Max Stays' },
                    { value: 'MaxDays', label: 'Max Days' },
                    { value: 'Urlprices', label: 'Url Prices' },
                    { value: 'Urlavailability', label: 'Url Availability' },
                    { value: 'Urlreservations', label: 'Url Reservations' },
                    { value: 'UrlreservationsModif', label: 'Url Reservations Modif' },
                    { value: 'UrlReservationsConfirmation', label: 'Url Reservations Confirmation' },
                    { value: 'ExtraUrl1', label: 'Extra Url 1' },
                    { value: 'ExtraUrl2', label: 'Extra Url 2' },
                    { value: 'ExtraUrl3', label: 'Extra Url 3' },
                    { value: 'Certificate', label: 'Certificate' },
                    { value: 'Supported', label: 'Supported' },
                    { value: 'IsCreatable', label: 'Is Creatable' },
                    { value: 'IsIcal', label: 'Is Ical' },
                    { value: 'Deleted', label: 'Deleted' }
                ]
            },
            {
                value: 'channelinstance',
                label: 'Channel Instance',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PropertyCode', label: 'Property Code' },
                    { value: 'User', label: 'User' },
                    { value: 'Password', label: 'Password' },
                    { value: 'SecondaryUser', label: 'Secondary User' },
                    { value: 'SecondaryPassword', label: 'Secondary Password' },
                    { value: 'Name', label: 'Name' },
                    { value: 'ChannelConfigId', label: 'Channel Config Id' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Active', label: 'Active' },
                    { value: 'MaxInventoryDays', label: 'Max Inventory Days' },
                    { value: 'PasswordExpirationDate', label: 'Password Expiration Date' },
                    { value: 'SendAvailability', label: 'Send Availability' },
                    { value: 'SendPrice', label: 'Send Price' },
                    { value: 'SendStopSales', label: 'Send Stop Sales' },
                    { value: 'SendCTA', label: 'Send CTA' },
                    { value: 'SendMinStay', label: 'Send Min Stay' },
                    { value: 'SendMaxStay', label: 'Send Max Stay' },
                    { value: 'LocalSecondaryPassword', label: 'Local Secondary Password' },
                    { value: 'LocalPassword', label: 'Local Password' },
                    { value: 'ICalUrl', label: 'Channel Config State' },
                    { value: 'ActivatedAt', label: 'Activated At' },
                ]
            },
            {
                value: 'channelinstanceaudit',
                label: 'Channel Instance Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PropertyCode', label: 'Property Code' },
                    { value: 'User', label: 'User' },
                    { value: 'Password', label: 'Password' },
                    { value: 'SecondaryUser', label: 'Secondary User' },
                    { value: 'SecondaryPassword', label: 'Secondary Password' },
                    { value: 'Name', label: 'Name' },
                    { value: 'ChannelConfigId', label: 'Channel Config Id' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Active', label: 'Active' },
                    { value: 'MaxInventoryDays', label: 'Max Inventory Days' },
                    { value: 'PasswordExpirationDate', label: 'Password Expiration Date' },
                    { value: 'SendAvailability', label: 'Send Availability' },
                    { value: 'SendPrice', label: 'Send Price' },
                    { value: 'SendStopSales', label: 'Send Stop Sales' },
                    { value: 'SendCTA', label: 'Send CTA' },
                    { value: 'SendMinStay', label: 'Send Min Stay' },
                    { value: 'SendMaxStay', label: 'Send Max Stay' },
                    { value: 'LocalSecondaryPassword', label: 'Local Secondary Password' },
                    { value: 'LocalPassword', label: 'Local Password' },
                    { value: 'ICalUrl', label: 'Channel Config State' },
                    { value: 'ActivatedAt', label: 'Activated At' }
                ]
            },
            {
                value: 'channelmapping',
                label: 'Channel Mapping',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Type', label: 'Type' },
                    { value: 'LocalCode', label: 'Local Code' },
                    { value: 'ChannelCode', label: 'Channel Code' },
                    { value: 'OTACode', label: 'OTA Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'channelmappingaudit',
                label: 'Channel Mapping Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Type', label: 'Type' },
                    { value: 'LocalCode', label: 'Local Code' },
                    { value: 'ChannelCode', label: 'Channel Code' },
                    { value: 'OTACode', label: 'OTA Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'complex',
                label: 'Complex',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ExternalId', label: 'External Id' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'PropertyTypeId', label: 'Property Type Id' },
                    { value: 'PropertyClassification', label: 'Property Classification' },
                    { value: 'MinPrice', label: 'Min Price' },
                    { value: 'RentalLicenceNumber', label: 'Rental Licence Number' },
                    { value: 'BETemplate', label: 'BE Template' },
                    { value: 'IsPublishedOnBe', label: 'Is Published On Be' },
                    { value: 'DataSentAzureAt', label: 'Data Sent Azure At' }
                ]
            },
            {
                value: 'complexaudit',
                label: 'Complex Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ExternalId', label: 'External Id' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'PropertyTypeId', label: 'Property Type Id' },
                    { value: 'PropertyClassification', label: 'Property Classification' },
                    { value: 'MinPrice', label: 'Min Price' },
                    { value: 'RentalLicenceNumber', label: 'Rental Licence Number' }
                ]
            },
            {
                value: 'externalpmsavailability',
                label: 'External PMS Availability',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'RoomCategoryId', label: 'Room Category Id' },
                    { value: 'HotelDate', label: 'Hotel Date' },
                    { value: 'BlockId', label: 'Block Id' },
                    { value: 'Inventory', label: 'Inventory' },
                    { value: 'OutOfInventory', label: 'Out Of Inventory' },
                    { value: 'OutOfOrder', label: 'Out Of Order' },
                    { value: 'WaitingList', label: 'Waiting List' },
                    { value: 'Optional', label: 'Optional' },
                    { value: 'Reserved', label: 'Reserved' },
                    { value: 'FreeRooms', label: 'Free Rooms' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'externalpmsavailabilityaudit',
                label: 'External PMS Availability Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'RoomCategoryId', label: 'Room Category Id' },
                    { value: 'HotelDate', label: 'Hotel Date' },
                    { value: 'BlockId', label: 'Block Id' },
                    { value: 'Inventory', label: 'Inventory' },
                    { value: 'OutOfInventory', label: 'Out Of Inventory' },
                    { value: 'OutOfOrder', label: 'Out Of Order' },
                    { value: 'WaitingList', label: 'Waiting List' },
                    { value: 'Optional', label: 'Optional' },
                    { value: 'Reserved', label: 'Reserved' },
                    { value: 'FreeRooms', label: 'Free Rooms' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            }, 
            {
                value: 'externalpmsrestriction',
                label: 'External PMS Restriction',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'HotelDate', label: 'Hotel Date' },
                    { value: 'RateCodeId', label: 'Rate Code Id' },
                    { value: 'StopSales', label: 'Stop Sales' },
                    { value: 'CloseToArrival', label: 'Close To Arrival' },
                    { value: 'CloseToDeparture', label: 'Close To Departure' },
                    { value: 'MinimumStayLen', label: 'Minimum Stay Len' },
                    { value: 'MaximumStayLen', label: 'Maximum Stay Len' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'externalpmsrestrictionaudit',
                label: 'External PMS Restriction Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'HotelDate', label: 'Hotel Date' },
                    { value: 'RateCodeId', label: 'Rate Code Id' },
                    { value: 'StopSales', label: 'Stop Sales' },
                    { value: 'CloseToArrival', label: 'Close To Arrival' },
                    { value: 'CloseToDeparture', label: 'Close To Departure' },
                    { value: 'MinimumStayLen', label: 'Minimum Stay Len' },
                    { value: 'MaximumStayLen', label: 'Maximum Stay Len' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'hotellicense',
                label: 'Hotel License',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'OperationMode', label: 'Operation Mode' },
                    { value: 'IsLocalPms', label: 'Local Pms' },
                    { value: 'Modules', label: 'Modules' },
                    { value: 'LicenseId', label: 'License Id' },
                    { value: 'ReservationDelivery', label: 'Reservation Delivery' },
                    { value: 'SendCMRoomRate', label: 'Send CM Room Rates' },
                    { value: 'PMSType', label: 'PMS Type' },
                    { value: 'RMSType', label: 'RMS Type' },
                    { value: 'LicensedRooms', label: 'Licensed Rooms' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'GoogleAnaliticsId', label: 'Google Analitics Id' }
                ]
            },
            {
                value: 'hotellicenseaudit',
                label: 'Hotel License Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'OperationMode', label: 'Operation Mode' },
                    { value: 'IsLocalPms', label: 'Local Pms' },
                    { value: 'Modules', label: 'Modules' },
                    { value: 'LicenseId', label: 'License Id' },        
                    { value: 'ReservationDelivery', label: 'Reservation Delivery' },
                    { value: 'SendCMRoomRate', label: 'Send CM Room Rates' },
                    { value: 'PMSType', label: 'PMS Type' },
                    { value: 'RMSType', label: 'RMS Type' },
                    { value: 'LicensedRooms', label: 'Licensed Rooms' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'GoogleAnaliticsId', label: 'Google Analitics Id' }
                ]
            },
            {
                value: 'package',
                label: 'Package',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'PackType', label: 'Pack Type' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'IsPriceModeComponent', label: 'Is Price Mode Component' }
                ]
            },
            {
                value: 'packageaudit',
                label: 'Package Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'PackType', label: 'Pack Type' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'IsPriceModeComponent', label: 'Is Price Mode Component' }
                ]
            },
            {
                value: 'packagecomponent',
                label: 'Package Component',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'PackageId', label: 'Package Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ChargeId', label: 'Charge Id' },
                    { value: 'ComponentType', label: 'Component Type' },
                    { value: 'ComponentPostType', label: 'Component Post Type' },
                    { value: 'Quantity', label: 'Quantity' },
                    { value: 'ComponentPrice', label: 'Component Price' },
                    { value: 'ComponentPriceChildrenGroup1', label: 'Component Price Children Group 1' },
                    { value: 'ComponentPriceChildrenGroup2', label: 'Component Price Children Group 2' },
                    { value: 'ComponentPriceChildrenGroup3', label: 'Component Price Children Group 3' },
                    { value: 'ChargeCode', label: 'Charge Code' },
                    { value: 'ChargeDescription', label: 'Charge Description' }
                ]
            },
            {
                value: 'packagecomponentaudit',
                label: 'Package Component Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'PackageId', label: 'Package Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ChargeId', label: 'Charge Id' },
                    { value: 'ComponentType', label: 'Component Type' },
                    { value: 'ComponentPostType', label: 'Component Post Type' },
                    { value: 'Quantity', label: 'Quantity' },
                    { value: 'ComponentPrice', label: 'Component Price' },
                    { value: 'ComponentPriceChildrenGroup1', label: 'Component Price Children Group 1' },
                    { value: 'ComponentPriceChildrenGroup2', label: 'Component Price Children Group 2' },
                    { value: 'ComponentPriceChildrenGroup3', label: 'Component Price Children Group 3' },
                    { value: 'ChargeCode', label: 'Charge Code' },
                    { value: 'ChargeDescription', label: 'Charge Description' }
                ]
            },
            {
                value: 'pricelist',
                label: 'Price List',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'DependencyMasterId', label: 'Dependency Master Id' },
                    { value: 'DependencyPercentage', label: 'Dependency Percentage' },
                    { value: 'DependencyAmount', label: 'Dependency Amount' },
                    { value: 'DependencyRoundDecimalPlaces', label: 'Dependency Round Decimal Places' },
                    { value: 'DependencyReferenceRoomCategory', label: 'Dependency Reference Room Category' },
                    { value: 'DependencyReferencePackage', label: 'Dependency Reference Package' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Currency', label: 'Currency' }
                ]
            },
            {
                value: 'pricelistaudit',
                label: 'Price List Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'Active', label: 'Active' },
                    { value: 'DependencyMasterId', label: 'Dependency Master Id' },
                    { value: 'DependencyPercentage', label: 'Dependency Percentage' },
                    { value: 'DependencyAmount', label: 'Dependency Amount' },
                    { value: 'DependencyRoundDecimalPlaces', label: 'Dependency Round Decimal Places' },
                    { value: 'DependencyReferenceRoomCategory', label: 'Dependency Reference Room Category' },
                    { value: 'DependencyReferencePackage', label: 'Dependency Reference Package' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'Currency', label: 'Currency' }
                ]
            },
            {
                value: 'ratecode',
                label: 'Rate Code',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'RoomCategoryId', label: 'Room Category Id' },
                    { value: 'PackageId', label: 'Package Id' },
                    { value: 'PricelistId', label: 'Price List Id' },
                    { value: 'InventoryMode', label: 'Inventory Mode' },
                    { value: 'InventorySharedWith', label: 'Inventory Shared With' },
                    { value: 'BlockId', label: 'Block Id' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Active', label: 'Active' },
                    { value: 'RestrictionSync', label: 'Restriction Sync' }
                ]
            },
            {
                value: 'ratecodeaudit',
                label: 'Rate Code Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'RoomCategoryId', label: 'Room Category Id' },
                    { value: 'PackageId', label: 'Package Id' },
                    { value: 'PricelistId', label: 'Price List Id' },
                    { value: 'InventoryMode', label: 'Inventory Mode' },
                    { value: 'InventorySharedWith', label: 'Inventory Shared With' },
                    { value: 'BlockId', label: 'Block Id' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Active', label: 'Active' },
                    { value: 'RestrictionSync', label: 'Restriction Sync' }
                ]
            },
            {
                value: 'ratecodedetails',
                label: 'Rate Code Details',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'CancelationPolicyId', label: 'Cancelation Policy Id' },
                    { value: 'BreakfastIncluded', label: 'Breakfast Included' },
                    { value: 'InternetIncluded', label: 'Internet Included' },
                    { value: 'ParkingIncluded', label: 'Parking Included' },
                    { value: 'MaxOcc', label: 'Max Occ' },
                    { value: 'RateCodeId', label: 'Rate Code Id' }
                ]
            },
            {
                value: 'ratecodechannel',
                label: 'Rate Code Channel',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'RateCodeId', label: 'Rate Code Id' },
                    { value: 'Active', label: 'Active' },
                    { value: 'RateCodeMapping', label: 'Rate Code Mapping' },
                    { value: 'RoomTypeMapping', label: 'Room Type Mapping' },
                    { value: 'AvailabilityGroupMapping', label: 'Availability Group Mapping' },
                    { value: 'ExtraMapping1', label: 'Extra Mapping 1' },
                    { value: 'ExtraMapping2', label: 'Extra Mapping 2' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ExternalId', label: 'External Id' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'PriceMode', label: 'Price Mode' },
                    { value: 'InterfaceMode', label: 'Interface Mode' },
                    { value: 'PricePosition', label: 'Price Position' },
                    { value: 'PriceMarkupType', label: 'Price Markup Type' },
                    { value: 'PriceMarkupValue', label: 'Price Markup Value' },
                    { value: 'DeactivatedBy', label: 'Deactivated By' },
                    { value: 'DeactivatedOn', label: 'Deactivated On' },
                    { value: 'ICalUrl', label: 'ICal Url' }
                ]
            },
            {
                value: 'ratecodechannelaudit',
                label: 'Rate Code Channel Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'RateCodeId', label: 'Rate Code Id' },
                    { value: 'Active', label: 'Active' },
                    { value: 'RateCodeMapping', label: 'Rate Code Mapping' },
                    { value: 'RoomTypeMapping', label: 'Room Type Mapping' },
                    { value: 'AvailabilityGroupMapping', label: 'Availability Group Mapping' },
                    { value: 'ExtraMapping1', label: 'Extra Mapping 1' },
                    { value: 'ExtraMapping2', label: 'Extra Mapping 2' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ExternalId', label: 'External Id' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'PriceMode', label: 'Price Mode' },
                    { value: 'InterfaceMode', label: 'Interface Mode' },
                    { value: 'PricePosition', label: 'Price Position' },
                    { value: 'PriceMarkupType', label: 'Price Markup Type' },
                    { value: 'PriceMarkupValue', label: 'Price Markup Value' },
                    { value: 'DeactivatedBy', label: 'Deactivated By' },
                    { value: 'DeactivatedOn', label: 'Deactivated On' },
                    { value: 'ICalUrl', label: 'ICal Url' }
                ]
            },
            {
                value: 'reservation',
                label: 'Reservation',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'Version', label: 'Version' }, 
                    { value: 'ResIdDate', label: 'Res Id Date' },
                    { value: 'ResIdValue', label: 'Res Id Value' },
                    { value: 'AmountAfterTax', label: 'Amount After Tax' }, 
                    { value: 'CurrencyCode', label: 'Currency Code' },
                    { value: 'GuestCount', label: 'Guest Count' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'AmountCommission', label: 'Amount Commission' },
                    { value: 'Email', label: 'Email' },
                    { value: 'Status', label: 'Status' },
                    { value: 'CommunicationStatus', label: 'Communication Status' },
                    { value: 'AmountPayed', label: 'Amount Payed' },
                    { value: 'ResIdLastUpdatedDate', label: 'Res Id Last Updated Date' },
                    { value: 'Source', label: 'Source' },
                    { value: 'CancelReason', label: 'Cancel Reason' },
                    { value: 'CancelDescription', label: 'Cancel Description' },
                    { value: 'NoShow', label: 'No Show' },
                    { value: 'CancelFee', label: 'Cancel Fee' },
                    { value: 'ChannelReservationId', label: 'Channel Reservation Id' },
                    { value: 'SentToPmsat', label: 'Sent To Pms At' },
                ]
            },
            {
                value: 'reservation_hist',
                label: 'Reservation Hist',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'ResIdDate', label: 'Res Id Date' },
                    { value: 'ResIdValue', label: 'Res Id Value' },
                    { value: 'AmountAfterTax', label: 'Amount After Tax' },
                    { value: 'CurrencyCode', label: 'Currency Code' },
                    { value: 'GuestCount', label: 'Guest Count' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'ChannelInstanceId', label: 'Channel Instance Id' },
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'AmountCommission', label: 'Amount Commission' },
                    { value: 'Email', label: 'Email' },
                    { value: 'Status', label: 'Status' },
                    { value: 'CommunicationStatus', label: 'Communication Status' },
                    { value: 'AmountPayed', label: 'Amount Payed' },
                    { value: 'ResIdLastUpdatedDate', label: 'Res Id Last Updated Date' },
                    { value: 'Source', label: 'Source' },
                    { value: 'CancelReason', label: 'Cancel Reason' },
                    { value: 'CancelDescription', label: 'Cancel Description' },
                    { value: 'NoShow', label: 'No Show' },
                    { value: 'CancelFee', label: 'Cancel Fee' },
                    { value: 'ChannelReservationId', label: 'Channel Reservation Id' },
                    { value: 'SentToPmsat', label: 'Sent To Pms At' }
                ]
            }, 
            {
                value: 'roomcategory',
                label: 'Room Category',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'ComplexId', label: 'Complex Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'LevelId', label: 'Level Id' },
                    { value: 'Nextlevel', label: 'Next level' },
                    { value: 'IsDummyCategory', label: 'Is Dummy Category' },
                    { value: 'DefPax', label: 'Def Pax' },
                    { value: 'AdditionalBeds', label: 'Additional Beds' },
                    { value: 'PlanningOrder', label: 'Planning Order' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'roomcategoryaudit',
                label: 'Room Category Audit',
                columns: [
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'Id', label: 'Id' },
                    { value: 'ComplexId', label: 'Complex Id' },
                    { value: 'Code', label: 'Code' },
                    { value: 'Description', label: 'Description' },
                    { value: 'LevelId', label: 'Level Id' },
                    { value: 'Nextlevel', label: 'Next level' },
                    { value: 'IsDummyCategory', label: 'Is Dummy Category' },
                    { value: 'DefPax', label: 'Def Pax' },
                    { value: 'AdditionalBeds', label: 'Additional Beds' },
                    { value: 'PlanningOrder', label: 'Planning Order' },
                    { value: 'SortOrder', label: 'Sort Order' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'PmsId', label: 'Pms Id' },
                    { value: 'PmsTimestamp', label: 'Pms Timestamp' }
                ]
            },
            {
                value: 'roomcategorydetails',
                label: 'Room Category Details',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'HotelId', label: 'HotelId' },
                    { value: 'BathroomType', label: 'Bathroom Type' },
                    { value: 'MaxOccupancy', label: 'Max Occupancy' },
                    { value: 'MinimumSellingPrice', label: 'Minimum Selling Price' },
                    { value: 'RoomCategoryId', label: 'RoomCategory Id' },
                    { value: 'RoomSize', label: 'Room Size' },
                    { value: 'RoomType', label: 'Room Type' },
                    { value: 'NumberOfRooms', label: 'Number Of Rooms' },
                    { value: 'LimitAvailability', label: 'Limit Availability' },
                    { value: 'CityTaxId', label: 'City Tax Id' }
                ]
            },
            {
                value: 'user',
                label: 'User',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'defaultProperty', label: 'Default Property' },
                    { value: 'UID', label: 'UID' },
                    { value: 'Name', label: 'Name' },
                    { value: 'Surname', label: 'Surname' },
                    { value: 'Email', label: 'Email' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                ]
            },
            {
                value: 'yieldRules',
                label: 'Yield Rules',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'RuleType', label: 'Rule Type' },
                    { value: 'Name', label: 'Name' },
                    { value: 'AvailabilityType', label: 'Availability Type' },
                    { value: 'AvailabilityValue', label: 'Availability Value' },
                    { value: 'ApplyType', label: 'Apply Type' },
                    { value: 'ApplyValue', label: 'Apply Value' },
                    { value: 'DateFrom', label: 'Date From' },
                    { value: 'DateTo', label: 'Date To' },
                    { value: 'Weekdays', label: 'Weekdays' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'Active', label: 'Active' },
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'ReleaseDays', label: 'Release Days' }
                ]
            },
            {
                value: 'yieldRulesAudit',
                label: 'Yields Rules Audit',
                columns: [
                    { value: 'Id', label: 'Id' },
                    { value: 'RuleType', label: 'Rule Type' },
                    { value: 'Name', label: 'Name' },
                    { value: 'AvailabilityType', label: 'Availability Type' },
                    { value: 'AvailabilityValue', label: 'Availability Value' },
                    { value: 'ApplyType', label: 'Apply Type' },
                    { value: 'ApplyValue', label: 'Apply Value' },
                    { value: 'DateFrom', label: 'Date From' },
                    { value: 'DateTo', label: 'Date To' },
                    { value: 'Weekdays', label: 'Weekdays' },
                    { value: 'CreatedAt', label: 'Created At' },
                    { value: 'CreatedBy', label: 'Created By' },
                    { value: 'UpdatedAt', label: 'Updated At' },
                    { value: 'UpdatedBy', label: 'Updated By' },
                    { value: 'Version', label: 'Version' },
                    { value: 'Deleted', label: 'Deleted' },
                    { value: 'Active', label: 'Active' },
                    { value: 'HotelId', label: 'Hotel Id' },
                    { value: 'ReleaseDays', label: 'Release Days' }
                ]
            }
        ]

        const columnsName = this.state.entityName ? columnsByEntity.find(entity => entity.value === this.state.entityName).columns : null;

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-database'} title="navbar.RecordsByEntityType">
                <Form onSubmit={this.getRecords}>
                    <Row className="align-items-center">
                        <Col className="col-3">
                            <CustomSelect
                                isSearchable
                                options={columnsByEntity}
                                onChange={this.handleCombo.bind(this, 'entityName', columnsByEntity)}
                                placeholder={<FormattedMessage id="RecordsByEntityType.Entity" />}
                                required
                            />
                        </Col>
                        <Col className="col-3">
                            <CustomSelect
                                isSearchable
                                options={columnsName ? columnsName : ''}
                                value={this.state.colName && columnsName ? columnsName.find(el => el.value === this.state.colName) : ''}
                                onChange={this.handleCombo.bind(this, 'colName', null)}
                                placeholder={<FormattedMessage id="RecordsByEntityType.Column" />}
                                isDisabled={!this.state.entityName}
                                required
                            />
                        </Col>
                        <Col>
                            <Input
                                type="text"
                                name="colValue"
                                onChange={this.handleChange.bind(this)}
                                value={this.state.colValue}
                                placeholder={this.props.intl.formatMessage({ id: "RecordsByEntityType.Value" })}
                                required
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="primary btn-host btn-sm" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {this.state.columns && this.state.columns.length > 0 ?
                    <Row className="mt-3" style={{ overflowX: 'auto' }}>
                        <Col>
                            <CustomTable
                                data={this.state.records}
                                columns={this.state.columns}
                                hidePageListOnlyOnePage={true}
                            />
                        </Col>
                    </Row>
                    : ''}
            </StyledCard>
        );
    }

};
export default injectIntl(RecordsByEntityType);