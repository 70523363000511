import React, { Component } from 'react'

export default class BulkPricesCell extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hasChangeRule: false,
            hasOverrideRule: false,
            hasComplexRule: false,
            hasAutomaticClosure: false,
            hasReleaseDays: false
        };
    }

    componentDidMount(){
        const { ruleKey, findRule, mapping, d, avail, getPercentage, getHighestComplexPercentage, getHighestPercentage, anyDay } = this.props;
        
        const isHost = global.PMSType === 'Host' || global.PMSType === 'None';
        
        let hasChangeRule = null, hasOverrideRule = null, hasComplexRule = null, hasAutomaticClosure = null, hasReleaseDays = null;

        if(anyDay){
            const highestPercentage = getHighestPercentage();
            const highestComplexPercentage = getHighestComplexPercentage();

            hasChangeRule = findRule("Price Change", true, null, mapping.channelInstanceId)
            .filter(rule => (rule.availabilityType === "Percent" && rule.availabilityValue <= highestPercentage) || (rule.availabilityType === "Amount" && rule.availabilityValue >= avail?.freeRooms)
            || (rule.availabilityType === "Percent" && rule.availabilityValue <= avail?.inventory))
            .length > 0;

            hasOverrideRule = findRule("Price Override", true, null, mapping.channelInstanceId).length > 0;
            hasComplexRule = findRule("Price Override By Complex Availability", true, null, mapping.channelInstanceId)
            .filter(rule => (rule.availabilityType === "Percent" && highestComplexPercentage >= rule.availabilityValue) ||
                (rule.availabilityType === "Amount" && rule.availabilityValue >= avail?.complexFreeRooms)).length > 0;

            hasAutomaticClosure = findRule("Automatic Closure", true, null, mapping.channelInstanceId).length > 0;

            hasReleaseDays = findRule("Release Days", true, null, mapping.channelInstanceId).length > 0;
        }
        else {
            const percentage = getPercentage(avail, isHost);

            const hotelPercentage = 100 - (avail?.complexFreeRooms / avail?.complexInventory) * 100;

            if(d){
                hasChangeRule = findRule("Price Change", d, ruleKey, mapping.channelInstanceId)
                .filter(rule => (rule.availabilityType === "Percent" && rule.availabilityValue <= percentage) || (rule.availabilityType === "Amount" && rule.availabilityValue >= avail.freeRooms)
                || (rule.availabilityType === "Percent" && rule.availabilityValue <= avail?.inventory))
                .length > 0;

                hasOverrideRule = findRule("Price Override", d, ruleKey, mapping.channelInstanceId).length > 0;

                hasComplexRule = findRule("Price Override By Complex Availability", d, ruleKey, mapping.channelInstanceId)
                .filter(rule => (rule.availabilityType === "Percent" && hotelPercentage >= rule.availabilityValue) ||
                    (rule.availabilityType === "Amount" && rule.availabilityValue >= avail?.complexFreeRooms)).length > 0;
                    
                hasAutomaticClosure = findRule("Automatic Closure", d, ruleKey, mapping.channelInstanceId).length > 0;
                
                hasReleaseDays = findRule("Release Days", d, ruleKey, mapping.channelInstanceId).length > 0;
            };
        }

        this.setState({
            hasChangeRule,
            hasOverrideRule,
            hasComplexRule,
            hasAutomaticClosure,
            hasReleaseDays
        })
    }
    render() {
        const { hasChangeRule, hasOverrideRule, hasComplexRule, hasAutomaticClosure, hasReleaseDays } = this.state;

        return (
            <div className=" h-100 d-flex align-items-center">
                {hasComplexRule && hasOverrideRule && hasChangeRule ?
                    <div style={{
                        width: '10px', height: '10px', background: 'linear-gradient(to right, #42b742, #42b742 33%, #e37a4a 33%, #e37a4a 67%, #ffde31 67%)',
                        borderRadius: '20px', display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasComplexRule && hasOverrideRule ?
                    <div className='mr-2' style={{
                        width: '10px', height: '10px', background: 'linear-gradient(135deg, #42b742 50%, rgb(255, 222, 49) 50%)', borderRadius: '20px',
                        display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasComplexRule && hasChangeRule ?
                    <div style={{
                        width: '10px', height: '10px', background: 'linear-gradient(135deg, rgba(227,122,74,1) 50%, rgb(255, 222, 49) 50%)',
                        borderRadius: '20px', display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasOverrideRule && hasChangeRule ?
                    <div style={{
                        width: '10px', height: '10px', background: 'linear-gradient(135deg, rgba(227,122,74,1) 50%, rgba(66,183,66,1) 50%)',
                        borderRadius: '20px', display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasComplexRule ?
                    <div style={{
                        width: '10px', height: '10px', background: 'rgb(255, 222, 49)',
                        borderRadius: '20px', display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasChangeRule ?
                    <div className='mr-2' style={{
                        width: '10px', height: '10px', background: '#42b742', borderRadius: '20px',
                        display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : hasOverrideRule ?
                    <div style={{
                        width: '10px', height: '10px', background: '#e37a4a', borderRadius: '20px',
                        display: 'inline-block', verticalAlign: 'baseline'
                    }} />
                : ''}


                {hasAutomaticClosure || hasReleaseDays ?
                    <i className="fas fa-times-circle ml-1 text-danger" style={{ fontSize: '10px' }} />
                : ''}
            </div>

        )
    }
}
