import React, { Component } from 'react';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Row, Col, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI } from "../../Base/API"
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import Timeline from '../../Base/Common/Timeline';
import TimelineElement from '../../Base/Common/TimelineElement';
import '../../Base/Common/Timeline.css';
import '../../Base/Common/TimelineElement.css';
import CustomSelect from '../../Base/Common/CustomSelect'


const DisplayIcon = ({ type, result }) => {

    var badgecolor = "badge-secondary";
    if (result === 2) badgecolor = "badge-danger";
    else if (result === 3) badgecolor = "badge-warning text-white";
    var classname = "";
    if (type === 'Price') classname = "fas fa-2x fa-dollar-sign"
    else if (type === 'Reservation') classname = "fas fa-2x fa-key"
    else if (type === 'Connection') classname = "fas fa-2x fa-plug"
    else if (type === 'Availability') classname = "fas fa-2x fa-calendar-alt"
    else if (type === 'Listing') classname = "fas fa-2x fa-list"

    return <span className={'badge ' + badgecolor} style={{ width: '28px' }}><i className={classname}></i></span>;
}

class Events extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            detailBlock: false,
            modal: false,
            // Search Criteria
            startDate: moment().subtract(2, "days"),
            endDate: moment().add(1, "days"),
            selectedErrorType: "",
            selectedServiceType: "",
            reservationid: "",
            // pagination control
            resultsPerPage: 10,
            currentPage: 1,
            defaultResultsPerPage: 10,
            lastSelected: 0,
            totalEvents: 0,
            // Combobox information
            services: [
                {
                    "value": "Availability,Price,Restriction",
                    "label": <FormattedMessage id="LogsEvents.Ari" />
                },
                {
                    "value": "Reservation",
                    "label": <FormattedMessage id="LogsEvents.Reservation" />
                },
                {
                    "value": "Connection,Inventory",
                    "label": <FormattedMessage id="LogsEvents.Connection" />
                },
                {
                    "value": "Listing",
                    "label": <FormattedMessage id="LogsEvents.Listing" />
                },
            ],
            errors: [
                {
                    "value": "1",
                    "label": <FormattedMessage id="LogsEvents.Success" />
                },
                {
                    "value": "2",
                    "label": <FormattedMessage id="LogsEvents.Error" />
                },
                {
                    "value": "3",
                    "label": <FormattedMessage id="LogsEvents.Warning" />
                }
            ],
            events: [],
            selectedEvent: null,
            detailChannel: null,
            displayChannelDataEntries: 0
        };
    }

    componentDidMount() {
        moment.locale('en', {
            calendar: {
                lastDay: '[Yesterday at] LTS',
                sameDay: '[Today at] LTS',
                lastWeek: '[Last] dddd [at] LTS',
                sameElse: 'L'
            }
        });

        moment.locale('pt', {
            calendar: {
                lastDay: '[Ontem às] LTS',
                sameDay: '[Hoje às] LTS',
                lastWeek: '[Última] dddd [às] LTS',
                sameElse: 'L'
            }
        });

        this.searchEvents();
    }

    viewEventDetails = (id, channel, evt) => {
        this.setState({ detailBlock: true, lastSelected: id, detailChannel: channel });

        getAPI(result => {
            const { data, error } = result;

            this.setState({ detailBlock: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, detailBlock: false });


                return;
            }
            if (data) {
                this.setState({
                    selectedEvent: data.data,
                    displayChannelDataEntries: data.data?.channelData ? data.data.channelData.length > this.state.pageSize ? this.state.pageSize : data.data.channelData.length : 0,
                    detailBlock: false
                });
            }
            else this.setState({ detailBlock: false });
        }, '/api/User/Events/v1/' + id);
    }

    search = () => {
        this.setState({
            resultsPerPage: this.state.defaultResultsPerPage,
            currentPage: 1,
            selectedEvent: null,
            lastSelected: 0
        }, this.searchEvents);
    }

    changePage = (evt, val) => {
        this.setState({ currentPage: val }, this.searchEvents);
    }

    searchEvents = () => {
        const currentPage = this.state.currentPage - 1;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;            

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ events: data.data, totalEvents: Math.ceil(data.count / this.state.resultsPerPage), block: false });
            }
            else this.setState({ block: false });

        }, '/api/User/Events/v1/PMSChannelEvents?datai=' + this.state.startDate.format('YYYY-MM-DD') +
            '&dataf=' + this.state.endDate.format('YYYY-MM-DD') +
            '&error=' + this.state.selectedErrorType +
            '&service=' + this.state.selectedServiceType +
            '&reservationid=' + this.state.reservationid +
            '&pageSize=' + this.state.resultsPerPage +
            '&pageIndex=' + (currentPage * this.state.resultsPerPage));
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        if (targetValue.indexOf('Reservation') >= 0)
            this.setState({ [evt]: targetValue })
        else
            this.setState({ [evt]: targetValue, reservationid: '' })

    }

    setChannel = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(actionMeta)) {
            targetValue = actionMeta.map(el => el.value).join(',');
        } else {
            targetValue = actionMeta ? actionMeta.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    handleCurrentPage = (evt) => {
        if (evt) {
            let { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    prepareSearchEvents = () => {
        const { currentPage } = this.state;
        if (!currentPage || currentPage < 1) {
            this.setState({ currentPage: 1 }, this.searchEvents);
        }
        else {
            this.searchEvents();
        }
    }

    loadMoreFunc = () => {
        const { selectedEvent, displayChannelDataEntries, pageSize } = this.state;
        const nextPage = displayChannelDataEntries + pageSize;

        this.setState({ displayChannelDataEntries: selectedEvent?.channelData?.length > nextPage ? nextPage : selectedEvent?.channelData?.length });
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Row>
                        <Col className="col-12 ">
                            <Card className="small bg-white border-top-0 border-right-0 border-left-0">
                                <CardBody className="pt-2 px-2">
                                    <Row>
                                        <Col className="col-2 pr-1">
                                            <CustomSelect
                                                icon={'fa fa-exclamation-triangle fa-fw'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="LogsEvents.Status" />}
                                                options={this.state.errors}
                                                value={this.state.errors.filter(el => el.value == this.state.selectedErrorType)}
                                                onChange={this.setComboStatus.bind(this, 'selectedErrorType')}
                                            />
                                        </Col>
                                        <Col className="col-2 pr-1">
                                            <CustomSelect
                                                icon={'fa fa-filter fa-fw'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="LogsEvents.EventType" />}
                                                options={this.state.services}
                                                onChange={this.setComboStatus.bind(this, 'selectedServiceType')}
                                            />
                                        </Col>

                                        {this.state.selectedServiceType && this.state.selectedServiceType.indexOf('Reservation') >= 0 ?
                                            <Col className="col-2 pr-1">
                                                <InputGroup size="sm text-right ">
                                                    <InputGroupAddon addonType="prepend">
                                                        <span className="input-group-text border-right-0">
                                                            <span className="fas fa-key fa-fw"></span>
                                                        </span>
                                                    </InputGroupAddon>
                                                    <FormattedMessage id="ReservationList.ReservationId">
                                                        {placeholder =>
                                                            <Input
                                                                className="border-left-0" type="text"
                                                                name="reservationid" maxLength="50" id="reservationid"
                                                                placeholder={placeholder}
                                                                value={this.state.reservationid}
                                                                onChange={(e) => this.setState({ reservationid: e.currentTarget.value })}
                                                            />
                                                        }
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        : ''}

                                        <Col className="col-3">
                                            <InputGroup size="sm text-right " >
                                                <DateRangePicker
                                                    startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                    startDateId="your_unique_start_date_id"
                                                    endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                    endDateId="endDate"
                                                    isOutsideRange={day => day >= moment().add(1, 'days') || day < moment().subtract(6, 'month')}
                                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                                    focusedInput={this.state.focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col className="text-right">
                                            <button className="btn btn-host shadow btn-sm" onClick={this.search}><span className="fas fa-search"></span></button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <div className="small mx-2 mt-2">
                        <Timeline layout='1-column' selectedEvent={this.state.selectedEvent} selectedChannel={this.state.detailChannel} block={this.state.detailBlock} displayChannelDataEntries={this.state.displayChannelDataEntries} loadMoreFunc={this.loadMoreFunc}>
                            {
                                this.state.events && this.state.events.map((item, key) =>
                                    <div key={key}>
                                        <TimelineElement
                                            icon={<DisplayIcon type={item.eventType} result={item.result} />}
                                            date={moment(item.createAt).locale(this.props.intl.locale).format("YYYY-MM-DD HH:mm:ss")}
                                            user={item.userName}
                                            channel={item.channelConfigName}
                                            eventId={item.id}
                                            propertyCode={item.propertyCode}
                                            lastSelected={this.state.lastSelected}
                                            viewEventDetails={this.viewEventDetails}
                                            processStatus={item.processStatus}
                                        >
                                            <div className="timeline-element-subtitle">
                                                {item.eventType === "Price" ? <FormattedMessage id={'PMSLogs.' + item.eventType + '.' + item.action} /> : ''}
                                                {item.eventType === "Connection" ? <FormattedMessage id={'PMSLogs.' + item.eventType + '.' + item.action} /> : ''}
                                                {item.eventType === "Reservation" ? <FormattedMessage id={'PMSLogs.' + item.eventType + '.' + item.action} values={{ ids: item.ids }}/> : ''}
                                                {item.eventType === "Availability" ? <FormattedMessage id={'PMSLogs.' + item.eventType + '.' + item.action} /> : ''}
                                                {item.eventType === "Listing" ? <FormattedMessage id={'PMSLogs.' + item.eventType + '.' + item.action} /> : ''}
                                            </div>
                                        </TimelineElement>
                                    </div>
                                )
                            }

                            <Row className="mt-2">
                                <Col className="text-center">
                                    <Row>
                                        <Col className="col-1">
                                            <Button className="btn-host shadow btn-sm float-left" style={{ opacity: '1' }} disabled={!this.state.currentPage || this.state.currentPage <= 1} onClick={() => this.changePage(this, parseInt(this.state.currentPage) - 1)}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                        </Col>
                                        <Col>
                                            <span>
                                                <strong>
                                                    <Row>
                                                        <Col />
                                                        <Col className="col-3 float-right" style={{ paddingRight: '0', fontSize: '20px' }}>
                                                            <FormattedMessage id="LogsEvents.Page" />
                                                        </Col>
                                                        <Col className="col-2" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                            <Input type="number" id="currentPage" name="currentPage" min="1" max={this.state.totalEvents} onChange={this.handleCurrentPage} value={this.state.currentPage !== null ? this.state.currentPage : ''} onBlur={this.prepareSearchEvents} />
                                                        </Col>
                                                        <Col className="col-2 float-left" style={{ paddingLeft: '0', fontSize: '20px' }}>
                                                            / {this.state.totalEvents}
                                                        </Col>
                                                        <Col />
                                                    </Row>
                                                </strong>
                                            </span>
                                        </Col>
                                        <Col className="col-1">
                                            <Button className="btn-host btn-sm shadow float-right" disabled={this.state.events?.length < this.state.resultsPerPage} onClick={() => this.changePage(this, parseInt(this.state.currentPage) + 1)}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Timeline>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(Events);
