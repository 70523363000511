import moment from 'moment';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-dates/initialize';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, CustomInput, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { CustomTable } from '../../Base/Common/CustomTable';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { handleNotification } from "../../Base/Notification"
import AirbnbReview from './AirbnbReview';
import AirbnbReviewMobile from './AirbnbReviewMobile';
import { AirbnbReviewDetail } from './AirbnbReviewDetail';

class AirbnbReviewCard extends Component {

    constructor(props) {
        super(props);
        
        this.form = React.createRef();
        this.reloadReviewInfo = this.reloadReviewInfo.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.filterReply = this.filterReply.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.state = {
            error: null,
            globalReviews: null,
            block: true,
            modal: false,
            date: moment(),
            selectedConnection: null,
            channelinstanceList: [],
            reviewsList: [],
            modalData: {},
            // search criteria
            reviwerRole: 'guest',
            reservationId: null,
            listingId: null,
            Alerts: [],
            selectedReplyStatus: false,
            resultsPerPage: 10,
            currentPage: 0
        };

    }


    componentDidMount() {

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var id = '';

                var ActiveData = data.filter(el => el.active === true);

                if (ActiveData.length > 0)
                    id = ActiveData[0].id;

                this.setState({ channelinstanceList: ActiveData, selectedConnection: id, block: false, error: errorMessage }, () => {
                    if (id) {
                        this.retrieveGlobalReview()
                    }
                });
               
            }
            
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Airbnb');

    }

    retrieveGlobalReview = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data);
                }

                this.setState({
                    globalReviews: data.rating_stats && data.rating_stats.length > 0 ? data.rating_stats[0] : null,
                    block: false, error: errorMessage
                });

                this.reloadReviewInfo()
            }
            else this.setState({ block: false });
        }, '/api/Rate/Review/v1/airbnb/' + this.state.selectedConnection + '/reviews/globalReview?type=user');
    }

    searchReviews() {
        if (this.validateFilters()) {
            var searchCriteria = '';

            if (this.state.reservationId) searchCriteria += '&reservationCode=' + this.state.reservationId
            if (this.state.reviwerRole) searchCriteria += '&reviwerRole=' + this.state.reviwerRole
            if (this.state.listingId) searchCriteria += '&listingId=' + this.state.listingId

            getAPI(result => {
                const { data, error } = result;
                var errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {

                    if (data.error) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                
                    if (errorMessage.length > 0)
                        this.setState({ block: false, error: errorMessage });
                    else {

                        this.setState({ reviewsList: data, block: false, error: errorMessage });                   
                    }
                }
                else  this.setState({ block: false });
            }, '/api/Rate/Review/v1/airbnb/' + this.state.selectedConnection + '/review/retrieve?limit=' + this.state.resultsPerPage + '&offset=' + this.state.currentPage + searchCriteria)
        }
       
    }

    validateFilters = () => {      
        if (this.state.reviwerRole && this.state.reservationId) {
            var obj = {
                error: {
                    error_details: '',
                    error_message: <FormattedMessage id="AirbnbReviewCard.OnlyOneFilter" />
                }
            }

            handleNotification(obj, null, 'Error');
            this.setState({ block: false });

            return false;
        }
        if (!this.state.reviwerRole && !this.state.reservationId) {
            var obj1 = {
                error: {
                    error_details: '',
                    error_message: <FormattedMessage id="AirbnbReviewCard.SelectAFilter" />
                }
            }

            handleNotification(obj1, null, 'Error');
            this.setState({ block: false });

            return false;
        }
        else {
            return true;
        }
    }

    setselectedConnection(evt) {
        if (evt != null) {
            this.setState({
                selectedConnection: evt.target.value,
            });
        }
    }

    reloadReviewInfo(event) {

        if (event) {
            event.preventDefault();
            if (!this.form.current.reportValidity()) {
                return;
            }
        }
       

        this.setState({ block: true, currentPage : 0 }, () => this.searchReviews());
        
    }

    toggleModal(row, key) {

        if (row != null) {
            this.setState(prevState => ({
                modalData: row, key: key, modal: !prevState.modal
            }));
        } else {
            this.setState(prevState => ({
                modalData: {}, key: {}, modal: !prevState.modal
            }));
        }
    }

    filterReply(event) {
        this.setState({ selectedReplyStatus: event.target.value });
    }

    fetchData() {

        this.setState({ currentPage: this.state.resultsPerPage + this.state.currentPage }, () =>this.searchReviews())

        

    }

    getChannelInstanceComboValues = () => {
        var array = [];
        this.state.channelinstanceList && this.state.channelinstanceList.map((item) => {
            if (item.active) {
                array.push({ 'label': item.name + ' (' + item.propertyCode + ')', 'value': item.id })
            }
            else {
                array.push({ 'label': <div> <i class="fas fa-times"></i> {item.name} ({item.propertyCode})</div>, 'value': item.id })
            }
        });
        return array
    }

    setComboStatus = (field, inputValue) => {
        var targetValue = '';
        targetValue = inputValue ? inputValue.value : null;


        this.setState({ [field]: targetValue })
    }

    getReviewerRoleComboValues() {

        var array = [
            { 'label': <FormattedMessage id="AirbnbReviewCard.Guest" />, 'value': 'guest' },
            { 'label': <FormattedMessage id="AirbnbReviewCard.Host" />, 'value': 'host', },
        ];

        return array
    }

    updateReview = (type, evt) => {
        const { name, value, categoryName } = evt.target;
        
        if (type !== 'category_ratings') {
            this.setState(prevState => ({
                ...prevState,
                selectedReview: {
                    ...prevState.selectedReview,
                    [name]: value
                }
            }));
        } else {
            let { category_ratings } = this.state.selectedReview;

            if (!category_ratings) category_ratings = [];

            const index = category_ratings.findIndex(el => el.category === categoryName);

            if (index !== -1) {
                if (name === 'rating' && value === 5) {
                    category_ratings[index].comment = null;
                }

                category_ratings[index][name] = value;
            }
            else {
                category_ratings.push({
                    category: categoryName,
                    [name]: value,
                })
            }

            this.setState({
                selectedReview: {
                    ...this.state.selectedReview,
                    category_ratings
                }
            })
        }
    }

    changeState(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }


    render() {
        const listValues = this.state.reviewsList.response ? this.state.reviewsList.response.reviews : [];

        const columnsGuest = [
            {
                dataField: 'reservation_confirmation_code',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.ReservationId" }),
                sort: true
            },
            {
                dataField: 'is_reviewee_recommended',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.RecommendedGuestShort" }),
                sort: true,
                formatter: (cell, row) => cell ? <i className="fas fa-times ml-5 text-danger" /> : <i className="fas fa-check ml-5 text-success" />,
                hidden: !listValues.some(el => el.reviewer_role === 'guest')
            },
            {
                dataField: 'reviewer_role',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.ReviewerRole" }),
                sort: true
            },
            {
                dataField: 'accuracy',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.accuracy" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings ? row.category_ratings.find(el => el.category === 'accuracy')?.rating : '',
                hidden: !listValues.some(el => el.reviewer_role === 'guest')
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.value" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'value')?.rating,
                hidden: !listValues.some(el => el.reviewer_role === 'guest')
            },
            {
                dataField: 'location',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.location" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'location')?.rating,
                hidden: !listValues.some(el => el.reviewer_role === 'guest')
            },
            {
                dataField: 'checkin',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.checkin" }),
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'checkin')?.rating,
                hidden: !listValues.some(el => el.reviewer_role === 'guest')
            },
            {
                dataField: 'cleanliness',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.cleanliness" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'cleanliness')?.rating 
            },
            {
                dataField: 'communication',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.communication" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'communication')?.rating 
            },
            {
                dataField: 'respect_house_rules',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.houseRules" }),
                sort: true,
                formatter: (cell, row) => row && row.category_ratings && row.category_ratings.find(el => el.category === 'respect_house_rules')?.rating,
                hidden: !listValues.some(el => el.reviewer_role === 'host')
            },
            {
                dataField: 'submitted',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.submited" }),
                sort: true,
                formatter: (cell, row) => <CustomInput type="checkbox" id={Math.random()} disabled={true} checked={cell ? true : false} /> 
            }/*,
            {
                dataField: 'hidden',
                text: this.props.intl.formatMessage({ id: "AirbnbReviewCard.Replied" }),
                sort: true,
                formatter: (cell, row) => <CustomInput type="checkbox" id={Math.random()} disabled={true} checked={cell ? true : false} />
            }*/
        ]
        
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
               
                this.setState({ modal: true, selectedReview: row})
            }
        };

        return (

            <div >
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />

                    {!global.isMobile ?
                        <AirbnbReview
                            form={this.form}
                            getChannelInstanceComboValues={this.getChannelInstanceComboValues}
                            setComboStatus={this.setComboStatus}
                            reservationId={this.state.reservationId}
                            changeState={this.changeState.bind(this)}
                            reloadReviewInfo={this.reloadReviewInfo}
                            globalReviews={this.state.globalReviews}
                            getReviewerRoleComboValues={this.getReviewerRoleComboValues}
                        />
                    :
                        <AirbnbReviewMobile
                            form={this.form}
                            getChannelInstanceComboValues={this.getChannelInstanceComboValues}
                            setComboStatus={this.setComboStatus}
                            reservationId={this.state.reservationId}
                            changeState={this.changeState.bind(this)}
                            reloadReviewInfo={this.reloadReviewInfo}
                            globalReviews={this.state.globalReviews}
                            getReviewerRoleComboValues={this.getReviewerRoleComboValues}
                        />
                    }

                    <br />


                    {this.state.modal &&
                        <AirbnbReviewDetail
                            modal={this.state.modal}
                            selectedConnection={this.state.selectedConnection}
                            selectedReview={this.state.selectedReview}
                            action={this.toggleModal.bind(this)}
                            updateReview={this.updateReview.bind(this)}
                            reloadReview={this.reloadReviewInfo.bind(this)}
                        />
                    }

                    <Row >
                        <Col>
                           
                            <CustomTable
                                data={listValues}
                                columns={columnsGuest}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                hidePageListOnlyOnePage={true}
                                onTableChange={this.handleTableChange}
                                shadow={false}
                                exportCSV={true}
                                selectRow={selectRow}
                            />
                            {
                                listValues.length === this.state.resultsPerPage ?
                                    <button style={{ marginBottom: '1.5rem' }} className="btn btn-host mt-2 btn-sm float-right" onClick={this.fetchData}> <FormattedMessage id="BookingReviewCard.LoadMoreData" /></button> :
                                    ""
                            }
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(AirbnbReviewCard)