import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane, CustomInput, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import { CommonHelper, StyledCard } from '../Base/Common/CommonUIComponents';
import CreatableSelect from 'react-select/creatable';
import CustomSelect from '../Base/Common/CustomSelect';
import { getAPI } from '../Base/API';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import classnames from 'classnames';
import { handleNotification } from '../Base/Notification';

class CreateURL extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: true,
            occupation: [],
            inputValue: '',
            selectedTags:[],
            PromoCodeList:[],
            checkIn: null,
            checkOut: null,
            complexId: null,
            complexList:null,
            c:null,
            categoriesList:null,
            campaignContent: null,
            campaignTerm: null,
            campaignMedium: null,
            campaignSource: null,
            campaignID: null,
            checkInToday: null,
            days: null,
            datesMode: null,
            lang: null,
            showExp: null,
            experinceType: null,
            experienceId: null,
            isChain: false,
            hotelIdMode: null,
            chain: null,
            city: null,
            hotelId: null,
            activeTab: '1',
            langList: [
                { value: 'en-GB', label: this.props.intl.formatMessage({ id: 'ComplexProfile.en-GB' }) },
                { value: 'de-DE', label: this.props.intl.formatMessage({ id: 'ComplexProfile.de-DE' }) },
                { value: 'fr-FR', label: this.props.intl.formatMessage({ id: 'ComplexProfile.fr-FR' }) },
                { value: 'es-ES', label: this.props.intl.formatMessage({ id: 'ComplexProfile.es-ES' }) },
                { value: 'pt-PT', label: this.props.intl.formatMessage({ id: 'ComplexProfile.pt-PT' }) },
                { value: 'pt-BR', label: this.props.intl.formatMessage({ id: 'ComplexProfile.pt-BR' }) },
                { value: 'pl-PL', label: this.props.intl.formatMessage({ id: 'ComplexProfile.pl-PL' }) },
                { value: 'it-IT', label: this.props.intl.formatMessage({ id: 'ComplexProfile.it-IT' }) },
            ],
            boolOptions: [
                { value: true, label: this.props.intl.formatMessage({ id: 'CreateURL.Yes' }) },
                { value: false, label: this.props.intl.formatMessage({ id: 'CreateURL.No' }) }
            ],
            chainOptions: [
                {value: '1', label: this.props.intl.formatMessage({ id: 'CreateURL.HotelId' })},
                {value: '2', label: this.props.intl.formatMessage({ id: 'CreateURL.City' })},
            ],
            experinceTypesOptions: [
                { value: 'Wellness', label: this.props.intl.formatMessage({ id: 'Experience.Wellness' }) },
                { value: 'Romantic', label: this.props.intl.formatMessage({ id: 'Experience.Romantic' }) },
                { value: 'Adventure', label: this.props.intl.formatMessage({ id: 'Experience.Adventure' }) },
                { value: 'Wild', label: this.props.intl.formatMessage({ id: 'Experience.Wild' }) },
                { value: 'Event', label: this.props.intl.formatMessage({ id: 'Experience.Event' }) },
                { value: 'Experience', label: this.props.intl.formatMessage({ id: 'Experience.Experience' }) },
                { value: 'Sports', label: this.props.intl.formatMessage({ id: 'Experience.Sports' }) },
                { value: 'Gastronomy', label: this.props.intl.formatMessage({ id: 'Experience.Gastronomy' }) }
            ],
            experienceOptions: []
        }
    }
    
    componentDidMount() {
        this.getPromoCodes();
    }

    getPromoCodes = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockProperties: false });

                return;
            }
            if(data && data.response) {
                const promoCodes = data.response.filter(promo => promo.active).map(promo => {
                    return({
                        value: promo.code,
                        label: promo.code
                    });
                });
                this.setState({ PromoCodeList: promoCodes }, this.getComplexList());
            }
            else{
                this.setState(({ block: false }));
            }
        }, '/api/Rate/Promotion/v1/promoCode');
    }
    
    getComplexList = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }

                if (data.length > 0) {
                    this.setState({ complexList: data.map(item => {return({ value: item.id, label: item.description })}) }, this.getRoomCategory());
                }
                else{
                    this.setState({ block: false });
                }
            }
            else{
                this.setState({ block: false });
            }
        }, `/api/hotel/Complexes/v1`);
    }
    
    getRoomCategory() {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let list = [];
                data.data && data.data.map((item) => list.push({ 'value': item.id, 'label': item.description }))
                this.setState({ categoriesList: list }, () => this.getExperiences());
            }
        }, '/api/hotel/RoomCategory/v1');
    }

    getExperiences = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                return;
            }
            if (data) {
                let list = [];
                data.response && data.response.map((item) => list.push({ 'value': item.id, 'label': item.descriptions.find(({cultureCode}) => cultureCode === 'en-GB')?.title }));
                this.setState({ experienceOptions: list, block: false  });
            }
        }, '/api/hotel/Experience/v1/experience?pageSize=9999&pageIndex=0');
    }

    createOption = (label) => ({
      label: label,
      value: label,
    });
    
    selectTags = (value) => {
        this.setState({
            selectedTags: value
        });
    }

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    }

    handleKeyDown = (event) => {
        const { inputValue, selectedTags } = this.state;
        if (!inputValue) return;
        if(inputValue.includes(',')) return;
        switch (event.key) {
        case 'Enter':
        case 'Tab':
            this.setState({
            inputValue: '',
            selectedTags: [...selectedTags, this.createOption(inputValue)],
            });
            event.preventDefault();
        }
    };

    toggleDiv = () => {
        this.setState(prev => ({ displayDiv: !prev }));
    }

    changeCount = (value, id) => {
        let size = this.state.occupation.length;
        if (value === 'minus' && size > id) {
            this.setState(prevState => ({
                ...prevState,
                occupation: [...prevState.occupation.slice(0, -1)]
            }));
        }
        if (value === 'plus' && size <= id) {
            this.setState(prevState => ({
                ...prevState,
                occupation: [...prevState.occupation, { room: this.state.occupation.length + 1, adults: 2, children: 0 }]
            }));
        }
    }

    changeCountOccupation = (roomNum, value, id, name) => {
        var r = roomNum;
        let newOccupation = this.state.occupation.find(el => el.room === r);
        if (value === 'minus' && newOccupation[name] > id) {
            newOccupation[name] -= 1;
        }
        if (value === 'plus' && newOccupation[name] <= id) {
            newOccupation[name] += 1;
        }
        this.setState(prevState => ({
            ...prevState,
            occupation: [...prevState.occupation.filter(el => el.room !== r), newOccupation].sort((occ1, occ2) => occ1.room - occ2.room)
        }));
    }

    handleChange = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChangeChainSelect = (name, combo) => {
        if(combo && combo.value === '1'){
            this.setState({
                [name]: combo ? combo.value : null,
                hotelId: global.hotel.id
            });
        }else{
            this.setState({
                [name]: combo ? combo.value : null
            });
        }
    };

    togglePanes(tab) {
        if (this.state.activeTab !== tab) {
            if(tab === '2'){
                this.setState({
                    activeTab: tab,
                    isChain: true,
                    chain: global.hotel.chainName
                });
            }else{
                this.setState({
                    activeTab: tab,
                    isChain: false,
                    chain: null
                });
            }
        }
    }

    render() {
        const { block, error, occupation, inputValue, chain, isChain, selectedTags, PromoCode, PromoCodeList, checkIn, checkOut, focusedInput, focusedInputChain, complexId, langList, lang,
            experinceType, experienceId, complexList, categoryId, categoriesList, campaignContent, campaignTerm, campaignMedium, campaignSource, campaignID,
            campaignName, checkInToday, days, datesMode, boolOptions, chainOptions, experinceTypesOptions, experienceOptions, city, hotelId, hotelIdMode, showExp } = this.state;

        const tags = selectedTags && selectedTags.length > 0 && selectedTags.map((tag) => tag.value).toString();
        
        const showExpOptions = [
            { value: 'onlyExperiences', label: this.props.intl.formatMessage({ id: 'CreateURL.onlyExperiences' }) },
            { value: 'noExperiences', label: this.props.intl.formatMessage({ id: 'CreateURL.noExperiences' }) }
        ];
        const url = `${process.env.REACT_APP_BE_URL}${isChain === true ? (chain !== null ? chain + '/ChainPage' : '') : (global.hotel.id)}?${checkIn ? `checkIn=${checkIn.format('YYYY-MM-DD')}&` : `${checkInToday ? `checkIn=today&` : ``}`}${checkOut ?
        `checkOut=${checkOut.format('YYYY-MM-DD')}&` : ''}${occupation.length ? `occupation=${JSON.stringify(occupation)}&` : ''}${PromoCode ?
        `promoCode=${PromoCode}&` : ''}${selectedTags && selectedTags.length > 0 ? `filters=${tags}&` : ''}${complexId ?
        `complex=${complexId}&` : ''}${categoryId ? `categoryCode=${categoryId}&` : ''}${campaignContent ?
        `utm_content=${campaignContent}&` : ''}${campaignTerm ? `utm_term=${campaignTerm}&` : ''}${campaignName ?
        `utm_campaign=${campaignName}&` : ''}${campaignMedium ? `utm_medium=${campaignMedium}&` : ''}${campaignSource ?
        `utm_source=${campaignSource}&` : ''}${campaignID ? `utm_id=${campaignID}&` : ''}${days ? `days=${days}&` : ``}${lang ?
        `lang=${lang}&` : ''}${showExp === "onlyExperiences" ? `onlyExperiences=true&` : ''}${showExp === "noExperiences" ? 'noExperiences=true&' : ''}${experinceType ?
                `experinceType=${experinceType}&` : ''}${experienceId ? `experienceId=${experienceId}&` : ''}${isChain === true ? (hotelIdMode === '1' ? (`hotelId=${hotelId !== null ? hotelId : ''}&`) : hotelIdMode === '2' ? (`hotelId=${city !== null ? city : ''}`) : '') : ''}`;
        
        const hasChain = global.modules && global.modules.includes("BookingEngineChain") && global.hotel.chainName;

        return (
            <div>
                <div className="my-2">
                    <StyledCard block={block} error={error} title={'URL'}>
                        <Row>
                            <Col>
                                <Row className='mt-2'>
                                    <Col>
                                        <Input
                                            value={url}
                                            readOnly
                                            style={{ display: 'inline-block', width: '90%' }}
                                            />
                                        <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                        width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} 
                                            onClick={e => {
                                                e.preventDefault(); 
                                                navigator.clipboard.writeText(url);
                                                handleNotification('', <FormattedMessage id="BEConfigDashboard.CopyURL" />);
                                            }}>
                                            <div>
                                                <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </StyledCard>
                </div>
                {hasChain ?
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.togglePanes('1'); }}>
                                <FormattedMessage id="CreateURL.GeneralParams" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.togglePanes('2'); }}>
                                <FormattedMessage id="CreateURL.ChainParams" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                : ''}
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane className={`${hasChain ? '' : `border-0 p-0` }`} tabId="1">
                        <div>
                            <StyledCard block={block} error={error} title={'CreateURL.BaseParams'} help={<FormattedMessage id="CreateURL.BaseParamsHelp" />} id="BaseParamsHelp" helperTarget="BaseParamsHelperTarget">
                                <Row>
                                    <Col>
                                        <CommonHelper
                                            help={
                                                <FormattedMessage id="CreateURL.occupationExplain" />
                                            }
                                            id={"occupationExplain"}
                                        />
                                        <Row>
                                            <Col className="col-2">
                                                <Label><FormattedMessage id={"CreateURL.Rooms"}/></Label>
                                            </Col>
                                            <Col className="col-2 px-1 text-center">
                                                <span onClick={() => this.changeCount('minus', 0)} disabled={occupation.length === 0}>
                                                    <i className="fas fa-minus fa-xs cursor-pointer" />
                                                </span>
                                                <span className="m-3">
                                                    {occupation.length}
                                                </span>
                                                <span disabled={occupation.length === 14} onClick={() => this.changeCount('plus', 14)}>
                                                    <i className="fas fa-plus fa-xs cursor-pointer" />
                                                </span>
                                            </Col>
                                        </Row>
                                        <div>
                                            {occupation.map((room, idx) => 
                                                <Row className="mt-2" key={idx} style={ idx % 2 === 0 ? { backgroundColor: '#f5f5f5', padding:'0.5rem 0', margin:'0 0.5rem' } : { backgroundColor:'white', margin:'0 0.5rem' }}>
                                                    <Col className='col-2' style={{ fontWeight: '500' }}>
                                                        <FormattedMessage id="CreateURL.Room"/>{` ${room.room}`}
                                                    </Col>
                                                    <Col>
                                                        <FormattedMessage id="CreateURL.Adults"/>:
                                                    </Col>
                                                    <Col className="text-center" style={{ width:'unset', padding:'unset' }}>
                                                        <span style={{ cursor: 'pointer'}} name="adults" disabled={room.adults === 1} onClick={() => this.changeCountOccupation(room.room, 'minus', 1, 'adults')}>
                                                            <i className="fas fa-minus fa-xs cursor-pointer"/>
                                                        </span>
                                                        <span className="m-3">{room.adults}</span>
                                                        <span style={{ cursor: 'pointer'}} name="adults" disabled={room.adults === 7} onClick={() => this.changeCountOccupation(room.room, 'plus', 7, 'adults')}>
                                                            <i className="fas fa-plus fa-xs cursor-pointer" />
                                                        </span>
                                                    </Col>
                                                    <Col className='ml-3'>
                                                        <FormattedMessage id="CreateURL.Children"/>:
                                                    </Col>
                                                    <Col className="text-center" style={{ width:'unset', padding:'unset' }}>
                                                        <span style={{ cursor: 'pointer'}} name="children" disabled={room.children === 0} onClick={() => this.changeCountOccupation(room.room, 'minus', 0, 'children')}>
                                                            <i className="fas fa-minus fa-xs cursor-pointer"/>
                                                        </span>
                                                        <span className="m-3">{room.children}</span>
                                                        <span style={{ cursor: 'pointer'}} name="children" disabled={room.children === 7} onClick={() => this.changeCountOccupation(room.room, 'plus', 7, 'children')}>
                                                            <i className="fas fa-plus fa-xs cursor-pointer" />
                                                        </span>
                                                    </Col>
                                                </Row>
                                            )}
                                        </div>
                                    </Col>
                                    <Col>
                                        <Row className="my-2">
                                            <Col>
                                                <CustomInput type="checkbox" name="checkInCheckout" id="checkInCheckout" onChange={_ => { if (datesMode === '1') { this.setState({ datesMode: null, checkInToday: null, days: null, checkIn: null, checkOut: null }); } else { this.setState({ datesMode: '1', checkInToday: null, days: null }); } }} checked={datesMode === '1'}>
                                                    <Label><FormattedMessage id="CreateURL.DatesFilter" /></Label>
                                                </CustomInput>
                                            </Col>
                                            <Col>
                                                <CustomInput type="checkbox" name="checkInToday" id="checkInToday" onChange={_ => { if (datesMode === '2') { this.setState({ datesMode: null, checkInToday: null, days: null, checkIn: null, checkOut: null }); } else { this.setState({ datesMode: '2', checkInToday: 'checkIn=today', checkIn: null, checkOut: null }); } }} checked={datesMode === '2'}>
                                                    <Label><FormattedMessage id="CreateURL.CheckInToday" /></Label>
                                                </CustomInput>
                                            </Col>
                                            <Col className="col-1">
                                                <CommonHelper help={<FormattedMessage id="CreateURL.checkInCheckoutExplain" />} id={"checkInCheckoutExplain"} />
                                            </Col>
                                        </Row>
                                        <Row style={{ display: datesMode === '1' ? '' : 'none' }}>
                                            <Col className="col-4">
                                                <Label><FormattedMessage id="CreateURL.checkInCheckout" /></Label>
                                            </Col>
                                            <Col>
                                                <DateRangePicker
                                                    startDate={checkIn ? moment(checkIn) : null}
                                                    startDateId="checkIn_id_general_params"
                                                    endDate={checkOut ? moment(checkOut) : null}
                                                    endDateId="checkout_id_general_params"
                                                    onDatesChange={({ startDate, endDate }) => {this.setState({ checkIn: startDate, checkOut: endDate })} }
                                                    focusedInput={focusedInput}
                                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                    small={true}
                                                    numberOfMonths={1}
                                                    showDefaultInputIcon={true}
                                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ display: datesMode === '2' ? '' : 'none' }}>
                                            <Col className="col-2">
                                                <Label><FormattedMessage id="CreateURL.Days" /></Label>
                                            </Col>
                                            <Col>
                                                <Input
                                                    type="number"
                                                    placeholder={this.props.intl.formatMessage({ id: "CreateURL.Days" })}
                                                    value={days ? days : ''}
                                                    min="1"
                                                    step="1"
                                                    onChange={e => this.setState({ days: e.target.value })}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mt-4'>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.PromoCode"/></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.PromoCodeExplain" />
                                                    }
                                                    id={"PromoCodeExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={PromoCodeList}
                                                    placeholder={<FormattedMessage id="CreateURL.PromoCode" />}
                                                    value={PromoCode ? PromoCodeList.find(c => c.value === PromoCode) : null}
                                                    onChange={e => this.handleChange('PromoCode', e)}
                                                    isClearable
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <Label> <FormattedMessage id="CreateURL.Tags"/></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.TagsExplain" />
                                                    }
                                                    id={"TagsExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CreatableSelect
                                                    components={{DropdownIndicator: null}}
                                                    inputValue={inputValue}
                                                    isClearable
                                                    isMulti
                                                    menuIsOpen={false}
                                                    onChange={this.selectTags}
                                                    onInputChange={this.handleInputChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    placeholder="Type something and press enter..."
                                                    value={selectedTags}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    {complexList && complexList.length > 0 ? 
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label for="complex"><FormattedMessage id="RoomCategorySetupConfig.Complex"/></Label>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.ComplexExplain" />
                                                        }
                                                        id={"ComplexExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <CustomSelect
                                                        options={complexList}
                                                        placeholder={<FormattedMessage id="RoomCategorySetupConfig.Complex" />}
                                                        value={complexId ? complexList.find(c => c.value === complexId) : null}
                                                        isClearable
                                                        onChange={e => this.handleChange('complexId', e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        :''
                                    }
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.Category"/></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.CategoryExplain" />
                                                    }
                                                    id={"CategoryExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={categoriesList}
                                                    placeholder={<FormattedMessage id="CreateURL.Category" />}
                                                    value={categoryId ? categoriesList.find(c => c.value === categoryId) : null}
                                                    isClearable
                                                    onChange={e => this.handleChange('categoryId', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.Lang"/></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.LangExplain" />
                                                    }
                                                    id={"LangExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={langList}
                                                    placeholder={<FormattedMessage id="CreateURL.Lang" />}
                                                    value={langList ? langList.find(c => c.value === lang) : null}
                                                    isClearable
                                                    onChange={e => this.handleChange('lang', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.ShowExp" /></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.ShowExpExplain" />
                                                    }
                                                    id={"ShowExpExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={showExpOptions}
                                                    placeholder={<FormattedMessage id="CreateURL.ShowExp" />}
                                                    value={showExpOptions ? showExpOptions.find(c => c.value === showExp) : null}
                                                    isClearable
                                                    onChange={e => this.handleChange('showExp', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.experinceType" /></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.experinceTypeExplain" />
                                                    }
                                                    id={"experinceTypeExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={experinceTypesOptions}
                                                    placeholder={<FormattedMessage id="CreateURL.experinceType" />}
                                                    value={experinceTypesOptions ? experinceTypesOptions.find(c => c.value === experinceType) : null}
                                                    isClearable
                                                    onChange={e => this.handleChange('experinceType', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="col-6">
                                        <Row>
                                            <Col>
                                                <Label><FormattedMessage id="CreateURL.experience" /></Label>
                                            </Col>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.experienceExplain" />
                                                    }
                                                    id={"experienceExplain"}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CustomSelect
                                                    options={experienceOptions}
                                                    placeholder={<FormattedMessage id="CreateURL.experience" />}
                                                    value={experienceOptions ? experienceOptions.find(c => c.value === experienceId) : null}
                                                    isClearable
                                                    onChange={e => this.handleChange('experienceId', e)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </StyledCard>
                        </div>
                        <div className="my-2">
                            <StyledCard block={block} error={error} title={'CreateURL.Campaign'} help={<FormattedMessage id="CreateURL.CampaignHelp" />} helperTarget="CampaignHelperTarget">
                                <div>
                                    <Row className="mt-3">
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignID"/></Label>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignIDExplain" />
                                                        }
                                                        id={"CampaignIDExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignID" })}
                                                        value={campaignID ? campaignID : ''}
                                                        onChange={e => this.setState({campaignID : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignSource"/></Label>
                                                    <span style={{ color: 'red' }}>*</span>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignSourceExplain" />
                                                        }
                                                        id={"CampaignSourceExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignSource" })}
                                                        value={campaignSource ? campaignSource : ''}
                                                        onChange={e => this.setState({ campaignSource : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignMedium"/></Label>
                                                    <span style={{ color: 'red' }}>*</span>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignMediumExplain" />
                                                        }
                                                        id={"CampaignMediumExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignMedium" })}
                                                        value={campaignMedium ? campaignMedium : ''}
                                                        onChange={e => this.setState({ campaignMedium : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    
                                    <Row className="mt-4">
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignName"/></Label>
                                                    <span style={{ color: 'red' }}>*</span>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignNameExplain" />
                                                        }
                                                        id={"CampaignNameExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignName" })}
                                                        value={campaignName ? campaignName : ''}
                                                        onChange={e => this.setState({ campaignName : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignTerm"/></Label>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignTermExplain" />
                                                        }
                                                        id={"CampaignTermExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignTerm" })}
                                                        value={campaignTerm ? campaignTerm : ''}
                                                        onChange={e => this.setState({ campaignTerm : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="CreateURL.CampaignContent"/></Label>
                                                </Col>
                                                <Col>
                                                    <CommonHelper
                                                        help={
                                                            <FormattedMessage id="CreateURL.CampaignContentExplain" />
                                                        }
                                                        id={"CampaignContentExplain"}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.CampaignContent" })}
                                                        value={campaignContent ? campaignContent : ''}
                                                        onChange={e => this.setState({ campaignContent : e.target.value })}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </StyledCard>
                        </div>
                    </TabPane>
                    {hasChain ?
                        <TabPane tabId="2" >
                            <div className="my-2">
                                <StyledCard block={block} error={error} title={'CreateURL.Chain'} help={<FormattedMessage id="CreateURL.ChainHelp" />} helperTarget="ChainHelperTarget">
                                    <div>
                                        <Row>
                                            <Col>
                                                <CommonHelper
                                                    help={
                                                        <FormattedMessage id="CreateURL.occupationExplain" />
                                                    }
                                                    id={"occupationExplainChain"}
                                                />
                                                <Row>
                                                    <Col className="col-2">
                                                        <Label><FormattedMessage id={"CreateURL.Rooms"}/></Label>
                                                    </Col>
                                                    <Col className="col-2 px-1 text-center">
                                                        <span onClick={() => this.changeCount('minus', 0)} disabled={occupation.length === 0}>
                                                            <i className="fas fa-minus fa-xs cursor-pointer" />
                                                        </span>
                                                        <span className="m-3">
                                                            {occupation.length}
                                                        </span>
                                                        <span disabled={occupation.length === 14} onClick={() => this.changeCount('plus', 14)}>
                                                            <i className="fas fa-plus fa-xs cursor-pointer" />
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    {occupation.map((room, idx) => 
                                                        <Row className="mt-2" key={idx} style={ idx % 2 === 0 ? { backgroundColor: '#f5f5f5', padding:'0.5rem 0', margin:'0 0.5rem' } : { backgroundColor:'white', margin:'0 0.5rem' }}>
                                                            <Col className='col-2' style={{ fontWeight: '500' }}>
                                                                <FormattedMessage id="CreateURL.Room"/>{` ${room.room}`}
                                                            </Col>
                                                            <Col>
                                                                <FormattedMessage id="CreateURL.Adults"/>:
                                                            </Col>
                                                            <Col className="text-center" style={{ width:'unset', padding:'unset' }}>
                                                                <span style={{ cursor: 'pointer'}} name="adults" disabled={room.adults === 1} onClick={() => this.changeCountOccupation(room.room, 'minus', 1, 'adults')}>
                                                                    <i className="fas fa-minus fa-xs cursor-pointer"/>
                                                                </span>
                                                                <span className="m-3">{room.adults}</span>
                                                                <span style={{ cursor: 'pointer'}} name="adults" disabled={room.adults === 7} onClick={() => this.changeCountOccupation(room.room, 'plus', 7, 'adults')}>
                                                                    <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                </span>
                                                            </Col>
                                                            <Col className='ml-3'>
                                                                <FormattedMessage id="CreateURL.Children"/>:
                                                            </Col>
                                                            <Col className="text-center" style={{ width:'unset', padding:'unset' }}>
                                                                <span style={{ cursor: 'pointer'}} name="children" disabled={room.children === 0} onClick={() => this.changeCountOccupation(room.room, 'minus', 0, 'children')}>
                                                                    <i className="fas fa-minus fa-xs cursor-pointer"/>
                                                                </span>
                                                                <span className="m-3">{room.children}</span>
                                                                <span style={{ cursor: 'pointer'}} name="children" disabled={room.children === 7} onClick={() => this.changeCountOccupation(room.room, 'plus', 7, 'children')}>
                                                                    <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col>
                                                <Row className="my-2">
                                                    <Col>
                                                        <CustomInput type="checkbox" name="checkInCheckout" id="checkInCheckout" onChange={_ => { if (datesMode === '1') { this.setState({ datesMode: null, checkInToday: null, days: null, checkIn: null, checkOut: null }); } else { this.setState({ datesMode: '1', checkInToday: null, days: null }); } }} checked={datesMode === '1'}>
                                                            <Label><FormattedMessage id="CreateURL.DatesFilter" /></Label>
                                                        </CustomInput>
                                                    </Col>
                                                    <Col>
                                                        <CustomInput type="checkbox" name="checkInToday" id="checkInToday" onChange={_ => { if (datesMode === '2') { this.setState({ datesMode: null, checkInToday: null, days: null, checkIn: null, checkOut: null }); } else { this.setState({ datesMode: '2', checkInToday: 'checkIn=today', checkIn: null, checkOut: null }); } }} checked={datesMode === '2'}>
                                                            <Label><FormattedMessage id="CreateURL.CheckInToday" /></Label>
                                                        </CustomInput>
                                                    </Col>
                                                    <Col className="col-1">
                                                        <CommonHelper help={<FormattedMessage id="CreateURL.checkInCheckoutExplain" />} id={"checkInCheckoutExplainChain"} />
                                                    </Col>
                                                </Row>
                                                <Row style={{ display: datesMode === '1' ? '' : 'none' }}>
                                                    <Col className="col-4">
                                                        <Label><FormattedMessage id="CreateURL.checkInCheckout" /></Label>
                                                    </Col>
                                                    <Col>
                                                        <DateRangePicker
                                                            startDate={checkIn ? moment(checkIn) : null}
                                                            startDateId="checkIn_id_chain_params"
                                                            endDate={checkOut ? moment(checkOut) : null}
                                                            endDateId="checkout_id_chain_params"
                                                            onDatesChange={({ startDate, endDate }) => {this.setState({ checkIn: startDate, checkOut: endDate })}}
                                                            focusedInput={focusedInputChain}
                                                            onFocusChange={focusedInputChain => this.setState({ focusedInputChain })}
                                                            small={true}
                                                            numberOfMonths={1}
                                                            showDefaultInputIcon={true}
                                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{ display: datesMode === '2' ? '' : 'none' }}>
                                                    <Col className="col-2">
                                                        <Label><FormattedMessage id="CreateURL.Days" /></Label>
                                                    </Col>
                                                    <Col>
                                                        <Input
                                                            type="number"
                                                            placeholder={this.props.intl.formatMessage({ id: "CreateURL.Days" })}
                                                            value={days ? days : ''}
                                                            min="1"
                                                            step="1"
                                                            onChange={e => this.setState({ days: e.target.value })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className="mt-4">
                                            <Col className="col-6">
                                                <Row>
                                                    <Col>
                                                        <Label><FormattedMessage id="CreateURL.Lang"/></Label>
                                                    </Col>
                                                    <Col>
                                                        <CommonHelper
                                                            help={
                                                                <FormattedMessage id="CreateURL.LangExplain" />
                                                            }
                                                            id={"LangExplainChain"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <CustomSelect
                                                            options={langList}
                                                            placeholder={<FormattedMessage id="CreateURL.Lang" />}
                                                            value={langList ? langList.find(c => c.value === lang) : null}
                                                            isClearable
                                                            onChange={e => this.handleChange('lang', e)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="col-6">
                                                <Row>
                                                    <Col>
                                                        <Label><FormattedMessage id="CreateURL.HotelIdParam"/></Label>
                                                    </Col>
                                                    <Col>
                                                        <CommonHelper
                                                            help={
                                                                <FormattedMessage id="CreateURL.CityOrHotelIdExplain" />
                                                            }
                                                            id={"CityOrHotelIdChain"}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <CustomSelect
                                                            options={chainOptions}
                                                            placeholder={<FormattedMessage id="CreateURL.HotelIdParam" />}
                                                            value={chainOptions ? chainOptions.find(c => c.value === hotelIdMode) : null}
                                                            isClearable
                                                            onChange={e => this.handleChangeChainSelect('hotelIdMode', e)}
                                                        />
                                                        <div style={{ display: hotelIdMode === '2' ? '' : 'none' }} className="mt-2">
                                                            <Row>
                                                                <Col>
                                                                    <Label><FormattedMessage id="CreateURL.City"/></Label>
                                                                </Col>
                                                                <Col>
                                                                    <CommonHelper
                                                                        help={
                                                                            <FormattedMessage id="CreateURL.CityExplain" />
                                                                        }
                                                                        id={"CityExplainChain"}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Input
                                                                        disabled={isChain === false}
                                                                        placeholder={this.props.intl.formatMessage({ id: "CreateURL.City" })}
                                                                        value={city ? city : ''}
                                                                        onChange={e => this.setState({city: e.target.value !== '' ? e.target.value : null })}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        
                                    </div>
                                </StyledCard>
                            </div>
                        </TabPane>
                    :''}
                </TabContent>
            </div>
        )
    }
}

export default injectIntl(CreateURL);