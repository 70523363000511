import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Card, CardBody, CardHeader, CustomInput, Row, Col, Button } from 'reactstrap';
import { ActiveInactiveStatusBadge, IcalUrlButton } from '../Base/Common/CommonUIComponents';
import { getInterfaceMode, getActive } from '../Base/Common/ReferenceDataFunctions';
import Authorization from '../Base/Authorization';
import moment from 'moment';
import CustomSelect from "../Base/Common/CustomSelect";
import CustomToolTip from '../../Utils/CustomToolTip';
import InfiniteScroll from 'react-infinite-scroller';
import BlockUi from 'react-block-ui';

class Mappings extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            sixMonthsInDays: 182,
            hrsRatesWithExtraMappings: ['Rate', 'Hot Deal', 'Season', 'Weekend', 'Package', 'Marketing', 'TradeShow'],
            hrsRatesWithExtraMappingsAllFields: ['Hot Deal', 'Season', 'Weekend', 'TradeShow'],
            mappings: [],
            roomOptions: [],
            rateOptions: [],
            rateLabelOptions: [],
            filterRooms: null,
            filterRates: null,
            rateLabels: null,
            mapStatusFilter: null,
            idx: 0,
            idxAdder: 1,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getMappings(this.props.rateList);
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.rateList) !== JSON.stringify(nextProps.rateList)) {
            this.getMappings(nextProps.rateList);
        }
    }

    getMappings = (data) => {
        const { filterRooms, filterRates, mapStatusFilter, rateLabels } = this.state;

        let rateCodeChannelList = [];
        let roomOptions = [];
        let rateOptions = [];
        let rateLabelOptions = [];

        data && data.forEach(el => {
            if (el.rateCodeChannelList.length > 0) {
                rateCodeChannelList.push(el);
            }
        })

        if (filterRooms) {
            this.filterRoomByCategory(filterRooms, rateCodeChannelList);
        }
        if (filterRates) {
            this.filterRateByRateCode(filterRates, rateCodeChannelList);
        }
        if(rateLabels){
            this.filterRatesByRateLabel(rateLabels, rateCodeChannelList);
        }
        if (mapStatusFilter !== null) {
            this.handleMapStatusByStatus(mapStatusFilter, rateCodeChannelList);
        }

        this.setState({ mappings: rateCodeChannelList, roomOptions, rateOptions, rateLabelOptions });
    }

    highlightDuplicated = (currentMap) => {
        let compareFunc = (m) => { return m.rateCode === currentMap.rateCode && m.roomCode === currentMap.roomCode; };
        if (currentMap.availabilityGroupMapping) {
            compareFunc = (m) => { return m.rateCode === currentMap.rateCode && m.roomCode === currentMap.roomCode && m.availabilityGroupMapping === currentMap.availabilityGroupMapping; };
        }
        const map = this.props.rateList && this.props.rateList.length > 0 && this.props.rateList.flatMap(x => x.rateList).reduce((acc, curr) => acc.concat(curr.mappingList), []);
        const duplicates = map && map.filter(compareFunc);

        if (duplicates.length > 1) {
            var highlighted = document.getElementsByClassName("bg-soft-blue");
            while (highlighted.length) {
                highlighted[0].classList.remove("bg-soft-blue");
            }

            duplicates.forEach(el => {
                document.getElementById(`map${el.id}`).classList.add("bg-soft-blue");
            })
        }
    }

    checkDuplicatedMapping = (currentMap, key) => {
        let compareFunc = (m) => { return m.rateCode === currentMap.rateCode && m.roomCode === currentMap.roomCode; };
        if (currentMap.availabilityGroupMapping) {
            compareFunc = (m) => { return m.rateCode === currentMap.rateCode && m.roomCode === currentMap.roomCode && m.availabilityGroupMapping === currentMap.availabilityGroupMapping; };
        }
        const map = this.props.rateList && this.props.rateList.length > 0 && this.props.rateList.flatMap(x => x.rateList).reduce((acc, curr) => acc.concat(curr.mappingList), []);
        const duplicates = map && map.filter(compareFunc).length - 1;

        return duplicates > 0 ?
            <span>
                <i className="ml-2 fas fa-exclamation-triangle text-warning" id={`duplicated${key}${currentMap.id}`} />

                <CustomToolTip placement="bottom" target={`duplicated${key}${currentMap.id}`}>
                    <FormattedMessage id="ChannelMapping.ThereAreDuplicateMappings" values={{ nrDuplicates: duplicates, rateCode: currentMap.rateCode, roomCode: currentMap.roomCode }} />
                </CustomToolTip>
            </span>
            :
            '';
    }
    
    getUrl = (iCalSyncUrls, id) => {
        const elem = iCalSyncUrls?.find(url => url.id === id);
        return elem ? elem.url : '';
    }

    filterRoom = (combo) => {
        let { mappings } = this.state;
        this.filterRoomByCategory(combo, mappings);
        this.setState({ filterRooms: combo, mappings });
    }

    filterRoomByCategory = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                if (combo.some(c => el.id === c.value)) {
                    el.isVisible = true;
                    el.showRoom = this.state.mapStatusFilter !== null ? el.rateCodeChannelList.some(rate => {
                        return rate.mappingList.some(ml => ml.active === this.state.mapStatusFilter);
                    }) : true;
                }
                else {
                    el.isVisible = false;
                }
            })
        }
        else {
            mappings.forEach(el => {
                el.isVisible = true;
                el.showRoom =
                    el.rateCodeChannelList.some(rate => {
                        return rate.isVisible !== false && (this.state.mapStatusFilter !== null ? rate.mappingList.some(ml => ml.active === this.state.mapStatusFilter) : true);
                    })

            })
        }
    }

    filterRate = (combo) => {
        let { mappings } = this.state;
        this.filterRateByRateCode(combo, mappings);
        this.setState({ filterRates: combo, mappings });
    }

    filterRateByRateCode = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = combo.some(c => rate.id === c.value);
                })

                el.showRoom = el.rateCodeChannelList.some(ml => ml.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = true;
                })

                el.showRoom = true;
            })
        }
    }

    filterRateLabel = (combo) => {
        let { mappings } = this.state;
        this.filterRatesByRateLabel(combo, mappings);
        this.setState({ rateLabels: combo, mappings });
    }

    filterRatesByRateLabel = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = combo.some(c => el.rateLabelId === c.value);
                })

                el.showRoom = el.rateCodeChannelList.some(ml => ml.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = true;
                })

                el.showRoom = true;
            })
        }
    }

    handleMapStatus = (combo) => {
        let { mappings } = this.state;
        const status = combo ? combo.value : null;
        this.handleMapStatusByStatus(status, mappings);
        this.setState({ mapStatusFilter: status, mappings })
    }

    handleMapStatusByStatus = (status, mappings) => {
        if (status !== null) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.showRate = rate.mappingList.some(ml => ml.active === status);
                })

                el.showRoom = el.isVisible !== false && el.rateCodeChannelList.some(rate => rate.showRate !== false && rate.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.showRate = true;
                })

                el.showRoom = el.isVisible !== false && el.rateCodeChannelList.some(rate => rate.isVisible !== false);
            })
        }
    }

    canLoad = () => {
        const { idx, idxAdder } = this.state;
        this.setState({ idx: idx + idxAdder });
    }

    render() {
        const { sixMonthsInDays, idx, mappings } = this.state;

        const hasRateLabel = this.state.mappings ? this.state.mappings.some(el => el.rateLabel) : false;

        const maxRooms = mappings?.filter(({isVisible, showRoom, rateCodeChannelList}) => isVisible !== false && showRoom !== false && rateCodeChannelList?.filter((rate) => rate.showRate !== false && rate.isVisible))?.length;

        return (
            <div>
                {this.props.channelDataMap && this.props.channelDataMap.length === 0 && this.props.showChannelInfo ?
                    <CardBody className="shadow">
                        <Row>
                            <Col>
                                <i className="fas fa-exclamation-triangle text-danger mr-1" />
                                <FormattedMessage id={'ChannelList.NoChannelData'} />
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.props.refreshChannelData}>
                                    <i className="fas fa-list" />
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                :
                    <Row className="mb-2 align-items-center">
                        <Col className={`${hasRateLabel ?'col-3' : 'col-4'} small pr-0`}>
                            <FormattedMessage id="ChannelMapping.CMRoom" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.props.roomOptions}
                                    onChange={(e) => this.filterRoom(e)}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        <Col className={`${hasRateLabel ?'col-3' : 'col-4'} small pr-0`}>
                            <FormattedMessage id="ChannelMapping.CMRate" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.props.rateOptions}
                                    onChange={(e) => this.filterRate(e)}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        {hasRateLabel && global.operationMode !== 'PmsFull' &&
                            <Col className="col-3 small">
                                <FormattedMessage id="ChannelMapping.RateLabel" >{option =>
                                    <CustomSelect
                                        isClearable
                                        options={this.props.rateLabelOptions}
                                        onChange={(e) => this.filterRateLabel(e)}
                                        placeholder={option}
                                        isMulti
                                    />
                                }</FormattedMessage>
                            </Col>
                        }

                        <Col className="col-3 small">
                            <FormattedMessage id="ChannelMapping.MapStatus" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={getActive(this.props.intl)}
                                    onChange={this.handleMapStatus}
                                    placeholder={option}
                                />
                            }</FormattedMessage>
                        </Col>
                    </Row>
                }

                <div style={{ overflow: 'auto', height: '500px' }}>
                    <InfiniteScroll
                        pageStart={0}
                        useWindow={false}
                        loadMore={this.canLoad}
                        element='div'
                        hasMore={idx < (maxRooms - 1)}
                        loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                        threshold={100}
                    >
                        {mappings?.filter(({isVisible, showRoom, rateCodeChannelList}) => isVisible !== false && showRoom !== false && rateCodeChannelList?.filter((rate) => rate.showRate !== false && rate.isVisible))
                        .filter((el, k) => idx >= k).map((el, k) =>
                            <Card className="bg-white shadow border-0 mb-2" key={k}>
                                <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}} className="card-header-gridview border-bottom-1 border-host bg-white pb-1">
                                    <span><b>{el.code}: </b> {el.description}</span>
                                    <span style={{ textAlign: 'right' }}>
                                        {el.hasRoomLevelRestrictions ?
                                            <span>
                                                <i id={`UncontrolledPopoverTooManyBidirMappings${el.id}`} className="ml-2 fas fa-exclamation-triangle text-warning" />
                                                <CustomToolTip placement="bottom" target={`UncontrolledPopoverTooManyBidirMappings${el.id}`}>
                                                    <FormattedMessage id="ChannelMapping.OnlyOneBidirMappingIsAllowed" />
                                                </CustomToolTip>
                                            </span>
                                        :''}
                                    </span>
                                </CardHeader>
                                <CardBody className="border-0 py-0" style={{ fontSize: '13px' }}>
                                    {el.rateCodeChannelList?.map((rate, rate_key) => {
                                        const url = this.getUrl(this.props.iCalSyncUrls, rate.id);
                                        const isMapped = rate.mappingList.find(map => map.iCalUrl !== null);

                                        return rate.showRate !== false && rate.isVisible !== false && (
                                            <Row className="overbglight" key={rate_key} style={{ borderBottom: '2px solid #dee2e6' }}>
                                                <Col className="border-right py-2">
                                                    <Row>
                                                        <Col className="col-9">
                                                            <div className="mb-2"> <b>{rate.code} </b> </div>
                                                            <div> {rate.description} </div>
                                                        </Col>
                                                        <Col className="text-right col-3">
                                                            <div className="d-flex justify-content-end">
                                                                {isMapped ?
                                                                    <IcalUrlButton
                                                                        id={'UncontrolledPopover' + rate.id}
                                                                        rateId={rate.id}
                                                                        getiCalSyncUrls={this.props.getiCalSyncUrls}
                                                                        url={url}
                                                                    />
                                                                :''}

                                                                {rate.mappingList.length === 0 ?
                                                                    this.props.isIcal ?
                                                                        <Button className="float-right btn-host btn-sm ml-2" onClick={this.props.openMapping.bind(this, rate.id, null)} disabled={el.isDisabledToAdd}>
                                                                            <i className="fas fa-plus" />
                                                                        </Button>
                                                                        :
                                                                        <Authorization
                                                                            perform="channel:mapping:add"
                                                                            yes={() => (
                                                                                <Button className="float-right btn-host btn-sm ml-2" onClick={this.props.openMapping.bind(this, rate.id, null)} disabled={el.isDisabledToAdd}>
                                                                                    <i className="fas fa-plus" />
                                                                                </Button>
                                                                            )}
                                                                            no={() => ""}
                                                                        />

                                                                    : rate.mappingList.every(el => !el.roomCode) ?
                                                                        <Authorization
                                                                            perform="channel:mapping:add"
                                                                            yes={() => (
                                                                                <Button className="float-right btn-host btn-sm ml-2" onClick={this.props.openMapping.bind(this, rate.id, null)} disabled={el.isDisabledToAdd || !this.props.isNotSapo}>
                                                                                    <i className="fas fa-plus" />
                                                                                </Button>
                                                                            )}
                                                                            no={() => ""}
                                                                        />

                                                                        : rate.mappingList && rate.mappingList.length > 0 && !this.props.isIcal ?
                                                                            <Authorization
                                                                                perform="channel:mapping:edit"
                                                                                yes={() => (
                                                                                    <div>
                                                                                        <Button className="btn-host btn-sm mb-1 ml-2" onClick={this.props.openMapping.bind(this, rate.id, null)} disabled={el.isDisabledToAdd || !this.props.isNotSapo}>
                                                                                            <i className="fas fa-plus" />
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                                no={() => ""}
                                                                            />
                                                                : ''}
                                                            </div>

                                                            <div className="d-flex justify-content-end align-items-end">
                                                                {rate.occupancyList ?
                                                                    <div>
                                                                        <Badge color="primary" className="py-1 mr-2" id={'pax' + rate.id}>
                                                                            <div>{rate.occupancyList.reduce((max, el) => el.adults > max ? el.adults : max, 0)} <i className="fas fa-male"></i>  {rate.occupancyList.length > 1 ? '+' : <span className="ml-2" />} </div>
                                                                        </Badge>
                                                                        <CustomToolTip placement="left" target={'pax' + rate.id}>
                                                                            {rate.occupancyList.sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults }).map((p, k3) =>
                                                                                <Row key={k3}>
                                                                                    <Col>
                                                                                        <div className="text-left">
                                                                                            {p.adults} <i className="fas fa-user mr-1 ml-1"></i>
                                                                                            {p.childrens > 0 ? <span> + {p.childrens} <i className="fas fa-child mx-1"></i></span> : ''}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            )}
                                                                        </CustomToolTip>
                                                                        {rate.isDerived ? <Badge color="primary">Derived</Badge> : ''}
                                                                    </div>
                                                                : ''}

                                                                <ActiveInactiveStatusBadge status={rate.active} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="pt-1 pb-2">
                                                    {rate.mappingList.map((map, map_key) => {
                                                        const channelData = this.props.channelDataMap.find(cd => cd.roomCode === map.roomCode && cd.rateCode === map.rateCode);
                                                        const occupancy = map.priceMode === 1 || this.props.isJuniper ? rate.occupancyList.find(occ => occ.position === map.pricePosition) : null;

                                                        return (this.state.mapStatusFilter !== null ? this.state.mapStatusFilter === map.active : true) && map.roomCode && (
                                                            <Row key={map_key} className={(map_key + 1 === rate.mappingList.length ? '' : "border-bottom ") + " pt-1 pb-2 pointer"} id={`map${map.id}`} onClick={() => this.highlightDuplicated(map, map_key)}>
                                                                <Col className="col-9 pr-0">
                                                                    {this.props.isIcal === false ?
                                                                        <div>
                                                                            <Row>
                                                                                <Col>
                                                                                    {this.props.ratecodechannel && this.props.channelDataMap && this.props.selectedChannel ? this.props.checkChannelManagerMapping(null, { 'channelRoomCode': map.roomCode, 'channelRateCode': map.rateCode, 'availabilityGroupMapping': map.availabilityGroupMapping, 'priceMarkupValue': map.priceMarkupValue }) : ''}
                                                                                    <span className="mr-1" />
                                                                                    {this.props.ratecodechannel && this.props.channelDataMap && this.props.selectedChannel ? this.props.getChannelName({ 'channelRoomCode': map.roomCode, 'channelRateCode': map.rateCode, 'channelId': map.id, 'availabilityGroupMapping': map.availabilityGroupMapping }, 'room') : ''},
                                                                                    <span className="mr-1" />
                                                                                    {this.props.ratecodechannel && this.props.channelDataMap && this.props.selectedChannel ? this.props.getChannelName({ 'channelRoomCode': map.roomCode, 'channelRateCode': map.rateCode, 'channelId': map.id, 'availabilityGroupMapping': map.availabilityGroupMapping }, 'rate') : ''}

                                                                                    {this.checkDuplicatedMapping(map, map_key)}
                                                                                </Col>

                                                                            </Row>
                                                                            <Row className="d-flex mt-1 align-items-center">
                                                                                <Col className="col-6 pr-0">
                                                                                    {map.priceMode >= 0 ?
                                                                                        <div >
                                                                                            <span>
                                                                                                Price per <FormattedMessage id={'ChannelList.priceMode' + map.priceMode} />{" "}
                                                                                            </span>
                                                                                            {(map.priceMode === 1 || this.props.isJuniper) && (map.pricePosition !== null && map.pricePosition !== undefined) ?
                                                                                                <span>
                                                                                                    <FormattedMessage id="ChannelMapping.PricePositionDescriptionAdults" values={{ adults: occupancy ? occupancy.adults : (rate.occupancyList.length > 0 ? Math.max(...rate.occupancyList.map(occ => occ.adults)) : 0) }} />
                                                                                                    {occupancy && occupancy.childrens > 0 ?
                                                                                                        <FormattedMessage id="ChannelMapping.PricePositionDescriptionChildren" values={{ children: occupancy.childrens }} />
                                                                                                        : <span />}
                                                                                                </span>
                                                                                                : <span />}

                                                                                            {global.operationMode === 'PmsFull' && this.props.priceModes.some(el => el.value === map.priceMode) === false ?
                                                                                                <span>
                                                                                                    <i id={`UncontrolledPopoverPriceModeDivergence${rate.id}${map.id}`} className="ml-2 fas fa-exclamation-triangle text-warning" />
                                                                                                    <CustomToolTip placement="bottom" target={`UncontrolledPopoverPriceModeDivergence${rate.id}${map.id}`}>
                                                                                                        <FormattedMessage id="ChannelMapping.PriceModeNotSupportedByChannel" />
                                                                                                    </CustomToolTip>
                                                                                                </span>
                                                                                                :
                                                                                                <span />
                                                                                            }
                                                                                        </div>
                                                                                    : ''}
                                                                                </Col>

                                                                                <Col className="col-2 pr-0">
                                                                                    {map.priceMarkupValue !== 0 ?
                                                                                        <b>
                                                                                            {map.priceMarkupType === 1 ?
                                                                                                'x'
                                                                                                : map.priceMarkupType === 2 && map.priceMarkupValue > 0 ? '+'
                                                                                                    : ''}
                                                                                            {map.priceMarkupValue}
                                                                                        </b>
                                                                                    : ''}
                                                                                </Col>

                                                                                <Col className="col-1">
                                                                                    {map.excludePriceComponent ?
                                                                                        <i className="fas fa-gift test text-muted" style={{ fontSize: '14px' }} title={this.props.intl.formatMessage({ id: "EditChannelMapping.ExcludePriceComponent" })}>
                                                                                            <span className="excludePriceComponent" />
                                                                                        </i>
                                                                                    : ''}
                                                                                </Col>

                                                                                <Col>
                                                                                    {channelData && channelData.priceMode !== null && map.priceMode !== null && map.priceMode >= 0 && channelData.priceMode !== map.priceMode && map.interfaceMode !== getInterfaceMode()[1].value ?
                                                                                        <span>
                                                                                            <i id={`UncontrolledPopoverPriceModeDivergence${rate.id}${map.id}`} className="ml-2 fas fa-exclamation-triangle text-warning" />
                                                                                            <CustomToolTip placement="bottom" target={`UncontrolledPopoverPriceModeDivergence${rate.id}${map.id}`}>
                                                                                                <FormattedMessage id="ChannelMapping.MappingListAlert" />
                                                                                            </CustomToolTip>
                                                                                        </span>
                                                                                    : <span />}

                                                                                    {channelData && channelData.endingDate && moment(channelData.endingDate).diff(moment(), 'days') < sixMonthsInDays ?
                                                                                        <span>
                                                                                            <i id={`UncontrolledPopoverContractExpiring${rate.id}${map.id}`} className="ml-2 fas fa-exclamation-triangle text-warning" />
                                                                                            <CustomToolTip placement="bottom" target={`UncontrolledPopoverContractExpiring${rate.id}${map.id}`}>
                                                                                                <FormattedMessage id="ChannelMapping.ContractExpiringAlert" />
                                                                                            </CustomToolTip>
                                                                                        </span>
                                                                                    : <span />}
                                                                                </Col>
                                                                            </Row>
                                                                            <Authorization
                                                                                perform="channelMapping:mapGoogleMappings"
                                                                                yes={() => (
                                                                                    map.associatedRateCodeChannels.length > 0 ?
                                                                                        <Row>
                                                                                            <Col className="col-3 pr-0">
                                                                                                <FormattedMessage id="ChannelMapping.PublishedOn" />
                                                                                            </Col>
                                                                                            {this.props.bookingEngines
                                                                                                .filter(be => map.associatedRateCodeChannels.find(associatedMapping => be.value === associatedMapping.channelCode) !== undefined)
                                                                                                .map((be, beKey) =>
                                                                                                    <Col key={beKey}>
                                                                                                        {be.label}
                                                                                                    </Col>
                                                                                                )}
                                                                                        </Row>
                                                                                    :
                                                                                        <div />
                                                                                )}
                                                                                no={() => <div />}
                                                                            />
                                                                            <Authorization
                                                                                perform="channelMapping:manageGoogleMappings"
                                                                                yes={() => (
                                                                                    this.props.isNotSapo ?
                                                                                        <div />
                                                                                    :
                                                                                        <Row>
                                                                                            <Col className="col-3 pr-0">
                                                                                                <FormattedMessage id="ChannelMapping.PublishedOn" />
                                                                                            </Col>
                                                                                            {this.props.bookingEngines.map((be, beKey) => {
                                                                                                const associatedRateCodeChannel = map.associatedRateCodeChannels.find(associatedMapping => be.value === associatedMapping.channelCode);

                                                                                                return (
                                                                                                    <Col key={beKey}>
                                                                                                        <CustomInput id={`${map.id}${be.value}`} type="checkbox" onClick={evt => this.props.handleSelectAssociatedMappings(evt, map.id, be.value, map.associatedRateCodeChannels)} checked={associatedRateCodeChannel !== undefined}>
                                                                                                            <span>
                                                                                                                <span>
                                                                                                                    {be.label}
                                                                                                                </span>
                                                                                                                {
                                                                                                                    associatedRateCodeChannel && associatedRateCodeChannel.isOutDated ?
                                                                                                                        <span>
                                                                                                                            <i id={`warning${associatedRateCodeChannel.channelCode}${associatedRateCodeChannel.id}`} style={{ position: 'relative', top: '-12px', right: '10px' }} className="fas fa-exclamation-triangle text-warning" />
                                                                                                                            <CustomToolTip placement="bottom" target={`warning${associatedRateCodeChannel.channelCode}${associatedRateCodeChannel.id}`}>
                                                                                                                                <FormattedMessage id="ChannelMapping.MappingIsOutDated" />
                                                                                                                            </CustomToolTip>
                                                                                                                        </span>
                                                                                                                        :
                                                                                                                        <span />
                                                                                                                }
                                                                                                            </span>
                                                                                                        </CustomInput>
                                                                                                    </Col>
                                                                                                );
                                                                                            })}
                                                                                        </Row>
                                                                                )}
                                                                                no={() => <div />}
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {map.iCalUrl ?
                                                                                <Badge className="p-1 mr-2" color="success">
                                                                                    <FormattedMessage id="ChannelMapping.RateMapped" />{" "}
                                                                                    <i className="fas fa-check" />
                                                                                </Badge>
                                                                                :
                                                                                <Badge className="p-1 mr-2" color="danger">
                                                                                    <FormattedMessage id="ChannelMapping.RateUnmapped" />{" "}
                                                                                    <i className="fas fa-exclamation-triangle" />
                                                                                </Badge>
                                                                            }
                                                                            <ActiveInactiveStatusBadge status={map.active} />
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col className="col-3">
                                                                    {this.props.isIcal ?
                                                                        <Button className=" float-right btn-host btn-sm" onClick={this.props.openMapping.bind(this, rate.id, map.id)} disabled={el.isDisabledToEdit}>
                                                                            <i className="fas fa-edit" />
                                                                        </Button>
                                                                        : 
                                                                        <>
                                                                            {this.props.isChannelReadOnly ?
                                                                                <Authorization
                                                                                    perform="channel:mapping:history"
                                                                                    yes={() => (
                                                                                        <Button className="float-right btn-host btn-sm" onClick={_ => this.props.rateCodeChannelHistory(map.id)}>
                                                                                            <i className="fas fa-history" />
                                                                                        </Button>
                                                                                    )}
                                                                                    no={() => ""}
                                                                                />
                                                                                :
                                                                                <Authorization
                                                                                    perform="channel:mapping:edit"
                                                                                    yes={() => (
                                                                                        <span>
                                                                                            <div className="text-right">
                                                                                                {this.props.isHrs && this.state.hrsRatesWithExtraMappings.some(rate => map.rateCode.includes(rate)) ?
                                                                                                    <Button className="btn-host btn-sm mr-2" onClick={_ => this.props.toggleExtraMappingModal(map.id, this.state.hrsRatesWithExtraMappingsAllFields.some(fld => map.rateCode.includes(fld)), map.rateCode.includes(this.state.hrsRatesWithExtraMappings[1]))}>
                                                                                                        <FormattedMessage id="ChannelMapping.Extra+" />
                                                                                                    </Button>
                                                                                                    : <span />}
                                                                                                <Button className="btn-host btn-sm" onClick={this.props.openMapping.bind(this, rate.id, map.id)} disabled={el.isDisabledToEdit}>
                                                                                                    <i className="fas fa-edit" />
                                                                                                </Button>
                                                                                            </div>
                                                                                        </span>
                                                                                    )}
                                                                                    no={() => (
                                                                                        this.props.isHrs && this.state.hrsRatesWithExtraMappings.some(rate => map.rateCode.includes(rate)) ?
                                                                                            <Button className="btn-host btn-sm" onClick={_ => this.props.toggleExtraMappingModal(map.id, this.state.hrsRatesWithExtraMappingsAllFields.some(fld => map.rateCode.includes(fld)), map.rateCode.includes(this.state.hrsRatesWithExtraMappings[1]))}>
                                                                                                <FormattedMessage id="ChannelMapping.Extra+" />
                                                                                            </Button>
                                                                                            :
                                                                                            <span />
                                                                                    )}
                                                                                />}

                                                                            <div className="d-flex justify-content-end align-items-end mt-2">
                                                                                <Badge className="mr-2 p-1" color="primary">
                                                                                    <FormattedMessage id={'generic.inventoryMode' + map.interfaceMode} />
                                                                                </Badge>
                                                                                <ActiveInactiveStatusBadge status={map.active} />
                                                                            </div>
                                                                        </>
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </CardBody>
                            </Card>
                        )}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export default injectIntl(Mappings);