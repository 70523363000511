import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { postAPI } from '../Base/API';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from '../Base/Notification';

export class PaymentCancelModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.doCancel = this.doCancel.bind(this);
        this.state = {
            block: false,
            reason: null
        }
    }

    doCancel() {
        const { payment, updatePayment } = this.props;
        const { reason } = this.state;
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response?.length > 0) {
                    handleNotification(data, <FormattedMessage id="Payments.SuccessfulCancel" />, <FormattedMessage id="General.Success" />);
                    updatePayment(data.response[0]);
                }

                this.setState({ error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/cancel/${payment.id}?reason=${reason}`);
    }

    updateFilterCombo(combo, evt) {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo && combo.value });
        }
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, reason } = this.state;

        const reasonOptions = [
            {
                value: 'requested_by_customer',
                label: <FormattedMessage id="Payments.RequestedByCustomer" />
            },
            {
                value: 'duplicate',
                label: <FormattedMessage id="Payments.Duplicate" />
            },
            {
                value: 'fraudulent',
                label: <FormattedMessage id="Payments.Fraudulent" />
            },
            {
                value: 'abandoned',
                label: <FormattedMessage id="Payments.Abandoned" />
            },
        ];

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="Payments.Refund" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block}>
                        <form ref={this.form}>
                            <Row className="mb-1">
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={this.doCancel}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2 d-flex align-items-center">
                                <Col className="col-3">
                                    <FormattedMessage id="Payments.Reason" />
                                </Col>
                                <Col className="col-9">
                                    <CustomSelect options={reasonOptions} isClearable value={reasonOptions.find(opt => opt.value === reason)} name="reason" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="Payments.Reason" />} required/>
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>    
        );
    }
}