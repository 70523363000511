import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Badge, Collapse, Input, CustomInput, Form, UncontrolledDropdown, DropdownToggle, Dropdown, DropdownItem, DropdownMenu, Nav, NavItem, NavLink } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../../Base/Notification";
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';
import moment from 'moment';
import CustomToolTip from '../../../../Utils/CustomToolTip';

class ComplexProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            profileConfig: true,
            paramsConfig: false,
            availableLangs: false,
            chatConfig: false,
            externalIntegrations: false,
            isEditableFieldsOrdered: false,
            profileFields: {
                fixedFields: [
                    { fieldName: 'firstName', isVisible: true, isMandatory: true },
                    { fieldName: 'lastName', isVisible: true, isMandatory: true },
                    { fieldName: 'email', isVisible: true, isMandatory: true },
                ],
                editableFields: [
                    { fieldName: 'telephone', isVisible: false, isMandatory: false },
                    { fieldName: 'dialCode', isVisible: false, isMandatory: false },
                    { fieldName: 'postalCode', isVisible: false, isMandatory: false },
                    { fieldName: 'country', isVisible: false, isMandatory: false },
                    { fieldName: 'addressLine', isVisible: false, isMandatory: false },
                    { fieldName: 'Title', isVisible: false, isMandatory: false },
                    { fieldName: 'Cityname', isVisible: false, isMandatory: false },
                    { fieldName: 'Gender', isVisible: false, isMandatory: false },
                    { fieldName: 'DocumentType', isVisible: false, isMandatory: false },
                    { fieldName: 'DocumentNumber', isVisible: false, isMandatory: false },
                    { fieldName: 'Birthdate', isVisible: false, isMandatory: false },
                    { fieldName: 'ArrivalTime', isVisible: false, isMandatory: false },
                    { fieldName: 'DepartureTime', isVisible: false, isMandatory: false }
                ]
            },
            paramFields:[
                { fieldName: 'suggestions', isVisible: true },
                { fieldName: 'AllowChildren', isVisible: true },
                { fieldName: 'AvailableRoomsWarning', isVisible: true },
                { fieldName: 'views', isVisible: true },
                { fieldName: 'sales', isVisible: true },
                { fieldName: 'RestrictionsApplyMinStay', isVisible: true },
                { fieldName: 'OtherSuggestions', isVisible: true },
                { fieldName: 'Filters', isVisible: false },
                { fieldName: 'HighLightPromo', isVisible: false },
                { fieldName: 'PricesCalendar', isVisible: true },
                { fieldName: 'singleOccRate', isVisible: false },
                { fieldName: 'CurrencyExchange', isVisible: false },
                { fieldName: 'PromoCode', isVisible: true },
                { fieldName: 'samePageExpAndCat', isVisible: false },
                { fieldName: 'hideOcc', isVisible: false },
                { fieldName: 'cityTaxNotIncluded', isVisible: false },
                { fieldName: 'StrictOcc', isVisible: false }
            ],
            langFields: [
                { fieldName: 'en-GB', isVisible: true },
                { fieldName: 'pt-PT', isVisible: true },
                { fieldName: 'pt-BR', isVisible: false },
                { fieldName: 'fr-FR', isVisible: true },
                { fieldName: 'es-ES', isVisible: true },
                { fieldName: 'de-DE', isVisible: true },
                { fieldName: 'it-IT', isVisible: true },
                { fieldName: 'pl-PL', isVisible: true }
            ],
            chatOptions:[
                { fieldName: 'Facebook-Messenger', isVisible: false, value: '' },
                { fieldName: 'Instagram', isVisible: false, value: '' },
                { fieldName: 'Whatsapp', isVisible: false, value: '' },
                { fieldName: 'Skype', isVisible: false, value: '' }
            ],
            externalIntegrationsOptions:[
                { fieldName: 'CustomGA', isVisible: false, value: '' },
                { fieldName: 'CustomGTM', isVisible: false, value: '' },
                { fieldName: 'CustomFBP', isVisible: false, value: '' },
            ],
            selectedComplex: this.props.selectedComplex ? this.props.selectedComplex : {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedComplex !== this.props.selectedComplex) {
            this.setState({
                selectedComplex: nextProps.selectedComplex
            }, () => this.getConfig())
        }
    }

    componentDidMount() {
        this.getConfig();
    }

    getConfig = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                let profileFields = { ...this.state.profileFields };
                let paramFields = [...this.state.paramFields];
                let langFields = [...this.state.langFields];
                let chatOptions = [...this.state.chatOptions];
                let externalIntegrationsOptions = [...this.state.externalIntegrationsOptions];
                let fixedFields = profileFields.fixedFields;
                let editableFields = profileFields.editableFields;

                data.response.forEach(config => {
                    const fixedFieldsKeys = fixedFields.map(({fieldName}) => fieldName);
                    const editableFieldsKeys = editableFields.map(({fieldName}) => fieldName);
                    const chatOptionsKeys = chatOptions.map(({fieldName}) => fieldName);
                    const externalIntegrationsOptionsKeys = externalIntegrationsOptions.map(({fieldName}) => fieldName);
                    const langFieldsKeys = langFields.map(({fieldName}) => fieldName);

                    const key = fixedFieldsKeys.includes(config.fieldName) ? 'fixedFields' : editableFieldsKeys.includes(config.fieldName) ? 'editableFields' :
                    chatOptionsKeys.includes(config.fieldName) ? 'chatOptions' : externalIntegrationsOptionsKeys.includes(config.fieldName) ? 'externalIntegrationsOptions' :
                    langFieldsKeys.includes(config.fieldName) ? 'langFields' : 'paramFields';

                    var i;
                    if(key === 'paramFields'){
                        i = paramFields.findIndex(ff => ff.fieldName === config.fieldName);
                        if (i > -1) paramFields[i] = config;
                    }
                    else if(key === 'langFields'){
                        i = langFields.findIndex(ff => ff.fieldName === config.fieldName);
                        if (i > -1) langFields[i] = config;
                    }
                    else if(key === 'chatOptions'){
                        i = chatOptions.findIndex(ff => ff.fieldName === config.fieldName);
                        if (i > -1) chatOptions[i] = config;
                    }
                    else if(key === 'externalIntegrationsOptions'){
                        i = externalIntegrationsOptions.findIndex(ff => ff.fieldName === config.fieldName);
                        if (i > -1) externalIntegrationsOptions[i] = config;
                    }
                    else{
                        i = profileFields[key].findIndex(ff => ff.fieldName === config.fieldName);
                        if (i > -1) profileFields[key][i] = config;
                    }
                });

                this.setState({ profileFields, paramFields, langFields, chatOptions, externalIntegrationsOptions }, () => this.orderEditableFields());
            }
            this.setState({ block: false });
        }, `/api/hotel/Complexes/v1/complex/${this.state.selectedComplex.id}/BeParams`);
    }

    saveProfileConfig = (e) => {
        e.preventDefault();
        const { profileFields, paramFields, langFields, chatOptions, externalIntegrationsOptions } = this.state;

        this.setState({ block: true });
        
        const body = [...profileFields.fixedFields].concat(...profileFields.editableFields).concat(...paramFields).concat(...langFields).concat(...chatOptions).concat(...externalIntegrationsOptions);

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, modalError: errorMessage });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ComplexProfile.ProfileConfigSaved" />, <FormattedMessage id="General.Success" />);
                }
            }
            this.setState({ block: false, modalError: errorMessage });
        }, `/api/hotel/Complexes/v1/complex/${this.state.selectedComplex.id}/BeParams`, body);
    }

    handleBadge = (key, fieldName) => {
        if(fieldName !== "en-GB"){ // Mandatory Lang
            const profileFields = { ...this.state.profileFields };
            const paramFields = [...this.state.paramFields];
            const langFields = [...this.state.langFields];
            const chatOptions = [...this.state.chatOptions];
            const externalIntegrationsOptions = [...this.state.externalIntegrationsOptions];

            let index = profileFields.editableFields.findIndex(pf => pf.fieldName === fieldName);

            if (index != -1) {
                if (key === 'isVisible' && profileFields.editableFields[index].isVisible) {
                    profileFields.editableFields[index].isMandatory = false;
                }

                profileFields.editableFields[index][key] = !profileFields.editableFields[index][key];
            }
            else{
                index = paramFields.findIndex(pf => pf.fieldName === fieldName);

                if(index != -1) paramFields[index][key] = !paramFields[index][key];
                else{
                    index = chatOptions.findIndex(pf => pf.fieldName === fieldName);

                    if(index != -1) chatOptions[index][key] = !chatOptions[index][key];
                    else{
                        index = langFields.findIndex(pf => pf.fieldName === fieldName);
                        if(index != -1) langFields[index][key] = !langFields[index][key];
                        else{
                            index = externalIntegrationsOptions.findIndex(pf => pf.fieldName === fieldName);
                            if(index != -1) externalIntegrationsOptions[index][key] = !externalIntegrationsOptions[index][key];
                        }
                    }
                }
            }

            this.setState({
                profileFields,
                paramFields,
                langFields,
                chatOptions,
                externalIntegrationsOptions
            });
        }
    }

    toggleCollapse = (section) => {
        this.setState(prev => ({ [section]: !prev[section] }));
    }

    handleValue = (e, type) =>{
        e.preventDefault();
        const { name, value } = e.target;
        const copy = [...this.state[type]];

        copy.find(({ fieldName }) => fieldName === name).value = value;
        
        this.setState({ [type]: copy });
    }

    orderEditableFields = () => {
        if(!this.state.isEditableFieldsOrdered){
            const listOrder = [
                'Title', 
                'Birthdate', 
                'Gender', 
                'dialCode', 
                'telephone', 
                'DocumentType', 
                'DocumentNumber', 
                'addressLine', 
                'postalCode', 
                'Cityname', 
                'country', 
                'ArrivalTime', 
                'DepartureTime'
            ];

            const orderedList = this.state.profileFields?.editableFields?.sort((a, b) => {
                return listOrder.indexOf(a.fieldName) - listOrder.indexOf(b.fieldName);
            });

            this.setState({ profileFields: { ...this.state.profileFields, editableFields: orderedList }, isEditableFieldsOrdered: true });
        }
    }

    render() {
        const { profileFields, paramFields, langFields, profileConfig, paramsConfig, availableLangs, chatConfig, chatOptions, externalIntegrationsOptions, externalIntegrations } = this.state;
        const { intl } = this.props;

        const dialCode = profileFields?.editableFields?.find(({fieldName}) => fieldName === "dialCode");
        const phone = profileFields?.editableFields?.find(({fieldName}) => fieldName === "telephone");

        const dialCodeValid = (dialCode && dialCode.isVisible && (!phone || !phone.isVisible)) || (dialCode.isMandatory && !phone.isMandatory);

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="border-0 shadow mb-2">
                    <CardHeader className="border-bottom bg-white" >
                        <Row>
                            <Col> <span className="fas fa-cog mr-1"> </span> <FormattedMessage id="ComplexProfile.BEConfig" /></Col>
                            <Col className="text-right pr-0">
                                <Button className="btn-host btn-sm" disabled={dialCodeValid} onClick={this.saveProfileConfig}>
                                    <i className="fas fa-save" />
                                </Button>
                                <CommonHelper help={<FormattedMessage id="ComplexProfile.ComplexSocialProfile"/>} id="ComplexSocialProfile" />
                            </Col>
                        </Row>
                    </CardHeader>
                    <div>
                        <Row className="px-4 py-2 cursor-pointer" onClick={_ => this.toggleCollapse('profileConfig')}>
                            <Col><span className="far fa-address-card mr-1"> </span> <FormattedMessage id="ComplexProfile.ProfileConfig" /></Col>
                            <Col className="text-right">
                                <div>
                                    <i className={profileConfig ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/>
                                </div>
                            </Col>
                        </Row>
                        <Collapse isOpen={profileConfig}>
                            <CardBody>
                                <b><FormattedMessage id="ComplexProfile.FixedFields" /></b>
                                <hr className="mt-2" />
                                <Row className="mb-3">
                                    {profileFields.fixedFields.map((field, key) => 
                                        <Col className="col-4" key={key}>
                                            <Card className="border-0 deep-shadow mb-3 p-2">
                                                <Row>
                                                    <Col className="pointer">
                                                        <FormattedMessage id={`ComplexProfile.${field.fieldName}`}/>
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Badge color={field.isVisible ? "primary" : "secondary"}> <FormattedMessage id="ComplexProfile.Visible" /> </Badge>
                                                        <Badge color={field.isMandatory ? "primary" : "secondary"} className="ml-2"> <FormattedMessage id="ComplexProfile.Mandatory" /> </Badge>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>

                                <b><FormattedMessage id="ComplexProfile.EditableFields" /></b>
                                <hr className="mt-2" />
                                <Row>
                                    {profileFields.editableFields.map((field, key) =>
                                        <Col className="col-4" key={key}>
                                            <Card className="border-0 deep-shadow mb-3 p-2">
                                                <Row>
                                                    <Col className="pointer">
                                                        <FormattedMessage id={`ComplexProfile.${field.fieldName}`} />
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Badge color={field.isVisible ? "primary" : "secondary"} className="pointer" onClick={() => this.handleBadge('isVisible', field.fieldName)}>
                                                            <FormattedMessage id="ComplexProfile.Visible" />
                                                        </Badge>
                                                        <Badge color={field.isMandatory ? "primary" : "secondary"} className="ml-2 pointer" onClick={() => { if (field.isVisible) this.handleBadge('isMandatory', field.fieldName) }}>
                                                            <FormattedMessage id="ComplexProfile.Mandatory" />
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Collapse>
                    </div>
                    <div>
                        <Row className="px-4 py-2 cursor-pointer" onClick={_ => this.toggleCollapse('paramsConfig')}>
                            <Col> <span className="far fa-address-card mr-1"> </span> <FormattedMessage id="ComplexProfile.ParamsConfig" /></Col>
                            <Col className="text-right">
                                <div>
                                    <i className={paramsConfig ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/>
                                </div>
                            </Col>
                        </Row>
                        <Collapse isOpen={paramsConfig}>
                            <CardBody className="mb-3">
                                <Row>
                                    {paramFields.map((field, key) =>
                                        <Col className="col-4" key={key}>
                                            <Card className="border-0 deep-shadow mb-3 p-2">
                                                <Row>
                                                    <Col className="pointer">
                                                        <FormattedMessage id={`ComplexProfile.${field.fieldName}`}/>
                                                    </Col>
                                                    <Col className="text-right col-2">
                                                        <Badge color={field.isVisible ? "primary" : "secondary"} className="pointer" onClick={() => this.handleBadge('isVisible', field.fieldName)}>
                                                            <FormattedMessage id="ComplexProfile.Visible" />
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                                {field.fieldName === 'sales' ?
                                                    <Row style={{ marginTop: '0.5rem' }}>
                                                        <Col>
                                                            <div style={{padding:'1.2rem 1rem', background:'#4174F8', borderRadius:'10px', float:'right',
                                                            color:'white', display: 'flex', alignItems: 'center'}}>
                                                                <div>
                                                                    <i className="fa-2x icon-SoldUnits24"/>
                                                                </div>
                                                                <div style={{margin: '0 0.6rem'}}>
                                                                    <FormattedMessage id="ComplexProfile.SalesPopUp" values={{Sales: 10}}/>
                                                                </div>
                                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                                                                    <div style={{width: '40px', height: '40px', borderRadius:'30px', color: 'white',
                                                                    background:'rgba(255, 255, 255, 0.1)', display:'flex', justifyContent:'center',
                                                                    alignItems:'center', fontSize:'15px'}}>
                                                                        x
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'views' ? 
                                                    <Row style={{ marginTop: '0.5rem' }}>
                                                        <Col>
                                                            <div style={{padding:'1.2rem 1rem', background:'#4174F8', borderRadius:'10px', float:'right',
                                                            color:'white', display: 'flex', alignItems: 'center'}}>
                                                                <div>
                                                                    <i className="fa-2x icon-SoldUnits24"/>
                                                                </div>
                                                                <div style={{margin: '0 0.6rem'}}>
                                                                    <FormattedMessage id="ComplexProfile.CurrentUserPopUp" values={{CurrentUsers: 5}}/>
                                                                </div>
                                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center', cursor:'pointer'}}>
                                                                    <div style={{width: '40px', height: '40px', borderRadius:'30px', color: 'white',
                                                                    background:'rgba(255, 255, 255, 0.1)', display:'flex', justifyContent:'center',
                                                                    alignItems:'center', fontSize:'15px'}}>
                                                                        x
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                :   field.fieldName === 'suggestions' ? 
                                                    <Row className='mt-2'>
                                                        <Col>
                                                            <div className="py-4" style={{ padding: '1.5rem 1rem', background: '#d9e3fe' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <div>
                                                                            <Row className='d-flex justify-content-center'>
                                                                                <div style={{ minWidth: '70px'}} className="HomeSuggestionsContainer">
                                                                                    <div style={{ padding: '1rem 0.5rem', background: 'white', borderRadius:'8px', height: '100%' }}
                                                                                    className="border-0 rounded-corners SuggestionsCardContainer shadow">
                                                                                        <div style={{ display: 'inline-block', padding: '2%' }}>
                                                                                            <Row className="mb-3">
                                                                                                <Col className="bluredText" style={{ fontSize: '0.3em' }}>
                                                                                                    Quarto Time Square
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row    >
                                                                                                <Col className="bold bluredText" style={{ fontSize: '0.4em' }}>
                                                                                                    Room Only
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="bluredText" style={{ fontSize: '0.2em' }}>
                                                                                                    <FormattedMessage id="ComplexProfile.Occupation" />:
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <Col style={{ height: '80px', width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                                className="col-4 GetDealContainer">
                                                                                    <Card className="shadow border-0 rounded-corners h-100" style={{ borderRadius:'8px', width:'inherit' }}>
                                                                                        <CardBody className="d-flex" style={{ flexDirection: 'column', justifyContent: 'space-between', padding:'1rem 0.5rem' }}>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <div className="bluredText" style={{fontSize:'0.4em'}}>
                                                                                                        Hotel
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col className="small bluredText">
                                                                                                    <div style={{ fontSize: '0.2em' }} className="mb-1">
                                                                                                        {moment().format('ddd, DD/MM/YYYY')} - {moment().add(1, 'day').format('ddd, DD/MM/YYYY')}
                                                                                                    </div>
                                                                                                </Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                <Col>
                                                                                                    <Button style={{ padding: '0.3rem 0' }} className="btn btn-block btn-host bluredText">
                                                                                                    </Button>
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'RestrictionsApplyMinStay' ? 
                                                    <Row>
                                                        <Col>
                                                            <Badge color="danger">
                                                                <FormattedMessage id="ComplexProfile.RestrictionsApplyMinStayContent" values={{ minStay: 3 }} />
                                                            </Badge>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'AllowChildren' ?
                                                    <Row style={{ fontSize: '0.5em' }}>
                                                        <Col>
                                                            <div className="occupationDiv shadow p-2" style={{ borderRadius: '5px', width: '70%', margin: '1rem 15% 0' }}>
                                                                <Row className="border-bottom m-1 pb-1">
                                                                    <Col className="col-2 col-sm- px-1">
                                                                        <span className="bold" style={{ whiteSpace: 'nowrap' }}>
                                                                            <FormattedMessage id="ComplexProfile.Rooms" />:
                                                                        </span>
                                                                    </Col>
                                                                    <Col className="col-2 px-1" />
                                                                    <Col className="col-3 px-1 text-center">
                                                                        <span>
                                                                            <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                        </span>
                                                                        <span className="m-3">1</span>
                                                                        <span>
                                                                            <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                        </span>
                                                                    </Col>
                                                                </Row>
                                                                <div>
                                                                    <Row className=" m-2 pb-1" style={{justifyContent:'space-between'}}>
                                                                        <Col className="mb-0 col-2" style={{ width:'unset', padding:'unset' }}>
                                                                            <span className="color-blue">
                                                                                <b>
                                                                                    <FormattedMessage id="ComplexProfile.Room"/>
                                                                                    <span className='ml-1'>1</span>
                                                                                </b>
                                                                            </span>
                                                                        </Col>
                                                                        <Col className="col-2" style={{ width:'unset', padding:'unset' }}>
                                                                            <FormattedMessage id="ComplexProfile.Adults"/>:
                                                                        </Col>
                                                                        <Col className="text-center col-3" style={{ width:'unset', padding:'unset' }}>
                                                                            <span>
                                                                                <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                            </span>
                                                                            <span className="m-3">2</span>
                                                                            <span>
                                                                                <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                            </span>
                                                                        </Col>
                                                                        <Col className="col-2" style={{ width:'unset', padding:'unset', whiteSpace:"nowrap" }}>
                                                                            <FormattedMessage id="ComplexProfile.Children"/>:
                                                                        </Col>
                                                                        <Col className="text-center col-3
                                                                        " style={{ width:'unset', padding:'unset' }}>
                                                                            <span>
                                                                                <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                            </span>
                                                                            <span className="m-3">0</span>
                                                                            <span>
                                                                                <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                            </span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'OtherSuggestions' ?
                                                    <Row className="mt-2 pl-2">
                                                        <Col>
                                                            <Row>
                                                                <Col style={{ color: 'rgb(65, 116, 248)', fontSize: '0.8em' }}>
                                                                    <FormattedMessage id="ComplexProfile.OtherSuggestions"/>
                                                                </Col>
                                                            </Row>
                                                            <div className='px-4 mt-2'>
                                                                <Row className="bluredText" style={{ boxShadow: '0px 3px 15px #6f73881a' }}>
                                                                    <Col className="col-3 p-3">
                                                                        <div style={{ filter: 'blur(8px)', width: '100%', height: '100%', background: 'grey', borderRadius: '5px' }} />
                                                                    </Col>
                                                                    <Col className="col-7 py-3 pl-0">
                                                                        <Row>
                                                                            <Col style={{ fontSize: '0.5em' }}>
                                                                                <b>Category Name</b>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-2">
                                                                            <Col style={{ fontSize: '0.4em' }}>
                                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-2">
                                                                            <Col style={{ fontSize: '0.4em' }}>
                                                                                <b>Detalhes & comodidades disponíveis</b>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col className="col-2" style={{ background: 'rgb(65, 116, 248)', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 'unset' }}>
                                                                        <div>
                                                                            <div style={{ display: 'flex', placeContent: 'center', textAlign: 'center' }}>
                                                                                <div>
                                                                                    <Row style={{ fontSize: '0.5em', textShadow: '0 0 5px rgb(255 255 255 / 50%)' }}>
                                                                                        <Col>
                                                                                            <span style={{ fontSize: '10px', marginRight: '0.1rem' }}>from</span>
                                                                                            <span style={{ fontSize: '120%' }}>270€</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <Row style={{ fontSize: '0.4em' }}>
                                                                                        <Col style={{ fontSize: '0.875em' }}>
                                                                                            135,00 € by night
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </div>
                                                                            <Row className="mt-4">
                                                                                <Col className="px-2" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <Button type="button" style={{ padding: '0.2rem 1rem', borderRadius: '2px' }}></Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'Filters' ?
                                                    <Row className="bg-white mt-2">
                                                        <Col className="text-center">
                                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                <button style={{ backgroundColor: '#d9e3fe', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                width: '30px', height: '30px', color: '#4174f8', fontSize: '0.5em', padding: '0', border: '0', borderRadius: '5px' }}>
                                                                    <span className='hasAmenitiesSelected'/>
                                                                    <i style={{fontSize: "1.5em"}} className="fas fa-sliders-h"/>
                                                                </button>
                                                            </div>
                                                            <Card className="bluredText shadow" style={{ fontSize: '0.5em', width: '20%', margin: '0.5rem auto 0', padding: '0.2rem 0.5rem', border: '0' }}>
                                                                <Row>
                                                                    <Col>
                                                                        Filters
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col className="px-3">
                                                                        <div className='d-flex align-items-center'>
                                                                            <div style={{ background: 'gray', filter: 'blur(2px)', width: '5px', height: '5px', marginRight: '5px' }}/>
                                                                            <div>Vista Mar (2)</div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div style={{ background: 'gray', filter: 'blur(2px)', width: '5px', height: '5px' }}/>
                                                                            <div>Vista Praia (5)</div>
                                                                        </div>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div style={{ background: 'gray', filter: 'blur(2px)', width: '5px', height: '5px' }}/>
                                                                            <div>Vista Céu (1)</div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'HighLightPromo' ? 
                                                    <Row>
                                                        <Col>
                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                <div style={{ position: 'absolute', zIndex: '5', color: 'white', fontSize: '1.5em', fontWeight: 'bold', marginTop: '-4px' }}>
                                                                    %
                                                                </div>
                                                                <div style={{ fontSize: '3.5em', color: '#d93f4d' }}>
                                                                    <i className="fas fa-certificate"/>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'PricesCalendar' ?
                                                    <Row>
                                                        <Col className="text-center">
                                                            <button style={{
                                                                border: 'none',
                                                                padding: '0.5rem',
                                                                background: '#d9e3fe',
                                                                borderRadius: '5px',
                                                                color: "rgb(65, 116, 248)",
                                                                fontSize: '0.875em',
                                                                margin: '3rem 0px 3.5%'
                                                            }}>
                                                                Show Price Calendar
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'singleOccRate' ?
                                                    <Row className="mt-2 pl-2">
                                                        <Col>
                                                            <div className='px-4 mt-2' style={{ boxShadow: '0px 3px 15px #6f73881a' }}>
                                                                <Row className="bluredText">
                                                                    <Col className="col-4 p-3">
                                                                        <div style={{ filter: 'blur(8px)', width: '100%', height: '100%', background: 'grey', borderRadius: '5px' }} />
                                                                    </Col>
                                                                    <Col className="col-8 py-3 pl-0">
                                                                        <Row>
                                                                            <Col style={{ fontSize: '0.5em' }}>
                                                                                <b>Category Name</b>
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-2">
                                                                            <Col style={{ fontSize: '0.4em' }}>
                                                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                                            </Col>
                                                                        </Row>
                                                                        <Row className="mt-2">
                                                                            <Col style={{ fontSize: '0.4em' }}>
                                                                                <b>Detalhes & comodidades disponíveis</b>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <div style={{ fontSize: '0.5em' }} className="pb-2">
                                                                    <Row className="mt-2 bluredText">
                                                                        <Col className="col-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div>
                                                                                <div>
                                                                                    <b>Estudio 99</b>
                                                                                </div>
                                                                                <div>
                                                                                    Room Only
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '65%' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                                                    <div>
                                                                                        <div className="cursor-pointer bluredText">
                                                                                            <span>Cancelamento</span>
                                                                                            <b className='ml-1'>Flexivel</b>
                                                                                        </div>
                                                                                        <div style={{ fontSize: '0.4em' }}>
                                                                                            Up to 2 Adults
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='text-center' style={{ width: 'fit-content' }}>
                                                                                        475€
                                                                                    </div>
                                                                                    <div style={{ fontWeight: '100', marginTop: '-5px', fontSize: '0.4em' }}>
                                                                                        Per Night
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Button className="btn btn-host btn-sm bluredText"
                                                                            style={{ borderRadius: '3px', fontSize: '0.4rem', width: '20%', height: '100%', padding: '0', whiteSpace: 'pre-line' }}>
                                                                                <span>
                                                                                    Book For 950€
                                                                                </span>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row className="mt-2 bluredText">
                                                                        <Col className="col-4" style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <div>
                                                                                <div>
                                                                                    <b>Estudio 99</b>
                                                                                </div>
                                                                                <div>
                                                                                    Room Only
                                                                                </div>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='col-8' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '65%' }}>
                                                                                <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                                                    <div>
                                                                                        <div className="cursor-pointer bluredText">
                                                                                            <span>Cancelamento</span>
                                                                                            <b className='ml-1'>Flexivel</b>
                                                                                        </div>
                                                                                        <div style={{ fontSize: '0.4em' }}>
                                                                                            Up to 2 Adults
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <div className='text-center' style={{ width: 'fit-content' }}>
                                                                                        475€
                                                                                    </div>
                                                                                    <div style={{ fontWeight: '100', marginTop: '-5px', fontSize: '0.4em' }}>
                                                                                        Per Night
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <Button className="btn btn-host btn-sm bluredText"
                                                                            style={{ borderRadius: '3px', fontSize: '0.4rem', width: '20%', height: '100%', padding: '0', whiteSpace: 'pre-line' }}>
                                                                                <span>
                                                                                    Book For 950€
                                                                                </span>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                : field.fieldName === 'CurrencyExchange' ?
                                                    <Row className="mt-2">
                                                        <Col className="d-flex align-items-center justify-content-center">
                                                            <UncontrolledDropdown style={{ color: '#007bff', cursor: 'pointer' }} direction="down">
                                                                <DropdownToggle tag="a" caret>
                                                                    <i className="fas fa-coins mr-1"/>
                                                                    EUR
                                                                </DropdownToggle>
                                                                <DropdownMenu positionFixed={true} className='currency' style={{ maxWidth: '200px', left: '-50px' }}>
                                                                    <div className='px-2 py-1 text-muted text-center' style={{ fontSize: '0.8em' }}>
                                                                        <i className="fas fa-info-circle mr-1"/>
                                                                        <FormattedMessage id="ComplexProfile.Info"/>
                                                                    </div>
                                                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                                                        {[
                                                                            {label: "EUR", value: "EUR"},
                                                                            {label: "USD", value: "USD"},
                                                                            {label: "GBP", value: "GBP"},
                                                                            {label: "PLN", value: "PLN"},
                                                                            {label: "JPY", value: "JPY"},
                                                                            {label: "AUD", value: "AUD"},
                                                                            {label: "CAD", value: "CAD"},
                                                                            {label: "CHF", value: "CHF"},
                                                                            {label: "CNY", value: "CNY"},
                                                                            {label: "HKD", value: "HKD"},
                                                                            {label: "BRL", value: "BRL"},
                                                                            {label: "NZD", value: "NZD"},
                                                                        ].map((cur, key) =>
                                                                            <DropdownItem key={key} active={"EUR" === cur.value} className='text-center'>
                                                                                {cur.label}
                                                                            </DropdownItem>
                                                                        )}
                                                                    </div>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </Col>
                                                    </Row>
                                                :  field.fieldName === 'PromoCode' ?
                                                    <Row className="mt-2 p-2 bluredText text-center">
                                                        <Col>
                                                            <input
                                                                style={{
                                                                    height: "50px",
                                                                    width: "100%",
                                                                    minWidth: "130px",
                                                                    borderRadius: "10px",
                                                                    boxShadow: "none",
                                                                    textAlign: "center",
                                                                    paddingLeft: "5%",
                                                                    paddingRight: "5%",
                                                                    border: "1px solid #dbdbdb",
                                                                    width: "170px",
                                                                    color: "#212529"
                                                                }}
                                                                readOnly={true}
                                                                placeholder={"Promo Code"}
                                                            />
                                                        </Col>
                                                    </Row>

                                                :  field.fieldName === 'samePageExpAndCat' ?
                                                    <div className="mt-2">
                                                        <Nav tabs className="border-0 justify-content-center d-flex align-items-center">
                                                            <NavItem className='border-0'>
                                                                <NavLink className='tab-border-host text-host border-0' style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-bed"/>
                                                                    <span style={{ fontWeight: '500' }}> <FormattedMessage id="Home.Rooms"/></span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem className='border-0'>
                                                                <NavLink className='navLink border-0' style={{ cursor: "pointer" }}>
                                                                    <i className="fas fa-gifts"/>
                                                                    <span style={{ fontWeight: '500' }}> <FormattedMessage id="Home.Experiences"/></span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                : field.fieldName === 'cityTaxNotIncluded' ?
                                                    <div className="mt-2 px-2" style={{color:'rgb(65, 116, 248)'}}>
                                                        <div style={{ display: 'inline-block', width:'50%', verticalAlign:'bottom', fontSize: '25px' }} className="small">
                                                            <FormattedMessage id="ComplexProfile.Total"/>
                                                        </div>
                                                        <div style={{ display: 'inline-block', width:'50%', fontSize: '25px' }} className="text-right">
                                                            <span id="cityTax"> <span className="bluredBlueText">874,00€</span>
                                                                <span style={{ position: 'absolute', fontSize: '20px' }}>*</span>
                                                                                                                </span>
                                                            {paramsConfig ? 
                                                                <CustomToolTip placementPrefix="bs-tooltip" placement="left" trigger={'focus'} autohide={false} defaultOpen={true} target={`cityTax`}>
                                                                    <FormattedMessage id="ComplexProfile.cityTaxNotIncluded" />
                                                                </CustomToolTip>
                                                                :''}
                                                        </div>
                                                    </div>
                                                : field.fieldName === 'hideOcc' ?
                                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                                        <div className='bluredText px-2' style={{ width: '30%' }}>
                                                            <div className='d-flex align-items-center justify-content-between p-2' style={{ borderRadius: '4px', border: '1px solid #4174f8', fontSize: '0.9em' }}>
                                                                <div className='d-flex align-items-center justify-content-center'>
                                                                    <i style={{ fontSize: '0.8em' }} className="fas fa-user mr-1" />
                                                                    <span>2</span>
                                                                </div>
                                                                <div>205.50€</div>
                                                            </div>
                                                        </div>
                                                        <div className='cursor-pointer text-right text-muted align-self-end' style={{ fontSize: '0.9em' }}>
                                                            <FormattedMessage id="ComplexProfile.SeeMore" />
                                                        </div>
                                                    </div>
                                                : field.fieldName === 'StrictOcc' ?
                                                    <Row>
                                                        <Col className="col-12">
                                                            <Row style={{ fontSize: '0.5em' }}>
                                                                <Col>
                                                                    <div className="occupationDiv shadow p-2" style={{ borderRadius: '5px', width: '70%', margin: '1rem 15% 0' }}>
                                                                        <Row className="border-bottom m-1 pb-1">
                                                                            <Col className="col-2 col-sm- px-1">
                                                                                <span className="bold" style={{ whiteSpace: 'nowrap' }}>
                                                                                    <FormattedMessage id="ComplexProfile.Rooms" />:
                                                                                </span>
                                                                            </Col>
                                                                            <Col className="col-2 px-1" />
                                                                            <Col className="col-3 px-1 text-center">
                                                                                <span>
                                                                                    <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                                </span>
                                                                                <span className="m-3">1</span>
                                                                                <span>
                                                                                    <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                                </span>
                                                                            </Col>
                                                                        </Row>
                                                                        <div>
                                                                            <Row className=" m-2 pb-1" style={{justifyContent:'space-between'}}>
                                                                                <Col className="mb-0 col-2" style={{ width:'unset', padding:'unset' }}>
                                                                                    <span className="color-blue">
                                                                                        <b>
                                                                                            <FormattedMessage id="ComplexProfile.Room"/>
                                                                                            <span className='ml-1'>1</span>
                                                                                        </b>
                                                                                    </span>
                                                                                </Col>
                                                                                <Col className="col-2" style={{ width:'unset', padding:'unset' }}>
                                                                                    <FormattedMessage id="ComplexProfile.Adults"/>:
                                                                                </Col>
                                                                                <Col className="text-center col-3" style={{ width:'unset', padding:'unset' }}>
                                                                                    <span>
                                                                                        <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                                    </span>
                                                                                    <span className="m-3">2</span>
                                                                                    <span>
                                                                                        <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                                    </span>
                                                                                </Col>
                                                                                <Col className="col-2" style={{ width:'unset', padding:'unset', whiteSpace:"nowrap" }}>
                                                                                    <FormattedMessage id="ComplexProfile.Children"/>:
                                                                                </Col>
                                                                                <Col className="text-center col-3
                                                                                " style={{ width:'unset', padding:'unset' }}>
                                                                                    <span>
                                                                                        <i className="fas fa-minus fa-xs cursor-pointer" />
                                                                                    </span>
                                                                                    <span className="m-3">0</span>
                                                                                    <span>
                                                                                        <i className="fas fa-plus fa-xs cursor-pointer" />
                                                                                    </span>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col className="col-12 mt-4">
                                                            <Row>
                                                                <Col>
                                                                    <div className="py-4" style={{ padding: '1.5rem 1rem', background: '#d9e3fe' }}>
                                                                        <Row>
                                                                            <Col>
                                                                                <div>
                                                                                    <Row className='d-flex justify-content-center'>
                                                                                        <div style={{ minWidth: '90px'}} className="HomeSuggestionsContainer">
                                                                                            <div style={{ padding: '1rem 0.5rem', background: 'white', borderRadius:'8px', height: '100%' }}
                                                                                            className="border-0 rounded-corners SuggestionsCardContainer shadow">
                                                                                                <div style={{ display: 'inline-block', padding: '2%' }}>
                                                                                                    <Row className="mb-3">
                                                                                                        <Col style={{ fontSize: '0.5em' }}>
                                                                                                            Quarto
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row    >
                                                                                                        <Col className="bold" style={{ fontSize: '0.5em' }}>
                                                                                                            Room Only
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        <Col style={{ fontSize: '0.4em' }}>
                                                                                                            <FormattedMessage id="ComplexProfile.Occupation" />: <i className="fas fa-user"></i> <i className="fas fa-user"></i>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <Col style={{ height: '100px', width: '120px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                                                        className="col-4 GetDealContainer">
                                                                                            <Card className="shadow border-0 rounded-corners h-100" style={{ borderRadius:'8px', width:'inherit' }}>
                                                                                                <CardBody className="d-flex" style={{ flexDirection: 'column', justifyContent: 'space-between', padding:'0.7rem 0.5rem' }}>
                                                                                                    <Row>
                                                                                                        <Col>
                                                                                                            <div style={{fontSize:'0.5em'}}>
                                                                                                                Hotel
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        <Col className="col-12">
                                                                                                            <div style={{ fontSize: '0.4em' }} className="mb-1">
                                                                                                                {moment().format('ddd, DD/MM/YYYY')} - {moment().add(1, 'day').format('ddd, DD/MM/YYYY')}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                        <Col className="col-12">
                                                                                                            <div style={{ fontSize: '0.4em' }} className="mb-1">
                                                                                                                {`1 Alojamento(s), 2 Adulto(s), 0 Criança(s)`}
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                    <Row>
                                                                                                        <Col>
                                                                                                            <Button style={{ padding: '0.5rem' }} className="btn btn-block btn-host bluredText">
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                </CardBody>
                                                                                            </Card>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                :
                                                    <Row>
                                                        <Col>
                                                            <div className="my-1" style={{color:'#EE6A5F'}}>
                                                                <i style={{verticalAlign:'middle'}} className="fas fa-lg icon-Lastunits mr-2"/>
                                                                <b>
                                                                    <FormattedMessage id="ComplexProfile.AvailableRoomsWarningContent" values={{ freeRooms: 2}}/>
                                                                </b>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Collapse>
                    </div>
                    <div>
                        <Row className="px-4 py-2 cursor-pointer" onClick={_ => this.toggleCollapse('availableLangs')}>
                            <Col><i className="fas fa-globe mr-1"/> <FormattedMessage id="ComplexProfile.AvailableLangs"/></Col>
                            <Col className="text-right">
                                <div>
                                    <i className={availableLangs ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/>
                                </div>
                            </Col>
                        </Row>
                        <Collapse isOpen={availableLangs}>
                            <CardBody className="mb-3">
                                <Row>
                                    {langFields.map((field, key) =>
                                        <Col className="col-4" key={key}>
                                            <Card className="border-0 deep-shadow mb-3 p-2">
                                                <Row>
                                                    <Col className="pointer">
                                                        <FormattedMessage id={`ComplexProfile.${field.fieldName}`} />
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Badge color={field.isVisible ? "primary" : "secondary"} className="pointer" onClick={() => this.handleBadge('isVisible', field.fieldName)}>
                                                            <FormattedMessage id="ComplexProfile.Visible" />
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Col>
                                    )}
                                </Row>
                            </CardBody>
                        </Collapse>
                    </div>
                    
                    <div>
                        <Row className="px-4 py-2 cursor-pointer" onClick={_ => this.toggleCollapse('chatConfig')}>
                            <Col><i className="fab fa-rocketchat mr-1"/><FormattedMessage id="ComplexProfile.chatConfig"/></Col>
                            <Col className="text-right">
                                <div>
                                    <i className={chatConfig ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/>
                                </div>
                            </Col>
                        </Row>
                        <Collapse isOpen={chatConfig}>
                            <CardBody className="mb-3">
                                <Row>
                                    <Col>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                            {chatOptions.map((field, key) =>
                                                <div style={{ minWidth: '50px', marginInline: '0.5rem' }} key={key}>
                                                    <Row>
                                                        <Col className={`${field.isVisible ? 'col-3' : ''} p-0`}>
                                                            <div onClick={() => this.handleBadge('isVisible', field.fieldName)}
                                                            className={`unSelectedChat ${field.isVisible ? `${field.fieldName}` : ''}`}>
                                                                <i className={`fab fa-${field.fieldName.toLowerCase()}`}/>
                                                            </div>
                                                        </Col>
                                                        {field.isVisible ? 
                                                            <Col className="pl-0" style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                                                <Input
                                                                    type="text"
                                                                    value={field.value}
                                                                    name={field.fieldName}
                                                                    onChange={e => this.handleValue(e, 'chatOptions')}
                                                                    placeholder={intl.formatMessage({ id:`ComplexProfile.${field.fieldName}` })}
                                                                />
                                                            </Col>
                                                        :''}
                                                    </Row>
                                                </div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Collapse>
                    </div>

                    <Row className="px-4 py-2 cursor-pointer" onClick={_ => this.toggleCollapse('externalIntegrations')}>
                        <Col><i className="fas fa-exchange-alt mr-1"></i><FormattedMessage id="ComplexProfile.externalIntegrations"/></Col>
                        <Col className="text-right">
                            <div>
                                <i className={externalIntegrations ? 'float-right fas fa-chevron-up' : 'float-right fas fa-chevron-down'}/>
                            </div>
                        </Col>
                    </Row>
                    <Collapse isOpen={externalIntegrations}>
                        <CardBody className="mb-3">
                            <Row>
                                {externalIntegrationsOptions.map((field, key) =>
                                    <Col>
                                        <Row>
                                            <Col className="d-flex align-items-center">
                                                <b style={{marginRight: '15px'}}><FormattedMessage id={`ComplexProfile.${field.fieldName}`} /></b>
                                                <div>
                                                    <CustomInput
                                                        id={`active${field.fieldName}`}
                                                        name="active"
                                                        type="switch"
                                                        checked={field.isVisible}
                                                        onChange={_ => this.handleBadge('isVisible', field.fieldName)}
                                                        label={<FormattedMessage id="HotelChannelInfo.Active" />}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className='pl-0 col-4'>
                                                <CommonHelper help={intl.formatMessage({ id:`ComplexProfile.${field.fieldName}Help` })} id={field.fieldName} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <hr className="mt-2" />
                                                <div>
                                                    <Input
                                                        type="text"
                                                        value={field.value}
                                                        name={field.fieldName}
                                                        onChange={e => this.handleValue(e, 'externalIntegrationsOptions')}
                                                        placeholder={intl.formatMessage({ id:`ComplexProfile.${field.fieldName}` })}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Collapse>
                </Card>
            </BlockUi>
        );
    }
}
export default injectIntl(ComplexProfile)