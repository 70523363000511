import React, { Component } from 'react';
import { Row, Col, CardHeader, Card, CardBody, Input, FormGroup, Label, UncontrolledTooltip, CustomInput, Button } from 'reactstrap';

import { getAirbnbCurrency, getAirbnbStandardFees } from "../../Base/Common/ReferenceDataFunctions";
import { getAPI, putAPI } from "../../Base/API"
import { handleNotification } from "../../Base/Notification"
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../Utils/CustomToolTip';


export class AirbnbPriceSettings extends Component {

    state = {
        error: null,
        block: false,
        blockUpdate: true,
        priceData: {
            cleaning_fee: null,
            default_daily_price: null,
            guests_included: null,
            listing_currency: null,
            listing_id: null,
            monthly_price_factor: null,
            price_per_extra_person: null,
            security_deposit: null,
            eligible_for_pass_through_taxes: null,
            weekend_price: null,
            weekly_price_factor: null,
            standard_fees: []
        }
    };



    constructor(props) {
        super(props);
        this.formPrice = React.createRef();
    }

    componentDidMount() {
       
    }


    updateForm = event => {
        let name = event.target.name;
        let val = event.target.value;
        this.setState(prevState => ({
            priceData: {
                ...prevState.priceData,
                [name]: val
            }
        }));
    };

    updateFormStandardFee = event => {
        let dataname = event.target.getAttribute('dataname');
        let datatype = event.target.getAttribute('datatype');
        let val = datatype === 'type' ? event.target.options[event.target.options.selectedIndex].id : event.target.value;
        

        let currElement = this.state.priceData.standard_fees ? this.state.priceData.standard_fees.find(el => el.fee_type === dataname) : null;

        this.setState(prevState => ({
            priceData: {
                ...prevState.priceData,
                standard_fees: [
                    ...(prevState.priceData.standard_fees && prevState.priceData.standard_fees.filter(el => el.fee_type !== dataname) || {}),
                    {
                        fee_type: dataname,
                        amount: datatype === 'amount' ? val : currElement ? currElement.amount : null,
                        amount_type: datatype === 'type' ? val : currElement ? currElement.amount_type : 'flat'
                    }
                ]
            }
        }));
    };

    getPriceInfo() { 
        this.setState({  block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data,
                        <FormattedMessage id="AirbnbPriceSttings.PriceSettingsError" />,
                        <FormattedMessage id="AirbnbPriceSttings.Error" />
                    ); 
                    this.setState({ block: false });
                } else {
                    
                    if (data.response?.pricing_setting && data.response.pricing_setting.standard_fees && data.response.pricing_setting.standard_fees.length > 0) {
                        for (var i = 0; i < data.response.pricing_setting.standard_fees.length; i++) {
                            if (data.response.pricing_setting.standard_fees[i].amount_type === 'flat')
                                data.response.pricing_setting.standard_fees[i].amount = data.response.pricing_setting.standard_fees[i].amount / 1000000

                        }
                    }

                    this.setState({ priceData: data.response?.pricing_setting, blockUpdate: false, block: false });
                }
            }
        }, '/api/Rate/Listing/v1/airbnb/' + this.props.channelInstanceId + '/pricing/' + this.props.roomCategoryId);

    }


   

    sendCurrency(evt) {

        evt.preventDefault();
        if (!this.formPrice.current.reportValidity()) {
            return;
        }

        let data = {
            listing_currency: this.state.priceData ? this.state.priceData.listing_currency : null
        };
        
        this.sendPriceInfo(data);
    }

    sendStandardFees(evt) {
        evt.preventDefault();
        if (!this.formPrice.current.reportValidity()) {
            return;
        }
        var stdFees = JSON.parse(JSON.stringify(this.state.priceData.standard_fees.filter(el => el.amount != null && el.amount != 0)));
        // set the amount in 
        if (stdFees && stdFees.length > 0) {
            for (var  i = 0; i < stdFees.length; i++) {
                if (stdFees[i].amount_type === 'flat')
                    stdFees[i].amount = stdFees[i].amount * 1000000
                
            }
        }
        
        let data = {
            
            security_deposit: this.state.priceData.security_deposit,
                cleaning_fee: this.state.priceData.cleaning_fee,
                guests_included: this.state.priceData.guests_included,
            price_per_extra_person: this.state.priceData.price_per_extra_person,
            standard_fees: stdFees
            
        };

        this.sendPriceInfo(data);
    }


    sendNightPrice(evt) {
        evt.preventDefault();
        if (!this.formPrice.current.reportValidity()) {
            return;
        }

        let data = {
            
            weekend_price: this.state.priceData.weekend_price,
            default_daily_price: this.state.priceData.default_daily_price
            
        };

        this.sendPriceInfo(data);
    }

    sendLOSdiscount(evt) {

        evt.preventDefault();
        if (!this.formPrice.current.reportValidity()) {
            return;
        }
        let data = {
           
                monthly_price_factor: this.state.priceData.monthly_price_factor,
                weekly_price_factor: this.state.priceData.weekly_price_factor
            
        };

        this.sendPriceInfo(data);
    }

    sendPriceInfo(dataReq) {
        this.setState({ block: true });
       
        putAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data);
                }
                else {
                    handleNotification(
                        data,
                        <FormattedMessage id="AirbnbPriceSttings.PriceSettingsUpdatedSuccessfully" />,
                        <FormattedMessage id="AirbnbPriceSttings.Success" />
                    );
                    if (data.response?.pricing_setting) {

                        if (data.response.pricing_setting && data.response.pricing_setting.standard_fees && data.response.pricing_setting.standard_fees.length > 0) {
                            for (var i = 0; i < data.response.pricing_setting.standard_fees.length; i++) {
                                if (data.response.pricing_setting.standard_fees[i].amount_type === 'flat')
                                    data.response.pricing_setting.standard_fees[i].amount = data.response.pricing_setting.standard_fees[i].amount / 1000000

                            }
                        }
                    }
                }

                this.setState({ priceData: data.response?.pricing_setting, block: false });
            } else
                this.setState({  block: false });
            
        }, '/api/Rate/Listing/v1/airbnb/' + this.props.channelInstanceId + '/pricing/' + this.props.roomCategoryId, dataReq);

    }



    render() {
        return (

            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="border-0 shadow">
                    <CardHeader className="bg-white border-bottom-1">
                        <i className="fas fa-sliders-h"></i> <FormattedMessage id="AirbnbPriceSettings.AirBNBPriceSettings" /> {this.props.roomCategoryId}
                        <Button className=" btn btn-host shadow  btn-sm float-right" onClick={this.getPriceInfo.bind(this)}> <FormattedMessage id="AirbnbPriceSettings.Get" /> </Button>

                </CardHeader>
            <CardBody>
                        <form ref={this.formPrice} >
                            <Row className="border-bottom my-2">
                                <Col>
                                    <h6> <FormattedMessage id="AirbnbPriceSettings.Currency" /> </h6>
                                </Col>
                                <Col>
                                    <Button disabled={this.state.blockUpdate} className=" btn btn-host shadow  btn-sm float-right" key="regulationSubmitbutton" onClick={this.sendCurrency.bind(this)}> <FormattedMessage id="AirbnbPriceSettings.Save" /> </Button>

                                </Col>
                            </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>
                                        <Label for="currency" sm={4}> <FormattedMessage id="AirbnbPriceSettings.Currency" /> </Label>
                                        <Col sm={5}>
                                            <CustomInput type="select" name="listing_currency" id="currency" bsSize="sm" className="text-sm" value={this.state.priceData && this.state.priceData.listing_currency ? this.state.priceData.listing_currency : ''} onChange={this.updateForm} >
                                            {
                                                getAirbnbCurrency().map((item, key) =>
                                                    <option key={key}  value={item}> {item}</option>
                                                    )
                                            }
                                            
                                        </CustomInput>
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="currencytooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                <CustomToolTip placement="right" target="currencytooptip">
                                     <FormattedMessage id="AirbnbPriceSettings.CurrencyUsedForListingPrices" />
                                </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                            </Row>
                    <Row className="border-bottom my-2">
                        <Col>
                            <h6> <FormattedMessage id="AirbnbPriceSettings.NightlyPrice" /> </h6>
                        </Col>
                        <Col>
                            <Button disabled={this.state.blockUpdate} className=" btn btn-host shadow  btn-sm float-right" key="regulationSubmitbutton" onClick={this.sendNightPrice.bind(this)}> <FormattedMessage id="AirbnbPriceSettings.Save" /> </Button>

                        </Col>
                    </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup row>

                            <Label for="dailyprice" sm={4}><FormattedMessage id="AirbnbPriceSettings.dailyprice" /></Label>
                                        <Col sm={5}>
                                            <Input type="number" name="default_daily_price" id="dailyprice" bsSize="sm" className="text-sm" value={this.state.priceData.default_daily_price ? this.state.priceData.default_daily_price : ''} max="25000" onChange={this.updateForm} />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="dailypricetooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="dailypricetooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.DefaultDailyPrice" />                                    
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="weekendprice" sm={4}><FormattedMessage id="AirbnbPriceSettings.weekendprice" /></Label>
                                        <Col sm={5}>
                                            <Input type="number" name="weekend_price" id="weekendprice" bsSize="sm" className="text-sm" value={this.state.priceData.weekend_price} max="25000" onChange={this.updateForm} />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="weekendpricetooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="weekendpricetooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.DefaultPriceToApply" />                                    
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                            </Row>
                            <Row className="border-bottom my-2 ">
                                <Col>
                                    <h6>
                                        <FormattedMessage id="AirbnbPriceSettings.StandardFeesAndCharges" /> 
                                    </h6>
                                </Col>
                                <Col>
                                    <Button disabled={this.state.blockUpdate} className=" btn btn-host shadow shadow  btn-sm float-right" key="regulationSubmitbutton" onClick={this.sendStandardFees.bind(this)}><FormattedMessage id="AirbnbPriceSettings.Save" /> </Button>

                                </Col>
                            </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="securitydeposit" sm={4}><FormattedMessage id="AirbnbPriceSettings.securitydeposit" /></Label>
                            <Col sm={5}>
                                            <Input type="number" name="security_deposit" id="securitydeposit" bsSize="sm" className="text-sm" value={this.state.priceData.security_deposit} max="25000" onChange={this.updateForm} />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="securitydeposittooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="securitydeposittooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.ASecurityDeposit" /> 
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="guestsincluded" sm={4}><FormattedMessage id="AirbnbPriceSettings.guestsincluded" /></Label>
                            <Col sm={5}>
                                            <Input type="number" name="guests_included" id="guestsincluded" bsSize="sm" className="text-sm" value={this.state.priceData.guests_included} min="1" max="50" onChange={this.updateForm}  />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="guestsincludedtooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="guestsincludedtooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.NumberOfGuestsPermitted" />
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="priceextraperson" sm={4}><FormattedMessage id="AirbnbPriceSettings.priceextraperson" /></Label>
                            <Col sm={5}>
                                            <Input type="number" name="price_per_extra_person" id="priceextraperson" bsSize="sm" className="text-sm" value={this.state.priceData.price_per_extra_person} onChange={this.updateForm}   />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="priceextrapersontooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="priceextrapersontooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.AmountAddedTo" />                                    
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                {getAirbnbStandardFees().map((item, key) =>
                    <Row key={key}>
                        <Col className="col-12">
                            <FormGroup row>

                                <Label for={item.value} sm={4}>{item.label}</Label>
                                <Col sm={5}>
                                    <Row>
                                        <Col className="col-3">
                                            <Input type="select" name={'amount_type' + item.value} id={'amount_type'+item.value} dataname={item.value} datatype={'type'} onChange={this.updateFormStandardFee}>
                                                <option id="flat" selected={this.state.priceData.standard_fees && this.state.priceData.standard_fees.find(el => el.fee_type === item.value) ? this.state.priceData.standard_fees.find(el => el.fee_type === item.value).amount_type === 'percent' ? false : true : true}>€</option>
                                                {item.value !== 'PASS_THROUGH_LINEN_FEE' ? 
                                                    <option id="percent" selected={this.state.priceData.standard_fees && this.state.priceData.standard_fees.find(el => el.fee_type === item.value) ? this.state.priceData.standard_fees.find(el => el.fee_type === item.value).amount_type === 'percent' ? true : false : false}  >%</option>
                                                    : ''
                                                }
                                                            
                                            </Input>
                                        </Col>
                                        <Col className="col-9">
                                            <Input type="number" name={item.value} id={item.value} dataname={item.value} datatype={'amount'} bsSize="sm" className="text-sm" step="1" value={this.state.priceData.standard_fees && this.state.priceData.standard_fees.find(el => el.fee_type === item.value) ? this.state.priceData.standard_fees.find(el => el.fee_type === item.value).amount : null} onChange={this.updateFormStandardFee} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="col-2" >


                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>

                )}
                <Row className="border-bottom my-2">
                    <Col>
                        <h6> <FormattedMessage id="AirbnbPriceSettings.LengthOfStayDiscounts" /> </h6>
                    </Col>
                    <Col>
                        <Button disabled={this.state.blockUpdate} className=" btn btn-host shadow  btn-sm float-right" key="regulationSubmitbutton" onClick={this.sendLOSdiscount.bind(this)}> <FormattedMessage id="AirbnbPriceSettings.Save" /></Button>

                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="monthlypricefactor" sm={4}><FormattedMessage id="AirbnbPriceSettings.monthlypricefactor" /></Label>
                                    <Col sm={5}>
                                            <Input type="number" name="monthly_price_factor" id="monthlypricefactor" bsSize="sm" className="text-sm" min="0" max="1" step="0.01" value={this.state.priceData.monthly_price_factor} onChange={this.updateForm} />
                            </Col>
                            <Col className="col-2" >
                                    <span href="#" id="monthlypricefactortooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="monthlypricefactortooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.MultiplyPriceLeast" />
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <FormGroup  row>

                            <Label for="weeklypricefactor" sm={4}><FormattedMessage id="AirbnbPriceSettings.weeklypricefactor" /></Label>
                            <Col sm={5}>
                                            <Input type="number" name="weekly_price_factor" id="weeklypricefactor" bsSize="sm" className="text-sm" min="0" max="1" step="0.01" value={this.state.priceData.weekly_price_factor} onChange={this.updateForm} />
                            </Col>
                            <Col className="col-2" >
                                <span href="#" id="weeklypricefactortooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                    <CustomToolTip placement="right" target="weeklypricefactortooptip">
                                        <FormattedMessage id="AirbnbPriceSettings.MultiplyPriceBetween" />
                                    </CustomToolTip>
                            </Col>
                        </FormGroup>
                    </Col>
                            </Row>

                           
                      
                    </form>
                </CardBody>
                </Card>
            </BlockUi>
        );
    }
}