import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader, Input, Form, Card, CardBody, Button, CustomInput } from 'reactstrap';
import { ActiveInactiveStatusBadge } from "../Base/Common/CommonUIComponents";
import { getBookingEngines, getMarkupType, priceMarkupIncludePCOptions } from "../Base/Common/ReferenceDataFunctions";
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Common/ErrorAlert";
import { postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import { ComboBoxWithTitle } from './ComboBoxWithTitle';
import { CheckAuthorization } from '../Base/Authorization';

class BulkOperation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            associatedRateCodeChannels: [],
            selectedMapping: [],
            applyMarkup: false,
            priceMarkupType: null,
            priceMarkupValue: null,
            interfaceMode: null,
            priceMode: null,
            changeMarkup: false,
            changeStatus: false,
            removeMappings: false,
            changeInterfaceMode: false,
            changePriceMode: false,
            changePublishedOn: false,
            changeExcludePriceComp: false,
            rateCodeChannel: this.props.rateCodeChannel,
            roomOptions: [],
            rateLabelOptions: []
        }
    }

    componentDidMount() {
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    changeApplyMarkup = (evt) => {
        let checked = evt.target.checked;

        this.setState(prevState => ({
            applyMarkup: checked,
            priceMarkupType: checked ? prevState.priceMarkupType : null,
            priceMarkupValue: checked ? prevState.priceMarkupValue : null
        }))
    }

    selectOperations = (combo) => {
        this.setState({
            changeMarkup: combo && combo.some(el => el.value === 'changeMarkup'),
            changeStatus: combo && combo.some(el => el.value === 'changeStatus'),
            removeMappings: combo && combo.some(el => el.value === 'removeMappings'),
            changeInterfaceMode: combo && combo.some(el => el.value === 'changeInterfaceMode'),
            changePriceMode: combo && combo.some(el => el.value === 'changePriceMode'),
            changePublishedOn: combo && combo.some(el => el.value === 'changePublishedOn'),
            changeExcludePriceComp: combo && combo.some(el => el.value === 'changeExcludePriceComp')
        })
    }

    changeSwitch = (evt) => {
        const { checked, id } = evt.target;

        this.setState({
            [id]: checked
        });
    }

    bulkOperation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { active, interfaceMode, priceMarkupValue, priceMarkupIncludePackageComponents, priceMarkupType, priceMode, removeMappings, selectedMapping,
            associatedRateCodeChannels, excludePriceComponent,
            applyMarkup, changeMarkup, changeStatus, changeInterfaceMode, changePriceMode, changePublishedOn, changeExcludePriceComp } = this.state;

        if (selectedMapping && selectedMapping.length < 1) {
            handleNotification({ warnings: [{ code: '', message: <FormattedMessage id="BulkOperation.ThereMustBeAtLeast1MapSelected" /> }] });
            this.setState({ block: false });
            return;
        }

        const body = {
            "active": active,
            "priceMarkupType": applyMarkup ? priceMarkupType : 0,
            "priceMarkupValue": applyMarkup ? priceMarkupValue : 0,
            "rateCodeChannelIds": selectedMapping,
            "priceMarkupIncludePackageComponents": priceMarkupIncludePackageComponents,
            "interfaceMode": interfaceMode,
            "priceMode": priceMode,
            "associatedRateCodeChannels": associatedRateCodeChannels,
            excludePriceComponent
        };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="EditChannelMapping.MappingSaved" />, <FormattedMessage id="EditChannelMapping.Success" />);
                    this.setState({ block: false });
                    this.props.getRates();
                    this.props.reloadChannelData();
                    this.props.toggle();
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/RateCodeChannel/v1/ratecodechannel/bulkOperation?changeMarkup=${changeMarkup}&changeStatus=${changeStatus}&removeMappings=${removeMappings}&changeInterfaceMode=${changeInterfaceMode}&changePriceMode=${changePriceMode}&changePublishedOn=${changePublishedOn}&changeExcludePriceComp=${changeExcludePriceComp}`, body);
    }

    handleAssociatedRateCodeChannels = (evt) => {
        const { associatedRateCodeChannels } = this.state;

        if (evt && evt.target) {
            const { checked, name } = evt.target;

            if (checked) {
                associatedRateCodeChannels.push( { id: null, channelCode: name } );
            }
            else {
                const idx = associatedRateCodeChannels.findIndex(aRcc => aRcc.channelCode === name);

                if (idx !== -1) {
                    associatedRateCodeChannels.splice(idx, 1);
                }
            }

            this.setState({ associatedRateCodeChannels });
        }
    }

    filterRoom = (combo, name) => {
        let { selectedRoom, selectedLabel, rateCodeChannel } = this.state;

        if (name === 'selectedRoom') selectedRoom = combo; else selectedLabel = combo;

        rateCodeChannel && rateCodeChannel.forEach(rcc => {
            if (selectedRoom && selectedLabel) {
                rcc.isHidden = !(selectedRoom.some(el => el.value === rcc.roomCategoryId) && selectedLabel.some(el => el.value === rcc.rateLabelId));
            }
            else if (selectedRoom) {
                rcc.isHidden = !selectedRoom.some(el => el.value === rcc.roomCategoryId);
            }
            else if (selectedLabel) {
                rcc.isHidden = !selectedLabel.some(el => el.value === rcc.rateLabelId);
            }
            else {
                rcc.isHidden = false;
            }
        })

        this.setState({ selectedRoom, selectedLabel, rateCodeChannel });
    }

    render() {
        const { interfaceModes, priceModes, rateLabelOptions, roomOptions } = this.props;
        const { changeInterfaceMode, changePriceMode, changePublishedOn, changeExcludePriceComp, rateCodeChannel } = this.state;

        const markupType = getMarkupType();

        const statusOptions = [
            { value: true, label: this.props.intl.formatMessage({ id: "generic.active" }) },
            { value: false, label: this.props.intl.formatMessage({ id: "generic.inactive" }) }
        ]

        const operationsOptions = [
            {
                value: 'changeMarkup',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeMarkup" })
            },
            {
                value: 'changeStatus',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeStatus" })
            },
            {
                value: 'removeMappings',
                label: this.props.intl.formatMessage({ id: "BulkOperation.RemoveMappings" })
            },
            {
                value: 'changeInterfaceMode',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeInterfaceMode" })
            },
            {
                value: 'changePriceMode',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangePriceMode" })
            },
            {
                value: 'changePublishedOn',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangePublishedOn" }),
                disabled: !(this.props.isHeybe && CheckAuthorization("channelMapping:mapGoogleMappings"))
            },
            {
                value: 'changeExcludePriceComp',
                label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeExcludePriceComp" })
            }
        ]

        const columns = [
            {
                dataField: 'localRateCode',
                text: this.props.intl.formatMessage({ id: "ChannelMapping.Rate" }),
                sort: true
            },
            {
                dataField: 'localRoomDesc',
                text: this.props.intl.formatMessage({ id: "ChannelMapping.Room" }),
                sort: true
            },
            {
                dataField: 'roomTypeMapping',
                text: this.props.intl.formatMessage({ id: "ChannelList.channelRoomCode" }),
                sort: true
            },
            {
                dataField: 'rateCodeMapping',
                text: this.props.intl.formatMessage({ id: "ChannelList.channelRateCode" }),
                sort: true
            },
            {
                dataField: 'priceMarkupType',
                text: this.props.intl.formatMessage({ id: "BulkOperation.MarkupType" }),
                sort: true,
                formatter: (cell) => {
                    const type = markupType.find(mt => mt.value === cell);
                    return type ? <span> {type.label} </span> : '';
                }
            },
            {
                dataField: 'priceMarkupValue',
                text: this.props.intl.formatMessage({ id: "BulkOperation.MarkupValue" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "ChannelList.status" }),
                sort: true,
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell}/>
            },
            {
                dataField: 'interfaceMode',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.Interface" }),
                sort: true,
                formatter: (cell) => cell >= 0 ? <FormattedMessage id={`generic.inventoryMode${cell}`} /> : ''
            },
            {
                dataField: 'priceMode',
                text: this.props.intl.formatMessage({ id: "EditChannelMapping.PriceMode" }),
                sort: true,
                formatter: (cell) => cell >= 0 ? <FormattedMessage id={`generic.priceMode${cell}`} /> : ''
            },
            {
                dataField: 'associatedRateCodeChannels',
                text: this.props.intl.formatMessage({ id: "ChannelMapping.PublishedOn" }),
                sort: true,
                formatter: (cell) => cell?.map(rcc => {
                    const img = getBookingEngines.find(el => el.value === rcc.channelCode)?.label;

                    return img || rcc.channelCode;
                })
            },
            {
                dataField: 'availabilityGroupMapping',
                text: this.props.intl.formatMessage({ id: "ChannelMapping.MapStatus" }),
                sort: true,
                formatter: (cell, row) => {
                    const obj = { 'channelRoomCode': row.roomTypeMapping, 'channelRateCode': row.rateCodeMapping, 'availabilityGroupMapping': row.availabilityGroupMapping, 'priceMarkupValue': row.priceMarkupValue };

                    return this.props.checkChannelManagerMapping(null, obj, true);
                }
            }

        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            selected: [...this.state.selectedMapping],
            onSelect: (row, isSelect, rowIndex, e) => {
                let list = [...this.state.selectedMapping];

                if (isSelect) {
                    list.push(row.id);
                }
                else {
                    list = list.filter(el => el !== row.id);
                }

                this.setState({ selectedMapping: list });
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.setState({ selectedMapping: rateCodeChannel.filter(r => !r.isHidden).map(r => r.id) });
                }
                else {
                    this.setState({ selectedMapping: [] });
                }
            }
        };

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="ChannelMapping.BulkOperation" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.bulkOperation}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row>
                                        <Col>
                                            <h5><FormattedMessage id="BulkOperation.Operation" /></h5>
                                        </Col>
                                        <Col className="text-right mb-2 col-2">
                                            <Button className="btn-sm btn-host" type="submit">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    
                                    <Row className="mb-4">
                                        <Col>
                                            <CustomSelect
                                                options={operationsOptions}
                                                required
                                                placeholder={this.props.intl.formatMessage({ id: "BulkOperation.Operation" })}
                                                onChange={this.selectOperations.bind(this)}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>

                                    <Row>

                                        {
                                            this.state.changeStatus ?
                                                <ComboBoxWithTitle title={<FormattedMessage id="ChannelList.status" />} name="active" options={statusOptions} onChangeFunc={this.handleChangeSelect.bind(this, 'active')} />
                                                :
                                                ''
                                        }
                                        {this.state.changeMarkup ?
                                            <Col className="mb-5 col-8">
                                                <Row className="mb-2">
                                                    <Col className="col-5">
                                                        <h5> <FormattedMessage id="EditChannelMapping.ApplyMarkup" /> </h5>
                                                    </Col>
                                                    <Col>
                                                        <CustomInput
                                                            type="switch" id="applyMarkup"
                                                            checked={this.state.applyMarkup}
                                                            onChange={this.changeApplyMarkup}
                                                        />
                                                    </Col>
                                                </Row>
                                                {this.state.applyMarkup === true ?
                                                    <Row className="align-items-center">
                                                        <Col className="col-1">
                                                            <FormattedMessage id="BulkOperation.Type" />
                                                        </Col>
                                                        <Col className="pl-0 col-3">
                                                            <CustomSelect name="priceMarkupType"
                                                                isClearable
                                                                options={markupType}
                                                                value={this.state.priceMarkupType ? markupType.filter(el => el.value === this.state.priceMarkupType) : ''}
                                                                onChange={this.handleChangeSelect.bind(this, 'priceMarkupType')}
                                                                placeholder={<FormattedMessage id="BulkOperation.Type" />}
                                                                required={this.state.applyMarkup}
                                                            />
                                                        </Col>
                                                        <Col className="col-1">
                                                            <FormattedMessage id="BulkOperation.Value" />
                                                        </Col>
                                                        <Col className="pl-0 col-2">
                                                            <Input type="number" name="priceMarkupValue"
                                                                placeholder={this.props.intl.formatMessage({ id: "BulkOperation.Value" })}
                                                                min='0.01' max="100.00" step="0.01"
                                                                value={this.state.priceMarkupValue || ''}
                                                                onChange={(evt) => this.setState({ priceMarkupValue: evt.target.value })}
                                                                required={this.state.applyMarkup}
                                                            />
                                                        </Col>
                                                        <Col className="col-1 pl-0">
                                                            <FormattedMessage id="BulkOperation.Option" />
                                                        </Col>
                                                        <Col>
                                                            <CustomSelect
                                                                name="priceMarkupIncludePackageComponents"
                                                                options={priceMarkupIncludePCOptions}
                                                                value={priceMarkupIncludePCOptions.find(el => el.value === this.state.priceMarkupIncludePackageComponents)}
                                                                onChange={this.handleChangeSelect.bind(this, 'priceMarkupIncludePackageComponents')}
                                                                placeholder={<FormattedMessage id="EditChannelMapping.Select" />}
                                                                required={this.state.applyMarkup}
                                                            />
                                                        </Col>
                                                    </Row>
                                                : ''}
                                            </Col>
                                        : ''}

                                        {
                                            changeInterfaceMode ?
                                                <ComboBoxWithTitle title={<FormattedMessage id="EditChannelMapping.Interface" />} name="interfaceMode" options={interfaceModes} onChangeFunc={this.handleChangeSelect.bind(this, 'interfaceMode')} />
                                                :
                                                ''
                                        }
                                        
                                        {
                                            changePriceMode ?
                                                <ComboBoxWithTitle title={<FormattedMessage id="EditChannelMapping.PriceMode" />} name="priceMode" options={priceModes} onChangeFunc={this.handleChangeSelect.bind(this, 'priceMode')} />
                                                :
                                                ''
                                        }

                                        {changePublishedOn ?
                                            <Col>
                                                <h5 className="mb-3 pb-1"><FormattedMessage id="EditChannelMapping.PublishOn" /></h5>
                                                <Row>                                                
                                                    {getBookingEngines.map((bookingEngine, bookingEngineIdx) =>
                                                        <Col key={bookingEngineIdx}>
                                                            <CustomInput type="checkbox" checked={this.state.associatedRateCodeChannels?.find(aRcc => aRcc.channelCode === bookingEngine.value)} id={`${bookingEngine.value}`} name={`${bookingEngine.value}`} onChange={this.handleAssociatedRateCodeChannels}>
                                                                {bookingEngine.label} <span className="mt-1">{bookingEngine.name}</span>
                                                            </CustomInput>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            : ''}

                                        {changeExcludePriceComp && 
                                            <Col className="mb-5 col-4">
                                                <Row className="mb-2">
                                                    <Col className="col-8">
                                                        <h5> <FormattedMessage id="EditChannelMapping.ExcludePriceComponent" /> </h5>
                                                    </Col>
                                                    <Col>
                                                        <CustomInput
                                                            type="switch"
                                                            id="excludePriceComponent"
                                                            checked={this.state.excludePriceComponent}
                                                            onChange={this.changeSwitch}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>                                            
                                        }
                                    </Row>
                                                                       

                                    <h5 className="mb-2 mt-4"><FormattedMessage id="BulkOperation.SelectMapping" /> </h5>

                                    <Row className="mb-2 align-items-center">
                                        <Col className='col-3 small pr-0'>
                                            <CustomSelect
                                                isClearable
                                                options={roomOptions}
                                                onChange={(e) => this.filterRoom(e, 'selectedRoom')}
                                                placeholder={<FormattedMessage id="ChannelMapping.CMRoom"/>}
                                                isMulti
                                            />
                                        </Col>
                                        {rateLabelOptions?.length > 0 && global.operationMode !== 'PmsFull' &&
                                            <Col className="col-3 small">
                                                <CustomSelect
                                                    isClearable
                                                    options={rateLabelOptions}
                                                    onChange={(e) => this.filterRoom(e, 'selectedLabel')}
                                                    placeholder={<FormattedMessage id="ChannelMapping.RateLabel"/>}
                                                    isMulti
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <CustomTable
                                        data={rateCodeChannel ? rateCodeChannel.filter(rcc => !rcc.isHidden) : []}
                                        columns={columns}
                                        shadow={false}
                                        selectRow={selectRow}
                                    />
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(BulkOperation)