import classnames from "classnames";
import React, { Component } from "react";
import BlockUi from "react-block-ui";
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from "react-intl";
import Authorization, { CheckAuthorization } from '../Base/Authorization';
import {
	Badge,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane,
	UncontrolledButtonDropdown,
	UncontrolledTooltip,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from "reactstrap";
import { getAPI, postAPI } from "../Base/API";
import { getBookingEngines, getChannelConfig, getChannelsLoadDataFromFile, getChannelsReadOnly, getChannelsWithSingleRoomRestriction, getHasAvailabilityGroupMapping, getInterfaceMode, getIsChannelReadOnly, getIsHostelWorld, getIsHrs, getIsJumboTours, getIsJuniper, getIsNotSapo, getOperationModes, getPriceMode, getShowInterfaceMode4, getShowInterfaceMode6 } from "../Base/Common/ReferenceDataFunctions";
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import ChannelCommonData from "./ChannelCommonData";
import ChannelInstanceDetail from "./ChannelInstanceDetail";
import ChannelMapping from "./ChannelMapping";
import ChannelRoomRate from "./ChannelRoomRate";
import { ImportListingsModal } from "./ImportListingsModal";
import moment from "moment";
import CustomSelect from "../Base/Common/CustomSelect";
import ChangePassword from "./ChangePassword";
import ChangePricingModal from "./ChangePricingModal";
import ChannelInstanceAdminConfig from "./ChannelInstanceAdminConfig";
import { CustomSingleDatePicker } from '../Base/Common/CustomReactDates';
import { checkMapStatus } from "./ChannelFunctions";


export class ChannelConf extends Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.refreshChannelData = this.refreshChannelData.bind(this);
		this.testConnectivity = this.testConnectivity.bind(this);
		this.getChannelData = this.getChannelData.bind(this);
		this.getRates = this.getRates.bind(this);
		this.getChannelConfigState = this.getChannelConfigState.bind(this);
		this.getChannelConfig = this.getChannelConfig.bind(this);
		this.toggleImportListingsModal = this.toggleImportListingsModal.bind(this);
		this.form = React.createRef();

		//if (isNotSapo === false) canal apenas permite 1 mapeamento por rate  
		const isNotSapo = getIsNotSapo(this.props.selectedChannel.code);
		//Canais internos - não carregam dados do canal, não mostram o cartão "Reference Data Mapping" nem a tab "Channel Info"
		const hasChannelData = this.props.selectedChannel.hasChannelData;
		const isJumboTours = getIsJumboTours(this.props.selectedChannel.code);
		const isHostelWorld = getIsHostelWorld(this.props.selectedChannel.code);
		const isJuniper = getIsJuniper(this.props.selectedChannel.code);
		const isHrs = getIsHrs(this.props.selectedChannel.code);
		const showChannelInfo = isNotSapo && !hasChannelData && !this.props.selectedChannel.isInternalChannel && this.props.selectedChannel.code !== 'GOOGLEHOTELADS';
		const showInterfaceMode4 = getShowInterfaceMode4(this.props.selectedChannel.code);
		const showInterfaceMode6 = getShowInterfaceMode6(this.props.selectedChannel.code);

		this.state = {
			activeTab: this.props.activeTab ? this.props.activeTab : "1",
			activeAddressTab: "1",
			Map: [],
			channelDataMap: [],
			channelDataMapAirbnb: [],
			rateList: [],
			notification: { type: "Success", message: "" },
			error: [],
			block: true,
			blockChannelData: false,
			blockAction: false,
			modaladd: false,
			modalResHist: false,
			passwordModal: false,
			changePricingModal: false,
			priceModes: [],
			isIcal: false,
			importListingsModal: false,
			channelsLoadDataFromFile: getChannelsLoadDataFromFile(),
			hideUserAndPasswordChannels: [
				"HEYBE",
				"HEYTRAVEL"
			],
			supported: [],
			sendToPms: false,
			channelsWithSingleRoomRestriction: getChannelsWithSingleRoomRestriction(),
			hasAvailabilityGroupMapping: false,
			mandatoryFields: [],
			hasHotelBedsSpecific: false,
			hasDates: false,
			bookingEngines: getBookingEngines.filter(be => this.props.metaSearchChannelsCreated?.find(meta => meta === be.value) !== undefined),
			channelsReadOnly: getChannelsReadOnly(),
			//if (isNotSapo === false) canal apenas permite 1 mapeamento por rate  
			isNotSapo: isNotSapo,
			//Canais internos - não carregam dados do canal, não mostram o cartão "Reference Data Mapping" nem a tab "Channel Info"
			hasChannelData: hasChannelData,
			isJumboTours: isJumboTours,
			isHostelWorld: isHostelWorld,
			isJuniper: isJuniper,
			showChannelInfo: showChannelInfo,
			hasAvailabilityGroupMapping: getHasAvailabilityGroupMapping(this.props.selectedChannel.code),
			isChannelReadOnly: getIsChannelReadOnly(this.props.selectedChannel.code),
			showInterfaceMode4: showInterfaceMode4,
			showInterfaceMode6: showInterfaceMode6,
			isHrs: isHrs,
			hasRoomLevelRestrictions: false,
			overrideUrls: false,
			interfaceModes: showInterfaceMode6 ? getInterfaceMode().filter(el => el.value !== 4) : showInterfaceMode4 ? getInterfaceMode().filter(el => el.value !== 6) : getInterfaceMode().filter(el => el.value !== 4 && el.value !== 6),
			totalMapWithError: null,
			isHeybe: false
		};
	}

	updateMapStatus = () => {
		var mapIncommingOffice = this.state.isJumboTours || this.state.isJuniper
		var result = checkMapStatus(this.state.channelDataMapAirbnb, this.state.channelDataMap, this.state.ratecodechannel, this.props.selectedChannel, this.state.hasAvailabilityGroupMapping, this.state.rateList, mapIncommingOffice)
		
		this.setState({
			blockChannelData: false,
			channelDataMap: result.channelData,
			channelDataMapAirbnb: result.channelDataAirbnb,
			totalMapWithError: result.errorCount
		})
	}

	getChannelData() { 
		getAPI(result => {
			const { data, error } = result;
			this.setState({ blockChannelData: true });
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage }, this.updateMapStatus);
				return;
			}
			if (data && data.length > 0) {
				let channelDataMapAirbnb = [];
				if (this.props.selectedChannel.channelName.toUpperCase() === "AIRBNB") {
					channelDataMapAirbnb = data.reduce((acc, curr) => {
						const channelData = acc.find(item => item.roomCode === curr.roomCode);
						if (channelData) {
							const cd = {
								rateCode: curr.rateCode,
								rateDescription: curr.rateDescription,
								active: curr.active
							};
							channelData.channelData.push(cd);
						}
						else {
							const item = {
								id: curr.id,
								roomCode: curr.roomCode,
								roomDescription: curr.roomDescription,
								maxPersons: curr.maxPersons,
								syncType: curr.syncType,
								active: curr.active,
								mappingNotNeeded: curr.mappingNotNeeded,
								channelData: []
							};
							if (curr.roomCode !== curr.rateCode) {
								const cd = {
									rateCode: curr.rateCode,
									rateDescription: curr.rateDescription,
									active: curr.active
								};
								item.channelData.push(cd);
							}
							acc.push(item);
						}
						return acc;
					}, []);
				}
				data.forEach(d => {
					if (d.occupancies && d.occupancies.length > 0) {
						d.occupancies = d.occupancies.map(occ => {
							const occs = occ.split('-');
							const item = {
								adults: parseInt(occs[0]),
								childrens: parseInt(occs[1])
							}
							return item;
						});
						d.occupancies.sort((occ1, occ2) => {
							const diff = occ1.adults - occ2.adults;
							return diff === 0 ? occ1.childrens - occ2.childrens : diff;
						});
					}
				});
				this.setState({
					channelDataMapAirbnb: channelDataMapAirbnb,
					channelDataMap: data,
					hasHotelBedsSpecific: data.find(cData => cData.hotelBedsSpecific !== undefined) !== undefined,
					hasDates: data.find(cData => cData.startingDate !== null || cData.endingDate !== null) !== undefined
				}, this.updateMapStatus);
			}
			else this.updateMapStatus();
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/ChannelData");
	}

	componentDidMount() {
		this.getRates();

		getAPI(result => {
			const { data, error, isLoading } = result;
			this.setState({ block: isLoading });
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				this.setState({ CommonData: data, blocking: false });
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/CommonData");
		this.reloadMap();
		this.getChannelConfigState();
		this.getChannelConfig();
	}

	reloadMap() {
		getAPI(result => {
			const { data, error, isLoading } = result;
			this.setState({ block: isLoading });
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				this.setState({ Map: data, blocking: false });
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/Map");
	}

	reloadChannelData = () => {
		this.setState({ blockReloadChannelData: true });

		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message,stack: error.stack,messageType: "danger" });
				this.setState({ error: errorMessage, blockReloadChannelData: false }, this.getChannelData);
				return;
			}
			if (data) {
				this.setState({ ratecodechannel: data.response ? data.response : [], blockReloadChannelData: false }, this.getChannelData);
			}
		}, "/api/Rate/RateCodeChannel/v1/ChannelInstance/" + this.props.selectedChannel.id + "");
	}

	getRates() {
		const { selectedChannel } = this.props;
		this.setState({ block: true });
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false }, this.reloadChannelData);
				return;
			}
			if (data) {
				const { channelsWithSingleRoomRestriction, channelsReadOnly } = this.state;
				const isChannelReadOnly = channelsReadOnly.find(c => c === selectedChannel.code) !== undefined;
				const isChannelsWithSingleRoomRestriction = channelsWithSingleRoomRestriction.find(c => c === selectedChannel.code) !== undefined;

				if (isChannelReadOnly || isChannelsWithSingleRoomRestriction) {
					const isDisabledToAddForChannelReadOnly = () => { return true; }
					const isDisabledToAddForChannelsWithSingleRoomRestriction = (rate) => { return rate.rateList && rate.rateList.find(r => r.mappingList.find(m => m.roomCode !== null) !== undefined) ? true : false; }
					const isDisabledToAdd = isChannelReadOnly ? isDisabledToAddForChannelReadOnly : isDisabledToAddForChannelsWithSingleRoomRestriction;
					
					const isDisabledToEditForChannelReadOnly = () => { return true; }
					const isDisabledToEditForChannelsWithSingleRoomRestriction = () => { return false; }
					const isDisabledToEdit = isChannelReadOnly ? isDisabledToEditForChannelReadOnly : isDisabledToEditForChannelsWithSingleRoomRestriction;

					data.response && data.response.forEach(rate => {
						rate.isDisabledToAdd = isDisabledToAdd(rate);
						rate.isDisabledToEdit = isDisabledToEdit(rate);
						return rate;
					});
				}
				else {
					data.response && data.response.forEach(rate => {
						rate.isDisabledToAdd = false;
						rate.isDisabledToEdit = false;
						return rate;
					});
				}
				this.setState({ rateList: data.response, block: false }, this.reloadChannelData);
			}
			else {
				this.setState({ block: false }, this.reloadChannelData);
            }
		}, '/api/Rate/Rate/v1/RateMapping/' + selectedChannel.id);
	}

	getChannelConfigState() {
		getAPI(result => {
			const { data, error, isLoading } = result;
			this.setState({ blocking: isLoading });
			const errorMessage = [];
			if (error) {
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, blocking: false });
				return;
			}
			if (data && data.response && data.response.channelConfig) {
				this.setState({ supported: data.response.channelConfig.supported });
			}
			if (data && data.response && data.response.priceModes) {
				let priceModes = [...this.state.priceModes];
				priceModes = getPriceMode().filter(priceMode => data.response.priceModes.find(value => "" + priceMode.value === "" + value));
				this.setState({ priceModes, mandatoryFields: data.response.mandatoryFields });
			}
			else {
                this.setState({ error: errorMessage, priceModes :[],  blocking: false });
            }
		}, `/api/Rate/ChannelConfig/v1/ChannelConfigState/${this.props.selectedChannel.channelConfigId}`);
	}

	getChannelConfig() {
		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				const channelConfig = data.length > 0 ? data[0] : null;

				if (channelConfig) {
					let { interfaceModes, showChannelInfo } = this.state;

					if (!(channelConfig.hasRoomLevelRestrictions || channelConfig.code === 'WEEKENDESK')) {
						interfaceModes = interfaceModes.filter(el => el.value !== 5)
                    }

					this.setState({
						interfaceModes: interfaceModes,
						isIcal: channelConfig.isIcal,
						hasRoomLevelRestrictions: channelConfig.hasRoomLevelRestrictions,
						showChannelInfo: showChannelInfo && !channelConfig.isIcal,
						overrideUrls: channelConfig.overrideUrls,
						isHeybe: channelConfig.code === 'HEYBE'
					});
				}

				this.setState({ block: false });
			}
		}, `/api/Rate/ChannelConfig/v1?id=${this.props.selectedChannel.channelConfigId}`);
	}

	toggleBlocking() {
		this.setState({ blocking: !this.state.blocking });
	}

	testConnectivity() {
		this.setState({ blockAction: true });

		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, blockAction: false });
				return;
			}
			if (data) {
				handleNotification(
					data,
					<FormattedMessage id="ChannelConf.ConnectionTestSuccessful" />,
					<FormattedMessage id="ChannelConf.Success" />
				);
				this.setState({ blockAction: false });
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/TestConnectivity");
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	refreshChannelData() {
		this.setState({ blockAction: true });
		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({
					message: error.message,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, blockAction: false });

				return;
			}
			if (data) {
				handleNotification(data, <FormattedMessage id="ChannelConf.ChannelDataRefreshed" />, <FormattedMessage id="ChannelConf.Success" />);
				this.setState({ blockAction: false }, () =>
                    this.getChannelData()
				);
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/ChannelData/Refresh");
	}

	send(endpoint, msg, success) {
		this.setState({ blockAction: true });
		postAPI(result => {
			const { data, error } = result;
			this.setState({ blockAction: true });

			if (error) {
				var errorMessage = [];
				if (error.request && error.request.status === 504 || (endpoint === 'SendPrice' && (error.request.status === 500 || error.request.status === 404))) {
					handleNotification({}, <FormattedMessage id="ChannelConf.TimeoutConsultLogs" values={{ action: endpoint }} />, <FormattedMessage id="ChannelConf.Timeout" />, 'info');
				}
				else {
					errorMessage.push({ message: error.message, messageType: "danger" });
				}	

				this.setState({ error: errorMessage, blockAction: false });
				return;
			}
			if (data) {
				handleNotification( data, <FormattedMessage id={msg} />, <FormattedMessage id="ChannelConf.Success" /> );
				
                if (success) {
                    //this.success();
					this.setState({ blockAction: false }, () => {
						if (this.props.refreshData) this.props.refreshData();
						if (this.props.toggleModalAdd) this.props.toggleModalAdd();
					});
                } else {
                    this.setState({ blockAction: false });
                }
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/" + endpoint);
	}

    getReservationHistory(evt) {
        evt.preventDefault();
        
        if (!this.form.current.reportValidity()) {
            return;
        }
        this.setState({ blockAction: true });
        postAPI(result => {
            const { data, error } = result;
			const errorMessage = [];

            if (error) {                
				if (error.request && error.request.status === 504) {
					handleNotification({}, <FormattedMessage id="ChannelConf.TimeoutConsultLogs" values={{ action: 'GetReservationHistory' }} />, <FormattedMessage id="ChannelConf.Timeout" />, 'info');
				}
				else {
					errorMessage.push({ message: error.message, messageType: "danger" });
                }
                this.setState({ error: errorMessage, blockAction: false });
                return;
            }
			if (data) {
				handleNotification(data, <FormattedMessage id="ChannelConf.ReservationProcessed" />, <FormattedMessage id="ChannelConf.Success" />);

				if (data.errors.length === 0 && data.warnings.length === 0) {
					this.setState({ error: errorMessage, blockAction: false }, () => {
						if (this.props.refreshData) this.props.refreshData();
						this.toggleResvHistoryModal();
					});
				} else {
					this.setState({ error: errorMessage, blockAction: false });
				}
			}
			else {
				this.setState({ error: errorMessage, blockAction: false });
            }
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + this.props.selectedChannel.id + "/GetReservationHistory?fromDate=" + this.state.date.format('YYYY-MM-DD') + "&sendToPms=" + this.state.sendToPms);


    }

	toggleDecommissionModal() {
		this.setState(prevState => ({
			modaladd: !prevState.modaladd
		}));
	}

    toggleResvHistoryModal() {
        this.setState(prevState => ({
            modalResHist: !prevState.modalResHist
        }));
    }

	toggleImportListingsModal() {
		this.setState(prevState => ({ importListingsModal: !prevState.importListingsModal }));
	}

	setCombo(name, combo) {
		this.setState({ [name]: combo && combo.value })
	}

	toggleModal = (modal) => {
		this.setState(prevState => ({
			[modal]: !prevState[modal]
		}));
	}

	updateChannelMap = (list, array) => {
		const data = [...this.state[array]];

		list.forEach(el => {
			const index = data.findIndex(d => d.id === el.id);

			if (index !== -1) {
				data[index].mappingNotNeeded = el.mappingNotNeeded;
            }
        })

		this.setState({ [array]: data }, this.updateMapStatus());
    }

	render() {
		const { selectedChannel, toggleModalAdd, isChannelList, user, updateChannelInstance, configApiKey, hotelId, getRateMappingFromBEConfig } = this.props;
		const { channelsLoadDataFromFile, importListingsModal, hideUserAndPasswordChannels, hasHotelBedsSpecific, hasDates, passwordModal, changePricingModal, modalResHist, modaladd, block,
		isNotSapo, hasChannelData, isJumboTours, isHostelWorld, isJuniper, showChannelInfo, isHrs, overrideUrls, totalMapWithError, activeTab, blockAction, date, sendToPms, blockChannelData,
		error, supported, mandatoryFields, ratecodechannel, rateList, channelsWithSingleRoomRestriction, Map, channelDataMap, CommonData, priceModes, isIcal, hasAvailabilityGroupMapping,
		bookingEngines, channelsReadOnly, isChannelReadOnly, showInterfaceMode4, hasRoomLevelRestrictions, interfaceModes, isHeybe, channelDataMapAirbnb, blockReloadChannelData } = this.state;
		const channelConf = getChannelConfig(this.props.selectedChannel.code);
		const sendToPmsOptions = [
			{
				value: false,
				label: <FormattedMessage id="ChannelConfig.No" />
			},
			{
				value: true,
				label: <FormattedMessage id="ChannelConfig.Yes" />
			},
		];

		return (
			<div>
				<>
					<ChangePassword
						modal={passwordModal}
						toggleModal={() => this.toggleModal('passwordModal')}
						channelInstanceId={selectedChannel.id}
					/>

					<ChangePricingModal
						modal={changePricingModal}
						toggleModal={() => this.toggleModal('changePricingModal')}
						toggleModalSuccess={toggleModalAdd}
						channelInstanceId={selectedChannel.id}
						mode={selectedChannel.mode}
					/>

					{importListingsModal &&
						<ImportListingsModal
							modal={importListingsModal}
							toggleModal={this.toggleImportListingsModal}
							channelInstanceId={selectedChannel?.id}
							channel={selectedChannel?.channelName}
							channelCode={selectedChannel?.code}
						/>
					}
				</>
				<Nav tabs>
					<NavItem>
						<NavLink onClick={() => this.toggle("1")} className={isChannelList ? 'ml-1' : '' + classnames({ active: activeTab === "1" })}>
							{channelConf ?
								<img
									className="mr-1"
									src={channelConf.image}
									alt={channelConf.image}
									height={channelConf.iconSize}
								/>
							:""}
							<FormattedMessage id="ChannelConfig.Config" />
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={classnames({ active: activeTab === "2" })} onClick={() => this.toggle("2")}>
							<FormattedMessage id="ChannelConfig.Mapping" />
						</NavLink>
					</NavItem>
					{showChannelInfo && totalMapWithError !== null && selectedChannel.code !== 'MAILRES' ?
						<NavItem>
							<NavLink className={classnames({ active: activeTab === "3" })} onClick={() => this.toggle("3")} >
								<FormattedMessage id="ChannelConfig.ChannelRoom/Rate" />
								{totalMapWithError > 0 ?
									<span>
										<Badge color="info" id="mappingDifferencesData" className="ml-1" >
											{totalMapWithError}
										</Badge>
										<UncontrolledTooltip placement="top" target="mappingDifferencesData" >
											<FormattedMessage id="ChannelConfig.NumberAreNotMapped" />
										</UncontrolledTooltip>
									</span>
								:""}
							</NavLink>
						</NavItem>
					:''}
					{channelConf?.HasCommonData ?
						<NavItem>
							<NavLink className={classnames({ active: activeTab === "4" })} onClick={() => this.toggle("4")}>
								<FormattedMessage id="ChannelConfig.ChannelCommonData" />
							</NavLink>
						</NavItem>
					:""}

					<Authorization
						perform="channelConf:adminConfig"
						yes={() => 
							overrideUrls ?
								<NavItem>
									<NavLink className={classnames({ active: activeTab === "5" })} onClick={() => this.toggle("5")} >
										<FormattedMessage id="ChannelConfig.AdminConfig" />
									</NavLink>
								</NavItem>
							:''
						}
						no={() => ""}
					/>

					<Authorization
						perform="channelConf:actions"
						yes={() => (
							<span className="ml-auto text-nowrap border-top-0 border-left-0 border-right-0">
								<UncontrolledButtonDropdown>
								{selectedChannel?.code === 'MAILRES' ?
									<DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }}>
										<i className="fas fa-ellipsis-v" />
									</DropdownToggle>
								:
									<DropdownToggle caret className="btn-host btn-sm mr-4">
										<FormattedMessage id="ChannelConfig.Action"/>
									</DropdownToggle>
								}
									<DropdownMenu right>
										<BlockUi tag="div" blocking={blockAction}>
											<DropdownItem header>
												<span className="mr-1">{selectedChannel.CHANNELNAME}</span>
												<FormattedMessage id="ChannelConfig.Actions" />
											</DropdownItem>
											{channelConf && channelConf.Actions.indexOf("TestConnection") >= 0 ?
												<DropdownItem disabled={!selectedChannel.hasPassword} onClick={this.testConnectivity}>
													<i className="fas fa-plug mr-2" />
													<FormattedMessage id="ChannelConfig.TestConnection" />
												</DropdownItem>
											:""}
											<DropdownItem divider />
											{channelConf && channelConf.Actions.indexOf("importListings") >= 0 ?
												<DropdownItem disabled={!selectedChannel.hasPassword || ((selectedChannel.code === 'HOST' || selectedChannel.code === 'GOOGLEHOTELADS') && !selectedChannel.active)}
													onClick={() => {
														if (channelsLoadDataFromFile.find(channelCode => channelCode === selectedChannel.code) !== undefined) {
															this.toggleImportListingsModal();
														}
														else {
															this.refreshChannelData();
														}
													}}
												>
													<i className="fas fa-list-ul mr-2" />
													{selectedChannel.code === 'HOST' || selectedChannel.code === 'GOOGLEHOTELADS' ?
														<FormattedMessage id="ChannelConfig.ExportListings"/>
													:
														<FormattedMessage id="ChannelConfig.ImportListings"/>
													}
												</DropdownItem>
											:""}
											{channelConf && channelConf.Actions.indexOf("sendPrice") >= 0 ?
												<DropdownItem disabled={!selectedChannel.active || !selectedChannel.hasPassword} onClick={this.send.bind(this, "SendPrice", "ChannelConf.SendPrinceSuccess")}>
													<i className="fas fa-hand-holding-usd mr-2" />
													<FormattedMessage id="ChannelConfig.Push" />{" "}
													{channelConf.PushType != "all" ?
														"Rates"
													:""}
												</DropdownItem>
											:""}
											{channelConf && channelConf.Actions.indexOf("sendAvailability") >= 0 ?
												<DropdownItem disabled={!selectedChannel.active || !selectedChannel.hasPassword} onClick={this.send.bind(this, "SendAvailability", "ChannelConf.SendAvailabilitySuccess" )}>
													<i className="fas fa-share-square mr-2" />
													<FormattedMessage id="ChannelConfig.PushAvailability" />
												</DropdownItem>
											:""}
											{channelConf && channelConf.Actions.indexOf("sendInventory") >= 0 ?
												<DropdownItem disabled={!selectedChannel.active || !selectedChannel.hasPassword} onClick={this.send.bind(this, "SendInventory", "ChannelConf.SendInventorySuccess" )}>
													<i className="fas fa-boxes mr-2" />
													<FormattedMessage id="ChannelConfig.PushInventory" />
												</DropdownItem>
											:""}
											{selectedChannel === "BOOKING" || selectedChannel === "EXPEDIA" ?
												<DropdownItem divider />
											:""}
											{channelConf && channelConf.Actions.indexOf("checkInventory") >= 0 ?
												<DropdownItem disabled={!selectedChannel.active || !selectedChannel.hasPassword} onClick={this.send.bind(this,"GetReservation","ChannelConf.GetReservationSuccess")}>
													<i className="fas fa-key mr-2" />
													<FormattedMessage id="ChannelConfig.CheckReservations" />
												</DropdownItem>
											:""}
											{channelConf && channelConf.Actions.indexOf("reservationHistory") >= 0 ?
												<>
													<DropdownItem disabled={!selectedChannel.active || !selectedChannel.hasPassword} onClick={() => this.toggleResvHistoryModal()}>
														<i className="far fa-calendar-check mr-2" />
														<FormattedMessage id="ChannelConfig.GetReservationHistory" />
													</DropdownItem>
													<Modal isOpen={modalResHist} toggle={() => this.toggleResvHistoryModal()} fade={false} style={{ minWidth: 0 }}>
														<ModalHeader toggle={() => this.toggleResvHistoryModal()}>
															<FormattedMessage id="AddRule.Confirmation" />
														</ModalHeader>
														<BlockUi tag="div" blocking={blockAction}>
															<ModalBody>
																<form ref={this.form}>
																	<Row>
																		<Col className="col-4">
																			<FormattedMessage id="ChannelConfig.GetReservationHistory" />
																		</Col>
																		<Col>
																			<CustomSingleDatePicker
																				id="Date"
																				required={true}
																				showTodaysButton={true}
																				isOutsideRange={day => (channelConf.code === "booking" ? day <= moment().add(-2, 'weeks') : day <= moment().add(-1, 'month')) || day > moment()}
																				date={date}
																				onDateChange={date => this.setState({ date })}
																				numberOfMonths={1}
																			/>
																		</Col>
																	</Row>
																	<Row>
																		<Col className="col-4">
																			<FormattedMessage id="ChannelConfig.SendToPms" />
																		</Col>
																		<Col>
																			<CustomSelect
																				id="sendToPms"
																				name="sendToPms"
																				options={sendToPmsOptions}
																				value={sendToPmsOptions.find(opt => opt.value === sendToPms)}
																				onChange={this.setCombo.bind(this, 'sendToPms')}
																				required
																			/>
																		</Col>
																	</Row>
																</form>
															</ModalBody>
															<ModalFooter>
																<Button className="btn-sm" color="secondary" onClick={() => this.toggleResvHistoryModal()}>
																	<FormattedMessage id="BookingChatWindow.Cancel" />
																</Button>
																<Button className="btn-sm" color="primary" onClick={this.getReservationHistory.bind(this)}>
																	<FormattedMessage id="AddRule.Confirm" />
																</Button>
															</ModalFooter>
														</BlockUi>
													</Modal>
												</>
											:""}
											{selectedChannel.code === "AIRBNB" ?
												<DropdownItem onClick={() => this.toggleModal('changePricingModal')}>
													<i className="fas fa-dollar-sign mr-2"/>
													<FormattedMessage id="ChannelList.ModeSet"/>
												</DropdownItem>
											:""}
											{channelConf && channelConf.Actions.indexOf("unlinkConnection") >= 0 ?
												<DropdownItem disabled={(selectedChannel.code !== "AIRBNB" && !selectedChannel.active) || !selectedChannel.hasPassword} onClick={this.send.bind(this, "Unlink", "ChannelConf.UnlinkSuccess")}>
													<i className="fas fa-link mr-2" />
													<FormattedMessage id="ChannelConfig.UnlinkConnection" />
												</DropdownItem>
											:""}
											<Authorization
												perform="channel:enable"
												yes={() => selectedChannel.active ?
													<DropdownItem onClick={this.send.bind(this, 'Disable', "ChannelConf.DisableSuccess")}>
														<i className="fas fa-power-off mr-1"/>
														<FormattedMessage id="ChannelConfig.Disable"/>
													</DropdownItem>
												:
													<DropdownItem disabled={!(selectedChannel.channelConfigState === null || selectedChannel.channelConfigState === 'Verified')} onClick={this.send.bind(this, 'Enable', "ChannelConf.EnableSuccess")}>
														<i className="fas fa-power-off"/>
														<FormattedMessage id="ChannelConfig.Enable"/>
													</DropdownItem>
												}
												no={() => ""}
											/>
											{channelConf && channelConf.Actions.indexOf("Delete") >= 0 ?
												selectedChannel.code === "AIRBNB" || selectedChannel.code === "GOOGLEHOTELADS" || CheckAuthorization("channel:delete", null) ?
													<>
														<DropdownItem onClick={() => this.toggleDecommissionModal()} >
															<i className="fas fa-trash mr-2" />
															<FormattedMessage id="ChannelConfig.Delete" />
														</DropdownItem>

														<Modal isOpen={modaladd} toggle={() => this.toggleDecommissionModal()} fade={false} size={"sm"} style={{ minWidth: 0 }} >
															<ModalHeader toggle={() => this.toggleDecommissionModal()} >
																<FormattedMessage id="AddRule.Confirmation" />
															</ModalHeader>
															<ModalBody>
																<FormattedMessage id="ChannelConfig.ConfirmDecommission" />
															</ModalBody>
															<ModalFooter>
																<Button color="secondary" onClick={() => this.toggleDecommissionModal()} >
																	<FormattedMessage id="BookingChatWindow.Cancel" />
																</Button>
																<Button color="primary" onClick={() => { this.send("Decommission", "ChannelConf.DecommissionSuccess", true); }} >
																	<FormattedMessage id="AddRule.Confirm" />
																</Button>
															</ModalFooter>
														</Modal>
													</>
												: ''
											:''}
											{channelConf && channelConf.Actions.indexOf("ChangePassword") >= 0 ?
												<DropdownItem disabled={!selectedChannel.hasPassword} onClick={() => this.toggleModal('passwordModal')}>
													<i className="fas fa-key mr-2" />
													<FormattedMessage id="ChannelConfig.ChangePassword" />
												</DropdownItem>
											:""}
										</BlockUi>
									</DropdownMenu>
								</UncontrolledButtonDropdown>
							</span>
						)}
						no={() => ""}
					/>
				</Nav>
				<BlockUi tag="div" blocking={block || blockChannelData}>
					<ErrorAlert error={error} />
					<TabContent activeTab={activeTab}>
						<TabPane tabId="1" className={selectedChannel?.code === 'MAILRES' ? 'px-0 pb-0' : ''} style={{ border: selectedChannel?.code === 'MAILRES' ? 'unset' : '' }}>
							<ChannelInstanceDetail
								selectedChannel={selectedChannel}
								toggleModalAdd={toggleModalAdd}
								user={user}
								updateChannelInstance={updateChannelInstance}
								supportedData={supported}
								hideUserAndPassword={hideUserAndPasswordChannels.some(channel => channel === selectedChannel.code)}
								mandatoryFields={mandatoryFields}
								configApiKey={configApiKey}
								manageCurrencyConfig={global.modules && global.modules.some(m => m === 'CurrencyConvertion') && global.operationMode !== getOperationModes()[0].value || global.operationMode === getOperationModes()[0].value && global.sendRoomRate}
							/>
						</TabPane>
						<TabPane tabId="2" className={selectedChannel?.code === 'MAILRES' ? 'px-0 pb-0' : ''} style={{ border: selectedChannel?.code === 'MAILRES' ? 'unset' : '' }}>
							{activeTab === "2" && rateList ?
								<ChannelMapping
									selectedChannel={selectedChannel}
									ratecodechannel={ratecodechannel}
									rateList={rateList}
									channelsWithSingleRoomRestriction={channelsWithSingleRoomRestriction}
									Map={Map}
									channelDataMap={channelDataMap}
									commonData={CommonData}
									refreshChannelData={this.refreshChannelData}
									user={user}
									priceModes={priceModes}
									isIcal={isIcal}
									hotelId={hotelId}
									showChannelInfo={showChannelInfo}
									isNotSapo={isNotSapo}
									isHostelWorld={isHostelWorld}
									hasAvailabilityGroupMapping={hasAvailabilityGroupMapping}
									mapIncommingOffice={isJumboTours || isJuniper}
									hasChannelData={hasChannelData}
									isHrs={isHrs}
									getRateMappingFromBEConfig={getRateMappingFromBEConfig}
									reloadChannelData={this.reloadChannelData}
									getRates={this.getRates}
									bookingEngines={bookingEngines}
									channelsReadOnly={channelsReadOnly}
									isChannelReadOnly={isChannelReadOnly}
									isJuniper={isJuniper}
									showInterfaceMode4={showInterfaceMode4}
									hasRoomLevelRestrictions={hasRoomLevelRestrictions}
									interfaceModes={interfaceModes}
									isHeybe={isHeybe}
									channelInstanceId={selectedChannel?.id}
								/>
							:''}
						</TabPane>
						<TabPane tabId="3">
							{activeTab === "3" && channelDataMap && channelDataMap.length > 0 && totalMapWithError !== null && !blockChannelData ?
								<ChannelRoomRate
									selectedChannel={selectedChannel}
									ratecodechannel={ratecodechannel}
									rateList={rateList}
									channelDataMap={channelDataMap}
									channelDataMapAirbnb={channelDataMapAirbnb}
									getChannelData={this.getChannelData.bind(this)}
									block={blockChannelData || blockReloadChannelData}
									hasAvailabilityGroupMapping={hasAvailabilityGroupMapping}
									hasHotelBedsSpecific={hasHotelBedsSpecific}
									hasDates={hasDates}
									updateChannelMap={this.updateChannelMap}
									mapIncommingOffice={isJumboTours || isJuniper}
								/>
							: ''}
						</TabPane>
						{activeTab === "4" && channelConf && channelConf.HasCommonData ?
							<TabPane tabId="4">
								<ChannelCommonData
									ratecodechannel={ratecodechannel}
									commonData={CommonData}
									selectedChannel={selectedChannel}
								/>
							</TabPane>
						:""}


						{activeTab === "5" ?
							<Authorization
								perform="channelConf:adminConfig"
								yes={() => overrideUrls ?
									<TabPane tabId="5">
										<ChannelInstanceAdminConfig
											selectedChannel={selectedChannel}
										/>
									</TabPane>
								:''}
								no={() => ""}
							/>
						:''}
					</TabContent>
				</BlockUi>
			</div>
		);
	}
}

export default injectIntl(ChannelConf)