import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Card, CardBody, CardHeader, UncontrolledCollapse, Label, Row, Col, Button, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { CustomTable } from '../Base/Common/CustomTable';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import EditChannelMapping from './EditChannelMapping';
import Authorization from '../Base/Authorization';
import BulkOperation from './BulkOperation';
import { deleteAPI, getAPI, postAPI } from "../Base/API";
import classnames from 'classnames';
import RateCodeChannelHistory from './RateCodeChannelHistory';
import { handleNotification } from '../Base/Notification';
import HrsExtraMappingModal from './HrsExtraMappingModal';
import Mappings from './Mappings';
import { getInterfaceMode, getMarkupType } from '../Base/Common/ReferenceDataFunctions';
import NotMapped from './NotMapped';
import CustomToolTip from '../../Utils/CustomToolTip';
import moment from 'moment';
import BulkOperationUnmapped from './BulkOperationUnmapped';

class ChannelMapping extends Component {
    _isMounted = false;    

    constructor(props) {
        super(props);
        this.checkChannelManagerMapping = this.checkChannelManagerMapping.bind(this);
        this.checkChannelManagerCommonDataMapping = this.checkChannelManagerCommonDataMapping.bind(this);
        this.getunmappedChannelData = this.getunmappedChannelData.bind(this);
        this.openMapping= this.openMapping.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getiCalSyncUrls = this.getiCalSyncUrls.bind(this);
        this.state = {
            error: [],
            block: false,
            modal: false,
            modalRemove: false,
            bulkOperationModal: false,
            pricemode: null,
            pricePosition: null,
            active: null,
            isActivated: null,
            channelid: null,
            rateid: null,
            rateList: [],
            deactivatedBy: null,
            deactivatedOn: null,
            iCalUrl: null,
            isIcal: null,
            iCalSyncUrls: [],
            activeTab: '1',
            associatedRateCodeChannels: [],
            rateCodeChannelHistoryModal: false,
            extraMappingModal: false,
            mappingId: null,
            mapHrsAllFields: null,
            isHotDeal: null,
            //Is an HRS mapping and rate is "comfort", "business", "economy", "extraBed"
            isHrsSpecialRate: false,
            isHrsEconomyRate: false,
            mapStatusFilter: null,
            availableMarkupOptions: getMarkupType(),
            interfaceModes: getInterfaceMode(),
            occupationsToSend: []
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.renderComboOptions(this.props.rateList);
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.rateList) !== JSON.stringify(nextProps.rateList)) {
            this.renderComboOptions(nextProps.rateList);
        }
    }

    renderComboOptions = (rateList) => {
        let rateCodeChannelList = [];
        let roomOptions = [];
        let rateOptions = [];
        let rateLabelOptions = [];

        rateList && rateList.forEach(el => {
            let newRateOptions = [];
            el.rateCodeChannelList = [];
            el.hasRoomLevelRestrictions = this.props.hasRoomLevelRestrictions && el.rateList.flatMap(rate => rate.mappingList.filter(ml => ml.active)).filter(rcc => rcc.interfaceMode === getInterfaceMode()[2].value || rcc.interfaceMode === getInterfaceMode()[3].value).length > 1;

            el.rateList && el.rateList.forEach(rate => {
                if (rate.mappingList.length > 0 && rate.mappingList.find(map => map.roomCode)) {
                    el.rateCodeChannelList.push(rate)

                    //rates
                    newRateOptions.push({ value: rate.id, label: rate.description });
                }
            })

            if (el.rateCodeChannelList.length > 0) {
                //rooms
                roomOptions.push({ value: el.id, label: el.description });

                //rates
                rateOptions = rateOptions.concat(newRateOptions);

                const rate = this.props.ratecodechannel && this.props.ratecodechannel.find(rcc => rcc.localRoomCode === el.code);
                const rateLabel = rate?.rateLabel;
                const rateLabelId = rate?.rateLabelId;
                
                el.rateLabel = rateLabel;
                el.rateLabelId = rateLabelId;

                if (rateLabelId && rateLabel && !rateLabelOptions.some(rl => rl.value === rateLabelId)) {
                    rateLabelOptions.push({ value: rateLabelId, label: rateLabel });
                }

                rateCodeChannelList.push(el);
            }
        })

        this.setState({ rateList, roomOptions, rateOptions, rateLabelOptions });
    }

    getiCalSyncUrls(id) {
        if (!this.state.iCalSyncUrls.find(url => url.id === id)) {
            this.setState({ block: true });
            getAPI(result => {
                const { error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                const { iCalSyncUrls } = this.state;
                const idx = iCalSyncUrls.indexOf(iCalSyncUrls.find(url => url.id === id));
                if (idx === -1) {
                    const link = `${process.env.REACT_APP_RATE_APIGETWAY_URL}/api/Calendar/ICal/v1/ICal/${this.props.hotelId}/${id}`;
                    iCalSyncUrls.push({
                        id: id,
                        url: link
                    });
                }
                this.setState({ iCalSyncUrls, block: false });
            }, `/api/Rate/ICal/v1/iCal/Validate/${this.props.hotelId}/${id}`);
        }
    }

    checkChannelManagerMapping(cell, row, onlyBadge = false) {
        const { isNotSapo, ratecodechannel, channelDataMap, selectedChannel, hasChannelData, isHrs } = this.props;
        if (hasChannelData) {
            return <span><FormattedMessage id="ChannelMapping.Room" />: {row.channelRoomCode}, <FormattedMessage id="ChannelMapping.Rate" />: {row.channelRateCode}</span>
        }

        if (ratecodechannel && channelDataMap && selectedChannel) {
            for (var z = 0; z < channelDataMap.length; z++) {
                if (this.props.hasAvailabilityGroupMapping) {
                    if (row.channelRoomCode && row.channelRateCode && channelDataMap[z].roomCode && channelDataMap[z].rateCode) {
                        if (row.channelRoomCode === channelDataMap[z].roomCode && row.channelRateCode === channelDataMap[z].rateCode && row.availabilityGroupMapping === channelDataMap[z].extraMapping) {
                            return (<span>
                                <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`.replaceAll('|', '')} color="success"><i className="fas fa-check"></i></Badge>
                                <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`.replaceAll('|', '')}>
                                    <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                                </CustomToolTip>
                            </span>);                            
                        }
                    }
                }
                else if (selectedChannel.channelName.toUpperCase() === 'HOTEL BEDS') {
                    if (row.channelRoomCode && row.channelRateCode && row.availabilityGroupMapping && channelDataMap[z].roomCode && channelDataMap[z].rateCode && channelDataMap[z].hotelBedsSpecific) {
                        if (row.channelRoomCode === channelDataMap[z].roomCode && row.channelRateCode === channelDataMap[z].rateCode && row.availabilityGroupMapping === `${channelDataMap[z].hotelBedsSpecific.contractName}/${channelDataMap[z].hotelBedsSpecific.incommingOffice}/${channelDataMap[z].hotelBedsSpecific.sequence}`) {
                            return (<span>
                                <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`} color="success"><i className="fas fa-check"></i></Badge>
                                <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`}>
                                    <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                                </CustomToolTip>
                            </span>);
                        }
                    }
                }
                else if (isHrs && this.isHrsSpecialRate(row.channelRateCode)) {
                    if (row.channelRoomCode && row.channelRateCode && channelDataMap[z].roomCode && channelDataMap[z].rateCode) {
                        if (row.channelRoomCode === channelDataMap[z].roomCode && row.channelRateCode === channelDataMap[z].rateCode) {
                            if (row.priceMarkupValue > 0) {
                                return (<span>
                                    <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`} color="success"><i className="fas fa-check"></i></Badge>
                                    <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`}>
                                        <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                                    </CustomToolTip>
                                </span>);                                
                            }
                            else {
                                return (
                                    <span>
                                        <Badge id={`priceMarkupValueIncorrect${row.channelRoomCode}${row.channelRateCode}`} color="warning" className="text-white">
                                            <i className="fas fa-exclamation-triangle" />
                                        </Badge>
                                        <CustomToolTip placement="bottom" target={`priceMarkupValueIncorrect${row.channelRoomCode}${row.channelRateCode}`}>
                                            <FormattedMessage id="ChannelMapping.PriceMarkupValueIncorrect" />
                                        </CustomToolTip>
                                    </span>
                                );
                            }
                        }
                    }
                }
                else if (this.props.mapIncommingOffice) {
                    if (row.channelRoomCode && row.channelRateCode && row.availabilityGroupMapping && channelDataMap[z].roomCode && channelDataMap[z].rateCode && channelDataMap[z].hotelBedsSpecific) {
                        if (row.channelRoomCode === channelDataMap[z].roomCode && row.channelRateCode === channelDataMap[z].rateCode && row.availabilityGroupMapping === `${channelDataMap[z].hotelBedsSpecific.incommingOffice}`) {
                            return (<span>
                                <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`} color="success"><i className="fas fa-check"></i></Badge>
                                <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}${row.availabilityGroupMapping}`}>
                                    <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                                </CustomToolTip>
                            </span>);
                        }
                    }
                }
                else {
                    if (row.channelRoomCode && row.channelRateCode && channelDataMap[z].roomCode && channelDataMap[z].rateCode) {
                        if (row.channelRoomCode === channelDataMap[z].roomCode && row.channelRateCode === channelDataMap[z].rateCode) {
                            return (<span>
                                <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`} color="success"><i className="fas fa-check"></i></Badge>
                                <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`}>
                                    <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                                </CustomToolTip>
                            </span>);
                        }
                    }
                }
            }
        }
        return isNotSapo ?
            <span>
                <Badge id={`mappingNotMatchWithChannel${row.channelRoomCode}${row.channelRateCode}`} color="danger" className="mr-1">
                    <i className="fas fa-exclamation-triangle" />
                </Badge>
                <CustomToolTip placement="bottom" target={`mappingNotMatchWithChannel${row.channelRoomCode}${row.channelRateCode}`}>
                    <FormattedMessage id="ChannelMapping.MappingNotMatchWithChannel" />
                </CustomToolTip>
                {!onlyBadge &&
                    <>
                        <FormattedMessage id="ChannelMapping.Room" />: {row.channelRoomCode} <FormattedMessage id="ChannelMapping.Rate" />: {row.channelRateCode}
                    </>
                }
            </span>
            :
            <span>
                <Badge id={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`} color="success">
                    <i className="fas fa-check" />
                </Badge>
                <CustomToolTip placement="bottom" target={`mappingWithSuccess${row.channelRoomCode}${row.channelRateCode}`}>
                    <FormattedMessage id="ChannelMapping.MappingWithSuccess" />
                </CustomToolTip>
            </span>
            ;
    }

    isHrsSpecialRate(rateCode) {
        const hrsSpecialRates = ['economy', 'business', 'comfort', 'extraBed', 'oceanView'];
        return hrsSpecialRates.some(rt => rateCode.includes(rt));
    }

    getChannelName = (row, type) => {
        if (this.props.ratecodechannel && this.props.channelDataMap && this.props.selectedChannel) {
            for (var z = 0; z < this.props.channelDataMap.length; z++) {
                if (this.props.selectedChannel.channelName.toUpperCase() === 'AIRBNB') {
                    if (row.channelRoomCode && this.props.channelDataMap[z].roomCode) {
                        if (row.channelRoomCode === this.props.channelDataMap[z].roomCode) {
                            return <Label >{type === 'rate' ? this.props.channelDataMap[z].rateDescription : this.props.channelDataMap[z].roomDescription}</Label>

                        }
                    }
                }
                else {
                    if (row.channelRoomCode && row.channelRateCode && this.props.channelDataMap[z].roomCode && this.props.channelDataMap[z].rateCode) {
                        const hasMapping = row.availabilityGroupMapping ? this.props.channelDataMap[z].hotelBedsSpecific ? this.props.mapIncommingOffice ? row.channelRoomCode === this.props.channelDataMap[z].roomCode && row.channelRateCode === this.props.channelDataMap[z].rateCode && row.availabilityGroupMapping === `${this.props.channelDataMap[z].hotelBedsSpecific.incommingOffice}` : row.channelRoomCode === this.props.channelDataMap[z].roomCode && row.channelRateCode === this.props.channelDataMap[z].rateCode && row.availabilityGroupMapping === `${this.props.channelDataMap[z].hotelBedsSpecific.contractName}/${this.props.channelDataMap[z].hotelBedsSpecific.incommingOffice}/${this.props.channelDataMap[z].hotelBedsSpecific.sequence}` : row.channelRoomCode === this.props.channelDataMap[z].roomCode && row.channelRateCode === this.props.channelDataMap[z].rateCode && row.availabilityGroupMapping === this.props.channelDataMap[z].extraMapping : row.channelRoomCode === this.props.channelDataMap[z].roomCode && row.channelRateCode === this.props.channelDataMap[z].rateCode;
                        if (hasMapping) {
                            return <span>
                                <Label id={`descriptionUncontrolledTolltip${z}${row.channelId}`}>{type === 'rate' ? this.props.channelDataMap[z].rateDescription : this.props.channelDataMap[z].roomDescription}</ Label>
                                <CustomToolTip placement="left" target={`descriptionUncontrolledTolltip${z}${row.channelId}`}>
                                    <Row className="pb-2">
                                        <Col>
                                            <FormattedMessage id="channelList.channeldatamap.roomCode"/>
                                        </Col>
                                        <Col>
                                            {this.props.channelDataMap[z].roomCode}
                                        </Col>
                                    </Row>
                                    <Row className="pb-2">
                                        <Col>
                                            <FormattedMessage id="channelList.channeldatamap.rateCode" />
                                        </Col>
                                        <Col>
                                            {this.props.channelDataMap[z].rateCode}
                                        </Col>
                                    </Row>
                                    {
                                        this.props.channelDataMap[z].extraMapping ?
                                            <Row className="pb-2">
                                                <Col>
                                                    <FormattedMessage id="channelList.channeldatamap.mealPlan" />
                                                </Col>
                                                <Col>
                                                    {this.props.channelDataMap[z].extraMapping}
                                                </Col>
                                            </Row>
                                            :
                                            <div />
                                    }
                                    {
                                        this.props.channelDataMap[z].hotelBedsSpecific ?
                                            <div>
                                                <Row className="pb-2">
                                                    <Col>
                                                        <FormattedMessage id="ChannelList.ContractName" />
                                                    </Col>
                                                    <Col>
                                                        {`${this.props.channelDataMap[z].hotelBedsSpecific.contractName}${this.props.mapIncommingOffice ? `/${this.props.channelDataMap[z].hotelBedsSpecific.incommingOffice}` : ``}`}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormattedMessage id="ChannelList.DateFrom" />
                                                    </Col>
                                                    <Col>
                                                        {this.props.channelDataMap[z].hotelBedsSpecific.dateFrom}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormattedMessage id="ChannelList.DateTo" />
                                                    </Col>
                                                    <Col>
                                                        {this.props.channelDataMap[z].hotelBedsSpecific.dateTo}
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <div />
                                    }
                                </CustomToolTip>
                            </span>
                        }
                    }
                }
            }
        }
        return '';
    }

    getunmappedChannelData() {
        const { mapIncommingOffice } = this.props;
        let res = [
            { label: this.props.intl.formatMessage({ id: "generic.active" }), options: [] },
            { label: this.props.intl.formatMessage({ id: "generic.inactive" }), options: [] }
        ];
        let compareFunc = (el, cd) => { return el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode; };
        let labelFunc = own => this.getChannelDataLabel(own);

        if (this.props.ratecodechannel !== undefined && this.props.channelDataMap && this.props.selectedChannel) {
            if (this.props.channelDataMap.find(cd => cd.hotelBedsSpecific !== undefined)) {
                const func = (el, cd) => { if (cd.hotelBedsSpecific) return el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === `${cd.hotelBedsSpecific.contractName}/${cd.hotelBedsSpecific.incommingOffice}/${cd.hotelBedsSpecific.sequence}`; };
                const funcJumboTours = (el, cd) => { if (cd.hotelBedsSpecific) return el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === `${cd.hotelBedsSpecific.incommingOffice}`; };
                labelFunc = own => `${this.getChannelDataLabel(own)} ${this.getContractLabel(own.hotelBedsSpecific)}`;
                compareFunc = mapIncommingOffice ? funcJumboTours : func;
            }
            else if (this.props.channelDataMap.find(cd => cd.extraMapping)) {
                compareFunc = (el, cd) => { return el.roomTypeMapping === cd.roomCode && el.rateCodeMapping === cd.rateCode && el.availabilityGroupMapping === cd.extraMapping; };
                labelFunc = own => `${this.getChannelDataLabel(own)} ${own.extraMapping ? `${this.props.intl.formatMessage({ id: "ChannelMapping.MealPlan" })} ${own.extraMapping}` : ``}`;
            }

            this.props.channelDataMap.forEach(cd => {
                const contractDateTo = cd.hotelBedsSpecific?.dateTo ? moment(cd.hotelBedsSpecific.dateTo).format("YYYY-MM-DD") : null;

                if ((contractDateTo ? moment().diff(contractDateTo, 'days') < 1 : true)) { //não mostrar opções com contrato expirado
                    let r = this.props.ratecodechannel.find(el => compareFunc(el, cd));

                    if (r === undefined) {
                        const label = `${this.getChannelDataLabel(cd)} ${cd.hotelBedsSpecific ? `${this.getContractLabel(cd.hotelBedsSpecific) }` : `${cd.extraMapping ? `${this.props.intl.formatMessage({ id: "ChannelMapping.MealPlan" })} ${cd.extraMapping}` : ``}`}`;
                        const index = cd.active ? 0 : 1;

                        res[index].options.push({ value: cd.id, label: label });
                    }
                }
            });
            if (this.state.selectedchannelid) {
                let own = this.props.channelDataMap.find(el => el.id === this.state.selectedchannelid);

                if (!res.find(r => r.value === own.id)) {
                    const index = own.active ? 0 : 1;

                    own && res[index].options.push({ value: own.id, label: labelFunc(own) });
                }
            }
        }
        return res;
    }

    getContractLabel = (hotelBedsSpecific) => {
        if (this.props.selectedChannel.channelName.toUpperCase() === 'HOTEL BEDS') {
            return `${this.props.intl.formatMessage({ id: "ChannelMapping.Contract" })} ${hotelBedsSpecific.contractName}/${hotelBedsSpecific.incommingOffice}/${hotelBedsSpecific.sequence}`;
        }

        return `${this.props.intl.formatMessage({ id: "ChannelMapping.Contract" })} ${hotelBedsSpecific.contractName === `` ? hotelBedsSpecific.incommingOffice : hotelBedsSpecific.contractName}`;
    }

    getChannelDataLabel = (info) => {
        return `(${info.roomCode}) ${info.roomDescription}-(${info.rateCode}${info.rateType ? `: ${info.rateType}` : ''}) ${info.rateDescription ? info.rateDescription : ''}`;
    }

    checkChannelManagerCommonDataMapping(cell, row) {
       
        if (this.props.commonData) {
            for (var z = 0; z < this.props.commonData.length; z++) {
                if (row.channelCode && this.props.commonData[z].otaCode) {
                    if (row.channelCode === this.props.commonData[z].otaCode) {
                        return <Badge color="success"><i className="fas fa-check"></i></Badge>;
                    }
                }
            }
            return <Badge color="danger"><i className="fas fa-exclamation-triangle"></i></Badge>;
        }
        return <div></div>;
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    openMapping(id, cid, evt) {
        const { channelDataMap, hasRoomLevelRestrictions, isHrs, rateList, showInterfaceMode4 } = this.props;

        let pm = null;
        let a = null;
        let interfaceMode = null;
        let priceMarkupType, priceMarkupValue = null, priceMarkupIncludePackageComponents = null;
        let selectedchannelid = null;
        let applyMarkup = null;
        let deactivatedBy = null;
        let deactivatedOn = null;
        let iCalUrl = null;
        let isIcal = this.props.isIcal;
        let pricePosition = 0;
        let associatedRateCodeChannels = [];
        let isHrsSpecialRate = false;
        let isHrsEconomyRate = false;
        let availableMarkupOptions = getMarkupType();
        let excludePriceComponent = false;
        let occupationsToSend = [];

        if (cid !== null) {
            if (rateList) {
                rateList.forEach(element =>
                    element.rateList.forEach(ritem =>
                        ritem.mappingList.forEach(mitem => {
                            if (mitem.id === cid) {
                                pm = mitem.priceMode;
                                interfaceMode = mitem.interfaceMode;
                                priceMarkupType = mitem.priceMarkupType;
                                priceMarkupValue = mitem.priceMarkupValue;
                                applyMarkup = priceMarkupType ? true : false;
                                priceMarkupIncludePackageComponents = mitem.priceMarkupIncludePackageComponents;
                                a = mitem.active;
                                deactivatedBy = mitem.deactivatedBy;
                                deactivatedOn = mitem.deactivatedOn;
                                iCalUrl = mitem.iCalUrl;
                                isIcal = mitem.isIcal;
                                pricePosition = mitem.pricePosition;
                                associatedRateCodeChannels = mitem.associatedRateCodeChannels;
                                excludePriceComponent = mitem.excludePriceComponent;
                                occupationsToSend = mitem.occupationsToSend;
                                if (channelDataMap) {
                                    let compareFunc = (el) => { return el.roomCode === mitem.roomCode && el.rateCode === mitem.rateCode; };
                                    if (mitem.availabilityGroupMapping) {
                                        compareFunc = (el) => el.hotelBedsSpecific ? this.props.mapIncommingOffice ? el.roomCode === mitem.roomCode && el.rateCode === mitem.rateCode && `${el.hotelBedsSpecific.incommingOffice}` === mitem.availabilityGroupMapping : el.roomCode === mitem.roomCode && el.rateCode === mitem.rateCode && `${el.hotelBedsSpecific.contractName}/${el.hotelBedsSpecific.incommingOffice}/${el.hotelBedsSpecific.sequence}` === mitem.availabilityGroupMapping : el.roomCode === mitem.roomCode && el.rateCode === mitem.rateCode && el.extraMapping === mitem.availabilityGroupMapping;
                                    }
                                    let r = channelDataMap.find(compareFunc);
                                    if (r !== undefined) {
                                        selectedchannelid = r.id;
                                    }
                                    if (isHrs) {
                                        const hrsSpecialRates = ['business', 'comfort', 'extraBed', 'oceanView'];
                                        const hrsEconomyRate = 'economy';

                                        isHrsEconomyRate = hrsEconomyRate.includes(mitem.rateCode);
                                        isHrsSpecialRate = hrsSpecialRates.some(rt => mitem.rateCode.includes(rt));

                                        if (this.isHrsSpecialRate(mitem.rateCode)) {
                                            availableMarkupOptions = [getMarkupType()[1]]
                                        }
                                    }
                                }
                            }
                        })
                    )
                )
            }
        }

        this.setState({ rateid: id, channelid: cid, selectedchannelid: selectedchannelid, pricemode: pm, active: a, isActivated: a, interfaceMode: interfaceMode, priceMarkupIncludePackageComponents, priceMarkupValue: priceMarkupValue, priceMarkupType: priceMarkupType, applyMarkup: applyMarkup, deactivatedBy: deactivatedBy, deactivatedOn: deactivatedOn, iCalUrl: iCalUrl, isIcal, pricePosition, associatedRateCodeChannels: associatedRateCodeChannels, isHrsSpecialRate, isHrsEconomyRate, availableMarkupOptions, excludePriceComponent, occupationsToSend });
        this.toggleModal();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    toggleRateCodeChannelHistoryModal = () => {
        this.setState(prevState => ({ rateCodeChannelHistoryModal: !prevState.rateCodeChannelHistoryModal, channelid: null }));
    }

    handleSelectAssociatedMappings = (evt, mapId, channelCode, associatedRateCodeChannels) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            if (checked) {
                this.createAssociatedMapping(mapId, channelCode);
            }
            else {
                const associatedRateCodeChannel = associatedRateCodeChannels.find(associatedMapping => channelCode === associatedMapping.channelCode);
                if (associatedRateCodeChannel) {
                    this.removeMapping(associatedRateCodeChannel.id)
                }
            }
        }
    }

    createAssociatedMapping = (mapId, channelCode) => {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="EditChannelMapping.MappingSaved" />, <FormattedMessage id="EditChannelMapping.Success" />);
                    this.props.getRates()
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Rate/RateCodeChannel/v1/ratecodechannel/${mapId}?channelCode=${channelCode}`);
    }

    removeMapping = (id) => {
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorMessage: errorMessage, block: false });
                return;
            }
            handleNotification(data, <FormattedMessage id="ChannelMapping.RemoveMappingSuccess" />, <FormattedMessage id="EditChannelMapping.Success" />);
            this.setState({ errorMessage: errorMessage, block: false });
            this.props.getRates();
        }, `/api/Rate/RateCodeChannel/v1/mapping/${id}`);
    }

    toggleExtraMappingModal = (mappingId, mapHrsAllFields, isHotDeal) => {
        this.setState(prevState => ({ extraMappingModal: !prevState.extraMappingModal, mappingId, mapHrsAllFields, isHotDeal }));
    }

    handleCombo = (name, combo) => {
        this.setState({ [name]: combo ? combo.value : null })
    }

    rateCodeChannelHistory = (id) => {
        this.setState({
            rateCodeChannelHistoryModal: true,
            channelid: id
        })
    }

    saveUnmappedData = (roomsWithUnmappedData) => {
        this.setState({ roomsWithUnmappedData })
    }

    render() {
        const { modal, rateid, channelid, rateList, roomOptions, rateOptions, rateLabelOptions } = this.state;
        const { classes, channelDataMap, priceModes, reloadChannelData, getRates } = this.props;
        const columnsMap = [
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "ChannelList.CodeType" }),
                sort: true,
                formatter: (cell, row) => <FormattedMessage id={row.type ? 'ChanneMappingTypeCode_' + row.type : ''} />
            },
            {
                dataField: 'localCode',
                text: this.props.intl.formatMessage({ id: "ChannelList.localCode" }),
                sort: true
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ChannelList.description" }),
                sort: true
            },
            {
                dataField: 'channelCode',
                text: this.props.intl.formatMessage({ id: "ChannelList.channelCode" }),
                sort: true
            },
            {
                dataField: 'otacode',
                text: this.props.intl.formatMessage({ id: "ChannelList.otacode" }),
                sort: true
            },
            {
                dataField: 'statusMap',
                text: this.props.intl.formatMessage({ id: "ChannelList.mapStatus" }),
                sort: true,
                formatter: (cell, row) => this.checkChannelManagerCommonDataMapping(cell, row)
            }
        ];

        return (
            <div>
                {modal ?
                    <EditChannelMapping
                        classes={classes}
                        modal={modal}
                        toggle={this.toggleModal}
                        channelDataMap={channelDataMap}
                        priceModes={priceModes}
                        reloadChannelData={reloadChannelData}
                        getRates={getRates}
                        rateList={rateList}
                        rateid={rateid}
                        channelid={channelid}
                        selectedchannelid={this.state.selectedchannelid}
                        pricemode={this.state.pricemode}
                        pricePosition={this.state.pricePosition}
                        active={this.state.active}
                        isActivated={this.state.isActivated}
                        interfaceMode={this.state.interfaceMode}
                        priceMarkupValue={this.state.priceMarkupValue}
                        priceMarkupType={this.state.priceMarkupType}
                        priceMarkupIncludePackageComponents={this.state.priceMarkupIncludePackageComponents}
                        applyMarkup={this.state.applyMarkup}
                        deactivatedBy={this.state.deactivatedBy}
                        deactivatedOn={this.state.deactivatedOn}
                        iCalUrl={this.state.iCalUrl}
                        isIcal={this.state.isIcal}
                        getunmappedChannelData={this.getunmappedChannelData}
                        selectedChannel={this.props.selectedChannel}
                        ratecodechannel={this.props.ratecodechannel}
                        isNotSapo={this.props.isNotSapo}
                        isHostelWorld={this.props.isHostelWorld}
                        hasAvailabilityGroupMapping={this.props.hasAvailabilityGroupMapping}
                        mapIncommingOffice={this.props.mapIncommingOffice}
                        hasChannelData={this.props.hasChannelData}
                        getRateMappingFromBEConfig={this.props.getRateMappingFromBEConfig}
                        bookingEngines={this.props.bookingEngines}
                        associatedRateCodeChannels={this.state.associatedRateCodeChannels}
                        isJuniper={this.props.isJuniper}
                        showInterfaceMode4={this.props.showInterfaceMode4}
                        isHrs={this.props.isHrs}
                        isHrsSpecialRate={this.state.isHrsSpecialRate}
                        isHrsEconomyRate={this.state.isHrsEconomyRate}
                        availableMarkupOptions={this.state.availableMarkupOptions}
                        interfaceModes={this.props.interfaceModes}
                        excludePriceComponent={this.state.excludePriceComponent}
                        occupationsToSend={this.state.occupationsToSend}
                    />
                :''}

                {this.state.bulkOperationModal && this.props.ratecodechannel ?
                    <BulkOperation
                        rateCodeChannel={this.props.ratecodechannel}
                        toggle={() => this.setState({ bulkOperationModal: false })}
                        modal={this.state.bulkOperationModal}
                        reloadChannelData={this.props.reloadChannelData}
                        getRates={this.props.getRates}
                        interfaceModes={this.props.interfaceModes}
                        //Only price per occupation is valid to update on bulk operation
                        //priceModes={this.props.priceModes.filter(pmd => pmd.value === getPriceMode()[0].value)}
                        priceModes={this.props.priceModes}
                        isHeybe={this.props.isHeybe}
                        checkChannelManagerMapping={this.checkChannelManagerMapping}
                        roomOptions={roomOptions}
                        rateLabelOptions={rateLabelOptions}
                    />
                :''}
                
                {this.state.bulkOperationUnmappedModal ?
                    //For HeyBe
                    <BulkOperationUnmapped
                        rateCodeChannel={this.props.ratecodechannel}
                        toggle={() => this.setState({ bulkOperationUnmappedModal: false })}
                        modal={this.state.bulkOperationUnmappedModal}
                        reloadChannelData={this.props.reloadChannelData}
                        getRates={this.props.getRates}
                        interfaceModes={this.props.interfaceModes}
                        priceModes={this.props.priceModes}
                        roomsWithUnmappedData={this.state.roomsWithUnmappedData}
                        channelInstanceId={this.props.channelInstanceId}
                    />
                : ''}

                {this.state.rateCodeChannelHistoryModal ?
                    <Modal isOpen={this.state.rateCodeChannelHistoryModal} toggle={this.toggleRateCodeChannelHistoryModal}>
                        <ModalHeader toggle={this.toggleRateCodeChannelHistoryModal}>
                            <FormattedMessage id="RateHistory.RateCodeChannelHistory" />
                        </ModalHeader>
                        <ModalBody>
                            <RateCodeChannelHistory
                                rateCodeChannelId={this.state.channelid}
                                priceModes={this.props.priceModes}
                            />
                        </ModalBody>
                    </Modal>
                :''}
                {this.state.extraMappingModal ?
                    <HrsExtraMappingModal
                        isOpen={this.state.extraMappingModal}
                        toggle={this.toggleExtraMappingModal}
                        mappingId={this.state.mappingId}
                        mapHrsAllFields={this.state.mapHrsAllFields}
                        isHotDeal={this.state.isHotDeal}
                    />
                :''}
                <div className="mb-2">
                    <StyledCard title='ChannelList.Mapping' icon='fas fa-random' block={this.state.block}>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id={'ChannelMapping.Mapped'} />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id={'ChannelMapping.NotMapped'} />
                                </NavLink>
                            </NavItem>


                            <Authorization
                                perform="channel:mapping:bulkOperations"
                                yes={() => (
                                    (!(global.operationMode === 'PmsFull' && !global.sendRoomRate) || global.PMSType === 'BookingSpaceBeds') ?
                                        this.props.isIcal === false && this.state.activeTab === '1' && this.props.ratecodechannel && this.props.ratecodechannel.length > 0 ?
                                            <NavItem className="ml-auto border-bottom mt-2">
                                                <Button className="btn-sm btn-host" onClick={() => this.setState({ bulkOperationModal: true })} disabled={this.props.isChannelReadOnly}>
                                                    <FormattedMessage id="ChannelMapping.BulkOperation" />
                                                </Button>
                                            </NavItem>
                                            : this.state.activeTab === '2' && this.props.isHeybe && this.state.roomsWithUnmappedData?.length > 0 ?
                                                <NavItem className="ml-auto border-bottom mt-2">
                                                    <Button className="btn-sm btn-host" onClick={() => this.setState({ bulkOperationUnmappedModal: true })}>
                                                        <FormattedMessage id="ChannelMapping.BulkOperation" />
                                                    </Button>
                                                </NavItem>
                                            : ''
                                        : ''
                                )}
                                no={() => ""}
                            />
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                {this.state.activeTab === '1' ?
                                    <Mappings
                                        channelDataMap={this.props.channelDataMap}
                                        showChannelInfo={this.props.showChannelInfo}
                                        rateList={rateList}
                                        iCalSyncUrls={this.state.iCalSyncUrls}
                                        openMapping={this.openMapping}
                                        refreshChannelData={this.props.refreshChannelData}
                                        isIcal={this.props.isIcal}
                                        isNotSapo={this.props.isNotSapo}
                                        isJuniper={this.props.isJuniper}
                                        isChannelReadOnly={this.props.isChannelReadOnly}
                                        isHrs={this.props.isHrs}
                                        getiCalSyncUrls={this.getiCalSyncUrls}
                                        rateCodeChannelHistory={this.rateCodeChannelHistory}
                                        toggleExtraMappingModal={this.toggleExtraMappingModal}
                                        ratecodechannel={this.props.ratecodechannel}
                                        selectedChannel={this.props.selectedChannel}
                                        checkChannelManagerMapping={this.checkChannelManagerMapping}
                                        getChannelName={this.getChannelName}
                                        priceModes={this.props.priceModes}
                                        bookingEngines={this.props.bookingEngines}
                                        hasRoomLevelRestrictions={this.props.hasRoomLevelRestrictions}
                                        handleSelectAssociatedMappings={this.handleSelectAssociatedMappings}
                                        roomOptions={roomOptions}
                                        rateOptions={rateOptions}
                                        rateLabelOptions={rateLabelOptions}
                                    />
                                :''}
                            </TabPane>
                            <TabPane tabId="2">
                                {this.state.activeTab === '2' ?
                                    <NotMapped
                                        channelDataMap={this.props.channelDataMap}
                                        refreshChannelData={this.props.refreshChannelData}
                                        rateList={rateList}
                                        openMapping={this.openMapping}
                                        saveUnmappedData={this.saveUnmappedData}
                                    />
                                :''}
                            </TabPane>
                        </TabContent>                        
                    </StyledCard>
                </div>
                {!this.props.hasChannelData ?
                    <Card className="bg-white border-0 shadow mb-2">
                        <CardHeader className="bg-white border-bottom"  >
                            <i className="fas fa-random" /> Reference Data Mapping
                        <div className="float-right">
                                <a id="ReferenceDataMappingtoggler">
                                    <i className="fas fa-chevron-down" />
                                </a>
                            </div>
                        </CardHeader>
                        <UncontrolledCollapse toggler="#ReferenceDataMappingtoggler">
                            <CardBody>
                                <CustomTable
                                    data={this.props.Map ? this.props.Map : []}
                                    columns={columnsMap}
                                    shadow={false}
                                    exportCSV={true}
                                    search={true}
                                />
                            </CardBody>
                        </UncontrolledCollapse>
                    </Card>
                : ''}
            </div>
        );
    }
}

export default injectIntl(ChannelMapping);