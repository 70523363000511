import React, { Component } from 'react';
import { Card, Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI } from "../../Base/API"
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import '../../Base/Common/Timeline.css';
import '../../Base/Common/TimelineElement.css';
import CustomSelect from '../../Base/Common/CustomSelect'
import { CustomTable } from '../../Base/Common/CustomTable'
import { SelectRate } from "../../Base/Common/CommonUIComponents";
import { LogsRequestModal } from '../Logs/LogsRequestModal';

class Mapping extends Component {
        
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            blockRooms: true,
            modal: false,
            // Search Criteria
            startDate: moment().subtract(2, "days"),
            endDate: moment().add(1, "days"),
            selectedErrorType: "",
            selectedServiceType: "",
            // pagination control
            pageSize: 10,
            currentPage: 1,
            totalResults: 0,
            // Combobox information
            updateData: [
                {
                    "value": "CTA",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.CTA" />
                },
                {
                    "value": "CTD",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.CTD" />
                },
                {
                    "value": "minStay",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.MinStay" />
                },
                {
                    "value": "maxStay",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.MaxStay" />
                },
                {
                    "value": "freerooms",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.FreeRooms" />
                },
                {
                    "value": "price",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.Price" />
                },
                {
                    "value": "stopSales",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.StopSales" />
                }
            ],
            events: [],
            roomCategoryList: null,
            selectedRoom: '',
            selectedRate: '',
            selectedData: '',
            detailChannel: null,
            channelinstanceList: [],
            selectedEvent: [],
            ariDate: moment(),
            sendDate: null
        };
    }

    componentDidMount() {
        this.getRoomCategoryList();
        this.searchEvents();
    }

    getRoomCategoryList = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRooms: false });

                return;
            }
            if (data) {

                var arr = [];
                data.data.map((item) => {
                    return arr.push({ 'value': item.id, 'label': '(' + item.code + ')' + item.description });
                })

                this.setState({ roomCategoryList: arr, blockRooms: false });
            }
        }, '/api/hotel/RoomCategory/v1');
    }

    search = (evt) => {
        evt.preventDefault();
        this.setState({ block: true }, () => this.searchEvents());
    }

    searchEvents = () => {
        const { currentPage, pageSize, selectedData, selectedRate, selectedRoom, ariDate, sendDate } = this.state;

        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    totalResults: data.count,
                    block: false,
                    selectedEvent: data.data ? data.data : []
                });
            }
        }, `/api/User/Events/v1/PMSEventDetails?pageIndex=${currentPage}&pageSize=${pageSize}&data=${selectedData}&rate=${selectedRate}&room=${selectedRoom}&ariDate=${moment(ariDate).format('YYYY-MM-DD')}${sendDate ? `&sendDate=${moment(sendDate).format('YYYY-MM-DD')}` : ``}`);
    }

    clearFilter() {
        this.setState({
            startDate: moment().subtract(15, "days"),
            endDate: moment(),
            selectedErrorType: null,
            selectedServiceType: null
        });
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }
        this.setState({ [evt]: targetValue })
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({ currentPage: page, pageSize: sizePerPage }, this.searchEvents);
    }

    toggle = (type, body, docTitle) => {
        var content = '';
        var contentType = '';
        if (type && body !== undefined) {
            contentType = type;
            try {
                content = JSON.stringify(JSON.parse(body), null, 2)
            } catch (e) {
                content = body;
            }
        }

        this.setState(prevState => ({
            requestType: contentType,
            requestContent: content,
            modal: !prevState.modal,
            docTitle
        }));
    }

    render() {
        const { modal, requestType, requestContent, selectedEvent, ariDate, sendDate, pageSize, currentPage } = this.state;

        const columns = [
            {
                dataField: 'pmsRateCode',
                text: this.props.intl.formatMessage({ id: "PMSLogs.Rate" })
            },
            {
                dataField: 'pmsRoomTypeCode',
                text: this.props.intl.formatMessage({ id: "PMSLogs.Room" }),
                sort: true
            },
            {
                dataField: 'from',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.From" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ""
            },
            {
                dataField: 'to',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.To" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ""
            },
            {
                dataField: 'freeRooms',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.FreeRooms" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('freerooms') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('freerooms') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'maxStay',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MaxStay" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('maxStay') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('maxStay') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'minStay',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MinStay" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('minStay') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('minStay') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'cta',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTA" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('CTA') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('CTA') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'ctd',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTD" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('CTD') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('CTD') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'stopSales',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.StopSales" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('stopSales') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('stopSales') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'prices',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Price" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('price') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('price') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.UpdateDate" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ""
            },
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Result" }),
                formatter: cell => <span className={`badge ${cell === 2 ? `badge-danger` : (cell === 3 ? `badge-warning text-white` : `badge-success`)}`} >
                    <i className={`fas ${cell === 1 ? `fa-check` : `fa-exclamation-triangle`}`} />
                </span>
            },
            {
                dataField: 'request',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Request" }),
                formatter: (cell, row) => <Button className="btn btn-host btn-sm" onClick={this.toggle.bind(this, 'request', cell, row.pmsRateCode)}><i className="fas fa-file-import" /></Button>
            },
            {
                dataField: 'response',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Response" }),
                formatter: (cell, row) => <Button className="btn btn-host btn-sm" onClick={this.toggle.bind(this, 'response', cell, row.pmsRateCode)}><i className="fas fa-file-export" /></Button>
            }
        ];

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <LogsRequestModal modal={modal} toggle={this.toggle.bind(this, '')} requestType={requestType} requestContent={requestContent} docTitle={this.state.docTitle} />

                    <ErrorAlert error={this.state.error} />
                    <Card className="border-0">
                        <Row className="font_size_xxs">
                            <Col className="col-7">
                            </Col>
                            <Col className="col-1 pr-0 mr-3">
                                <FormattedMessage id="PMSLogs.AriDate" />
                            </Col>
                            <Col className="pl-3">
                                <FormattedMessage id="PMSLogs.SendDate" />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="col-2 ">
                                <SelectRate
                                    icon={'fa fa-gift fa-fw'} name={'selectedRate'}
                                    onChangeFunc={this.setComboStatus}
                                    placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRate" />}
                                    isMulti={false}
                                    value={this.state.selectedRate}
                                />
                            </Col>
                            <Col className="col-2 px-1">
                                <CustomSelect
                                    icon={'fas fa-bed'} isClearable isSearchable
                                    placeholder={<FormattedMessage id="LogsAvailabilityPrice.ChooseRoom" />}
                                    options={this.state.roomCategoryList}
                                    onChange={this.setComboStatus.bind(this, 'selectedRoom')}
                                />
                            </Col>

                            <Col className="col-3 pr-1">
                                <CustomSelect
                                    icon={'fas fa-table'}
                                    isClearable isMulti isSearchable
                                    placeholder={<FormattedMessage id="LogsAvailabilityPrice.ChooseDataChanged" />}
                                    options={this.state.updateData}
                                    onChange={this.setComboStatus.bind(this, 'selectedData')}
                                />
                            </Col>

                            <Col className="col-1 mr-3">
                                <SingleDatePicker
                                    id="ModificationData"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(2, 'year')}
                                    date={ariDate}
                                    onDateChange={date => this.setState({ ariDate: date })}
                                    focused={this.state.focusedAriDate}
                                    onFocusChange={({ focused }) => this.setState({ focusedAriDate: focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    required={true}
                                    showDefaultInputIcon={false}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </Col>
                            <Col className="col-1">
                                <SingleDatePicker
                                    id="EventsDate"
                                    isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment()}
                                    date={sendDate}
                                    onDateChange={date => this.setState({ sendDate: date })}
                                    focused={this.state.focusedSendDate}
                                    onFocusChange={({ focused }) => this.setState({ focusedSendDate: focused })}
                                    small={true}
                                    numberOfMonths={1}
                                    required={true}
                                    showDefaultInputIcon={false}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </Col>
                            <Col className="text-right">
                                <button className="btn btn-host shadow btn-sm" onClick={this.search} disabled={!ariDate}>
                                    <span className="fas fa-search" />
                                </button>
                            </Col>
                        </Row>
                        <Row className="mb-2 small">
                            
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <CustomTable
                                    data={selectedEvent}
                                    columns={columns}
                                    sizePerPage={this.state.pageSize}
                                    totalSize={this.state.totalResults}
                                    search={true}
                                    exportCSV={true}
                                    hasPagination={false}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-1">
                                <Button className="btn-host shadow btn-sm mr-1" style={{ opacity: '1' }} disabled={!currentPage || currentPage <= 1} onClick={() => this.handleTableChange(null, { page: parseInt(currentPage) - 1, sizePerPage: pageSize })}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col className="text-center" style={{ fontSize: '20px' }}>
                                <FormattedMessage id="LogsAvailabilityPrice.Page" values={{ page: currentPage }} />
                            </Col>
                            <Col className="col-1 text-right">
                                <Button className="btn-host btn-sm shadow" disabled={selectedEvent?.length < pageSize} onClick={() => this.handleTableChange(null, { page: parseInt(currentPage) + 1, sizePerPage: pageSize })}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(Mapping)