import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Progress } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../../Base/API"
import BlockUi from 'react-block-ui';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";

class AirbnbPropScore extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            channelInstanceList: [],
            selectedConnection: '',
            propertyScore: [],
            activeTab: '1',
            globalReviews: null,
            listings: []
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var id = null;
                var list = [];

                data && data.filter(c => c.active).forEach(channel => {
                    list.push({ value: channel.id, label: `${channel.name} (${channel.propertyCode})` });
                })

                if (list.length > 0) id = list[0].value;

                this.setState({ channelInstanceList: list, selectedConnection: id, block: false },
                    () => {
                        if (this.state.channelInstanceList) {
                            this.reloadData();
                        }
                        else {
                            var data = {
                                warnings: [{
                                    message: <FormattedMessage id="PropertyScore.ThereAreNoChannels" />
                                }]
                            }
                            handleNotification(data, null, null);
                        }
                    }
                );
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Airbnb');
    }

    getPropertyScore = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    globalReviews: data.rating_stats ? data.rating_stats : null,
                    block: false
                });
            }
        }, '/api/Rate/Review/v1/airbnb/' + this.state.selectedConnection + '/reviews/globalReview?type=listings');
    }

    getListings = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    listings: data.response && data.response.listings ? data.response.listings : [],
                    block: false
                });
            }
        }, `/api/Rate/Listing/v1/airbnb/${this.state.selectedConnection}/listingAll`);
    }

    reloadData = () => {
        if (this.state.selectedConnection) {
            this.getPropertyScore();
            this.getListings(); 
        }       
    }

    changeChannelConnection = (combo) =>  {
        this.setState({
            selectedConnection: combo ? combo.value : null,
            block: true
        }, () => this.reloadData());
    }


    render() {
        const { globalReviews, selectedConnection, channelInstanceList, listings } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row>
                    <Col className="col-9"/>
                    <Col className="col-3 mt-2">
                        <CustomSelect
                            icon={'fa fa-plug fa-fw'}
                            isSearchable
                            placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                            options={this.state.channelInstanceList}
                            onChange={this.changeChannelConnection.bind(this)}
                            value={selectedConnection ? channelInstanceList.find(el => el.value === selectedConnection) : ''}
                        />
                    </Col>
                </Row>
                <br />
                {globalReviews && globalReviews.map((review, k) => {
                    if (review.count > 0) {
                        const listing = listings && listings.find(l => l.id === review.entity_id);

                        return <Card className="border-left-primary shadow h-100 mb-3" key={k}>
                            <CardBody>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <Row>
                                            <Col>
                                                <p className="text-xs font-weight-bold text-uppercase mb-4">{listing ? listing.name : review.entity_id}</p>
                                            </Col>
                                            <Col className="col-2 text-right">
                                                <FormattedMessage id="PropertyScore.Reviews" />: {review.count}
                                            </Col>
                                        </Row>
                                        

                                        <Row>
                                            {review.category_stats && review.category_stats.length > 0 && review.category_stats.map((cat, key) =>
                                                <Col className="col-6" key={key}>
                                                    <Row>
                                                        <Col className="col-3">
                                                            <h6> <FormattedMessage id={`PropertyScore.${cat.category}`} /> </h6>
                                                        </Col>
                                                        <Col>
                                                            <Progress
                                                                style={{ backgroundColor: '#e9ecef !important' }}
                                                                className="progress-bar-sm progress-bar-animated-alt progress"
                                                                color={cat.rating > 3.5 ? 'success' : cat.rating < 2.3 ? 'danger' : 'warning'}
                                                                value={cat.rating}
                                                                max={5}>
                                                                {cat.rating}
                                                            </Progress>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    }
                })}
            </BlockUi>
        );
    }
}

export default injectIntl(AirbnbPropScore)