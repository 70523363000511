import React, { Component } from 'react';
import { Button, ButtonGroup, Col, CustomInput, Row, Badge, Card, CardBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { putAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { MajorBulkUpdateModalChooseRates } from "./MajorBulkUpdateModalChooseRates";
import { MajorBulkUpdateModalChooseRatesByChannels } from "./MajorBulkUpdateModalChooseRatesByChannels";
import { MajorBulkUpdateModalChooseRestrictions } from "./MajorBulkUpdateModalChooseRestrictions";
import MajorBulkUpdateModalConfirm from "./MajorBulkUpdateModalConfirm";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../Common/ErrorAlert';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';
import { getRmsTypes } from '../Base/Common/ReferenceDataFunctions';
import { ConfirmRestrictionsToChannelsModal } from '../Base/Common/CommonUIComponents';

export class MajorBulkUpdate extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.formResctriction = React.createRef();
        this.onClickWeekDays = this.onClickWeekDays.bind(this);
        this.handleRateChange = this.handleRateChange.bind(this);
        this.handleMenuChoice = this.handleMenuChoice.bind(this);
        this.handleChangeStep = this.handleChangeStep.bind(this);
        this.resetRatesAndChannels = this.resetRatesAndChannels.bind(this);
        this.handleChangeRoomCategory = this.handleChangeRoomCategory.bind(this);
        this.handleChangeChannel = this.handleChangeChannel.bind(this);
        this.state = {
            block: false,
            error: null,
            weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            rateCodeIds: [],
            roomCategoryIds: [],
            steps: [true, false, false, false],
            mode: 1,
            channelInstances: [],
            channels: [],
            applyRestrictionsToChannels: false,
            selectedChannels: [],
            roomCategoriesFiltered: [],
            rates: [],
            ratesFiltered: [],
            areAllRatesSelected: false,
            dates: [],
            allowRestrictionsByChannels: global.RMSType === getRmsTypes()[2].value
        };
    }

    setAction(combo) {
        if (combo) {
            const { value } = combo;
            this.setState({ action: value });
        }
    }

    setComboBox = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    onClickWeekDays(weekDay) {
        const weekdays = [...this.state.weekdays];
        if (weekdays.find(weekday => weekday === weekDay)) {
            const elementsToRemove = 1;
            weekdays.splice(weekdays.indexOf(weekdays.find(item => item === weekDay)), elementsToRemove);
        }
        else {
            weekdays.push(weekDay);
        }
        this.setState(({ weekdays }));
    }

    restrictionValidations = (event) => {
        event.preventDefault();

        if (!this.form.current.reportValidity()) {
            return;
        }

        if (this.state.channels.length === 0 && !this.state.applyRestrictionsToChannels) {
            this.setState({ applyAllChannelsModal: true });
        }
        else {
            this.updateRestrictionsBulk();
        }
    }

    updateRestrictionsBulk = (applyToAllChannels) => {
        const { closedToArrival, closedToDeparture, stopSales, maximumStayLen, minimumStayLen, rateCodeIds, channels, weekdays, minAdvancedBookingOffset, maxAdvancedBookingOffset, applyRestrictionsToChannels } = this.state;

        const req = {
            dates: this.state.dates,
            rateCodeIds: rateCodeIds,
            channelInstanceIds: channels,
            weekdays: weekdays,
            applyRestrictionsToChannels: applyToAllChannels || applyRestrictionsToChannels
        };

        if (closedToArrival != undefined) req.closedToArrival = closedToArrival;
        if (closedToDeparture != undefined) req.closedToDeparture = closedToDeparture;
        if (stopSales != undefined) req.stopSales = stopSales;
        if (maximumStayLen) req.maximumStayLen = maximumStayLen;
        if (minimumStayLen) req.minimumStayLen = minimumStayLen;
        if (minAdvancedBookingOffset) req.minAdvancedBookingOffset = minAdvancedBookingOffset;
        if (maxAdvancedBookingOffset) req.maxAdvancedBookingOffset = maxAdvancedBookingOffset;

        this.setState({ block: true, applyAllChannelsModal: false });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="BulkUpdate.RestrictionsUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);
                                
                this.setState({
                    error: errorMessage, block: false,
                    roomCategoriesFiltered: [],
                    selectedChannels: [],
                    rateCodeIds: [],
                    channels: [],
                    weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                    applyRestrictionsToChannels: false,
                    dates: [],
                    areAllRatesSelected: false
                }, () => this.resetRestrictions(0, 3));
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Price/Availability/v1/RestrictionNDays', req);
    }

    handleRateChange(evt, byChannel) {
        if (evt.target) {
            const { name, checked } = evt.target;
            const { rateCodeIds, rates } = this.state;
            const rateCodeIdParsed = parseInt(name);
            const rateCodeId = rateCodeIds.find(rateCodeId => rateCodeId === rateCodeIdParsed);

            if (rateCodeId && !checked) {
                rateCodeIds.splice(rateCodeIds.indexOf(rateCodeId), 1);
            }
            else if (!rateCodeId && checked) {
                rateCodeIds.push(rateCodeIdParsed);
            }

            this.setState({ rateCodeIds, areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, (byChannel ? rates : this.props.roomCategories.flatMap(rc => rc.rates))) });
        }
    }

    //Select or remove all rates from given room category
    selectRatesByRoomCat = (roomCategory, isToRemove) => {
        let { rateCodeIds, rates } = this.state;

        if (isToRemove) {
            rateCodeIds = rateCodeIds.filter(el =>
                !roomCategory.rates.some(rate => rate.id === el && (rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true))
            );
        }
        else {
            roomCategory.rates
                .filter(r => !rateCodeIds.includes(r.value) && (r.hasOwnProperty('hidden') ? r.hidden === false ? true : false : true))
                .forEach(r => {
                    rateCodeIds.push(r.value);
                });
        }

        this.setState({ rateCodeIds, areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, rates) });
    }

    //Select or remove all rates from given rates
    selectRatesByRates = (rateList, isToRemove) => {
        let { rateCodeIds, rates } = this.state;

        if (isToRemove) {
            rateCodeIds = rateCodeIds.filter(el =>
                !rateList.some(rate => rate.id === el && (rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true))
            );
        }
        else {
            rateList
                .filter(r => !rateCodeIds.includes(r.value) && (r.hasOwnProperty('hidden') ? r.hidden === false ? true : false : true))
                .forEach(r => {
                    rateCodeIds.push(r.value);
                });
        }

        this.setState({ rateCodeIds, areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, rates) });
    }

    //Select or remove all rates
    selectAllRates = (isToRemove) => {
        let { rateCodeIds } = this.state;
        const rates = this.props.roomCategories.flatMap(rc => rc.rates);

        if (isToRemove) {
            rateCodeIds = rateCodeIds.filter(el =>
                !rates.some(rate => rate.id === el && (rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true))
            );
        }
        else {
            rates
                .filter(r => r.hasOwnProperty('hidden') ? r.hidden === false ? true : false : true)
                .forEach(r => {
                    rateCodeIds.push(r.value);
                });
        }

        this.setState({ rateCodeIds, areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, rates) });
    }

    handleMenuChoice(showPageIdx, hidePageIdx, mode) {
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ mode: mode }, _ => this.handleChangeStep(showPageIdx, hidePageIdx));
    }

    handleChangeRoomCategory(combo, evt) {
        if (evt) {
            this.setState({ roomCategoryId: combo && combo.value });
        }
    }

    resetRatesAndChannels(showPageIdx, hidePageIdx) {
        this.setState({ rateCodeIds: [], channels: [], areAllRatesSelected: false },
            _ => this.handleChangeStep(showPageIdx, hidePageIdx));
    }

    resetRestrictions(showPageIdx, hidePageIdx) {
        this.setState({ stopSales: null, closedToArrival: null, closedToDeparture: null, minimumStayLen: null, maximumStayLen: null, applyRestrictionsToChannels: false, areAllRatesSelected: this.areAllRatesSelected(this.state.rateCodeIds, this.state.rates) },
            _ => this.handleChangeStep(showPageIdx, hidePageIdx));
    }

    handleChangeStep(showPageIdx, hidePageIdx) {
        const { steps } = this.state;
        steps[hidePageIdx] = false;
        steps[showPageIdx] = true;
        this.setState({ steps });
    }

    handleChangeChannel(combo, evt) {
        if (evt) {
            const { channelInstances, heyBeAndGoogleChannels, roomCategories } = this.props;
            let { channels, rateCodeIds } = this.state;

            if (evt.action === "select-option") {
                //Add
                if (evt.option && heyBeAndGoogleChannels.some(el => el === evt.option.value)) {
                    channels = channels.concat(heyBeAndGoogleChannels);
                }
                else {
                    channels = combo ? combo.map(c => c.value) : [];
                }
            }
            else {
                //Remove
                if (evt.removedValue && heyBeAndGoogleChannels.some(el => el === evt.removedValue.value)) {
                    channels = channels.filter(ci => !heyBeAndGoogleChannels.some(el => el === ci));
                }
                else {
                    channels = combo ? combo.map(c => c.value) : [];
                }
            }

            const channelInstancesFiltered = channelInstances.filter(ci => channels.find(c => c === ci.value) !== undefined);
            const rateCodeIdsFiltered = rateCodeIds.filter(rc => channelInstancesFiltered.find(ci => ci.rateCodeChannels.find(rcc => rcc.rateCodeId === rc) !== undefined) !== undefined);
            const roomCategoriesFiltered = roomCategories.filter(rc => rc.rates.find(r => channelInstancesFiltered.find(c => c.rateCodeChannels.find(rcc => rcc.rateCodeId === r.value) !== undefined) !== undefined) !== undefined);
            const rates = roomCategoriesFiltered.flatMap(rc => rc.rates?.filter(r => channelInstancesFiltered.find(c => c.rateCodeChannels.find(rcc => rcc.rateCodeId === r.value) !== undefined) !== undefined));

            this.setState({
                channels: channels,
                rateCodeIds: rateCodeIdsFiltered,
                selectedChannels: channelInstancesFiltered,
                roomCategoriesFiltered: roomCategoriesFiltered,
                rates: rates,
                areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, rates)
            });
        }
    }

    selectAllChannels = () => {
        const { channelInstances, roomCategories } = this.props;
        let { channels, areAllChannelsSelected, rateCodeIds, selectedChannels, rates, roomCategoriesFiltered } = this.state;

        if (channelInstances.length === selectedChannels.length) {
            channels = [];
            rateCodeIds = [];
            selectedChannels = [];
            roomCategoriesFiltered = [];
        }
        else {
            channels = channelInstances.map(rate => rate.value);
            selectedChannels = channelInstances;
            roomCategoriesFiltered = roomCategories.filter(rc => rc.rates.find(r => channelInstances.find(c => c.rateCodeChannels.find(rcc => rcc.rateCodeId === r.value) !== undefined) !== undefined) !== undefined);
        }

        rates = roomCategoriesFiltered.flatMap(rc => rc.rates?.filter(r => selectedChannels.find(c => c.rateCodeChannels.find(rcc => rcc.rateCodeId === r.value) !== undefined) !== undefined));

        this.setState({ channels, areAllChannelsSelected, rateCodeIds, selectedChannels, rates, roomCategoriesFiltered, areAllRatesSelected: this.areAllRatesSelected(rateCodeIds, rates) });
    }

    areAllRatesSelected = (rateCodeIds, rates) => {
        return rateCodeIds.length > 0 && this.getRatesFiltered(rates).every(r => rateCodeIds.some(el => el === r.value));
    }

    getRatesFiltered = (rates) => {
        return rates.filter(rate => rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true);
    }

    removeDate = (index) => {
        const { dates, startDateDay } = this.state;
        dates.splice(index, 1);
        const date = dates.find(d => d.startDate === startDateDay);

        this.setState({ dates, startDateDay: date ? date.startDate : dates.length > 0 ? dates[0].startDate : moment().format("YYYY-MM-DD") });
    }

    isDayBlocked = (day) => {
        const { dates } = this.state;
        const val = dates.find(date => {
            if (moment(date.startDate).dayOfYear() + moment(date.startDate).year() <= day.dayOfYear() + day.year()) {
                if (day.dayOfYear() + day.year() <= moment(date.endDate).dayOfYear() + moment(date.endDate).year()) {
                    if (day.year() === moment(date.startDate).year() && day.year() === moment(date.endDate).year()) {
                        return true;
                    };
                };
            };
            return false;
        });
        return val;
    }

    filterRates = (e, byChannel, isLabel) => {
        const { rateCodeIds } = this.state;
        if (isLabel) {
            this.props.searchRates(e, isLabel, rateCodeIds)
        } else {
            const { value } = e.target;
            const rates = byChannel ? this.state.rates : this.props.roomCategories.flatMap(rc => rc.rates);

            this.setState({
                areAllRatesSelected: this.areAllRatesSelected(this.state.rateCodeIds, rates)
            }, () => this.props.searchRates(value, false, rateCodeIds));
        }
    }

    handleDates = (startDate, endDate) => {
        if (startDate !== null && endDate !== null) {
            const { dates } = this.state;
            dates.push({
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            });
            this.setState({ dates, startDate: null, endDate: null, startDateDay: dates[dates.length - 1].startDate });
        }
        else {
            this.setState({ startDate, endDate });
        }
    }


    render() {
        const { roomCategories, channelInstances, clearRatesSearch } = this.props;

        const { block, error, startDate, endDate, rateCodeIds, steps, mode, channels, stopSales, closedToArrival, closedToDeparture, minimumStayLen, maximumStayLen, minAdvancedBookingOffset,
            maxAdvancedBookingOffset, applyRestrictionsToChannels, selectedChannels, roomCategoriesFiltered, rates, areAllRatesSelected, allowRestrictionsByChannels, applyAllChannelsModal } = this.state;

        const validWeekDays = [
            {
                value: "Mon",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />
            },
            {
                value: "Tue",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />
            },
            {
                value: "Wed",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />
            },
            {
                value: "Thu",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />
            },
            {
                value: "Fri",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />
            },
            {
                value: "Sat",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />
            },
            {
                value: "Sun",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />
            }
        ];

        return (
            <Card className="border-0 h-100">
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>

                    {applyAllChannelsModal ?
                        <ConfirmRestrictionsToChannelsModal
                            isOpen={applyAllChannelsModal}
                            toggleModal={_ => this.setState({ applyAllChannelsModal: false })}
                            confirmFunction={_ => this.updateRestrictionsBulk(true)}
                            cancelFunction={_ => this.updateRestrictionsBulk(false)}
                        />
                    : ''}

                    <CardBody className="p-2">
                        <form ref={this.form} >
                            <Row className="mb-1 align-items-center">
                                <Col className="col-2">
                                    <FormattedMessage id="MajorBulkUpdateModal.Dates" />
                                </Col>
                                <Col className="col-3 pl-0">
                                    <CustomDateRangePicker
                                        startDate={startDate}
                                        startDateId="reststartDate"
                                        isOutsideRange={day => day.isBefore(moment(), 'day') || day > moment().add(2, 'years')}
                                        endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                                        endDateId="restsendDate"
                                        numberOfMonths={2}
                                        showDefaultInputIcon={true}
                                        minimumNights={0}
                                        isDayBlocked={this.isDayBlocked}
                                        onDatesChange={(startDate, endDate) => this.handleDates(startDate, endDate)}
                                        showYearOptions={{ pastYears: false, futureYears: true }}
                                        required={this.state.dates.length === 0}
                                    />
                                </Col>
                                <Col className="col-2">
                                    <FormattedMessage id="MajorBulkUpdateModal.Weekdays" />
                                </Col>
                                <Col className="col-3">
                                    <ButtonGroup name="weekdays" size="sm">
                                        { validWeekDays && validWeekDays.map((weekDay, key) =>
                                            <Button key={key} className={this.state.weekdays.find(el => el === weekDay.value) ? 'btn btn-host' : 'btn'} onClick={() => this.onClickWeekDays(weekDay.value)}>
                                                {weekDay.label}
                                            </Button>
                                        ) }
                                    </ButtonGroup>
                                </Col>
                            </Row>

                            <Row className="mb-2">
                                <Col className="col-2">
                                    <span>
                                        <FormattedMessage id="BulkUpdate.Dates" />:
                                    </span>
                                </Col>
                                <Col>
                                    <Row>
                                        {this.state.dates?.map((date, key) =>
                                            <div key={key} >
                                                <Badge className="p-2 my-1 mr-2" color="primary">
                                                    <span>
                                                        <span style={{ cursor: 'pointer' }} onClick={_ => this.setState({ startDateDay: date.startDate })}>
                                                            <FormattedMessage id="BookingPromotionCard.From" /> {`${date.startDate}`} <FormattedMessage id="BookingPromotionCard.To" /> {`${date.endDate}`}
                                                        </span>
                                                        <span className="text-right ml-3" onClick={_ => this.removeDate(key)} style={{ cursor: 'pointer' }}>
                                                            <i className="fas fa-times text-white" />
                                                        </span>
                                                    </span>
                                                </Badge>
                                            </div>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </form>
                        <hr />
                        <Row>
                            <Col className="py-1">
                                <ul className="steps">
                                    <li className={'step ' + (steps[0] ? ' step-active' : '')}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Step1" />
                                            </span>
                                            <span className="step-circle" />
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.ChooseOption" />
                                            </span>
                                        </div>
                                    </li>
                                    <li className={'step ' + (steps[1] ? ' step-active' : '')}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Step2" />
                                            </span>
                                            <span className="step-circle" />
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Rates" />
                                            </span>
                                        </div>
                                    </li>
                                    <li className={'step ' + (steps[2] ? ' step-active' : '')}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Step3" />
                                            </span>
                                            <span className="step-circle" />
                                            <span className="step-text small">
                                                <FormattedMessage id="BulkUpdate.Restrictions" />
                                            </span>
                                        </div>
                                    </li>
                                    <li className={'step ' + (steps[3] ? ' step-active' : '')}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Step4" />
                                            </span>
                                            <span className="step-circle" />
                                            <span className="step-text small">
                                                <FormattedMessage id="MajorBulkUpdateModal.Confirm" />
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <div className="mt-3" style={{ display: steps[0] ? 'block' : 'none', height: '100%' }}>
                            <Row className="mb-2">
                                <Col className="col-1" />
                                <Col className="col-5">
                                    <Button className="btn btn-host" size="lg" block onClick={_ => this.handleMenuChoice(1, 0, 1)}>
                                        <FormattedMessage id="MajorBulkUpdateModal.ApplyRestrictionsToDefaultRates" />
                                    </Button>
                                </Col>
                                <Col className="col-5">
                                    <Button className="btn btn-host" size="lg" block onClick={_ => this.handleMenuChoice(1, 0, 2)} disabled={allowRestrictionsByChannels}>
                                        <FormattedMessage id="MajorBulkUpdateModal.ApplyRestrictionsToRatesByChannels" />
                                    </Button>
                                </Col>
                                <Col className="col-1" />
                            </Row>
                        </div>
                        {steps[1] ?
                            <div style={{ height: '100%' }}>
                                <Row className="mb-2">
                                    <Col>
                                        <Button className="btn btn-host btn-sm" onClick={_ => { this.resetRatesAndChannels(0, 1); clearRatesSearch() }}>
                                            <FormattedMessage id="MajorBulkUpdateModal.Back" />
                                        </Button>
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm" onClick={_ => { this.handleChangeStep(2, 1); clearRatesSearch() }} disabled={rateCodeIds.length === 0}>
                                            <FormattedMessage id="MajorBulkUpdateModal.Forward" />
                                        </Button>
                                    </Col>
                                </Row>
                                { mode === 1 ?
                                    <MajorBulkUpdateModalChooseRates
                                        roomCategories={roomCategories}
                                        selectAllRates={this.selectAllRates}
                                        areAllRatesSelected={this.state.areAllRatesSelected}
                                        selectRatesByRoomCat={this.selectRatesByRoomCat}
                                        handleRateChange={this.handleRateChange}
                                        searchRates={this.filterRates}
                                        rateCodeIds={rateCodeIds}
                                        intl={this.props.intl}
                                        labels={this.props.labels}
                                        hideLabels={this.props.hideLabels}
                                    />
                                : <div /> }
                                {
                                    mode === 2 ?
                                        <MajorBulkUpdateModalChooseRatesByChannels
                                            channelInstances={channelInstances}
                                            rateCodeIds={rateCodeIds}
                                            searchRates={this.filterRates}
                                            intl={this.props.intl}
                                            selectedChannels={selectedChannels}
                                            roomCategoriesFiltered={roomCategoriesFiltered}
                                            rates={rates}
                                            areAllRatesSelected={areAllRatesSelected}
                                            handleChangeChannel={this.handleChangeChannel}
                                            handleRateChange={this.handleRateChange}
                                            selectAllChannels={this.selectAllChannels}
                                            selectRatesByRates={this.selectRatesByRates}
                                            areAllChannelsSelected={this.state.areAllChannelsSelected}
                                            labels={this.props.labels}
                                            hideLabels={this.props.hideLabels}
                                        />
                                        :
                                        <div />
                                }
                            </div>
                        : ''}
                        <div style={{ display: steps[2] ? 'block' : 'none', height: '100%' }}>
                            <Row className="mb-2">
                                <Col>
                                    <Button className="btn btn-host btn-sm" onClick={_ => this.resetRestrictions(1, 2)}>
                                        <FormattedMessage id="MajorBulkUpdateModal.Back" />
                                    </Button>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" onClick={_ => this.handleChangeStep(3, 2)} disabled={!(minimumStayLen != undefined || maximumStayLen != undefined || stopSales != undefined || closedToDeparture != undefined || closedToArrival != undefined || minAdvancedBookingOffset != undefined || maxAdvancedBookingOffset != undefined)}>
                                        <FormattedMessage id="MajorBulkUpdateModal.Forward" />
                                    </Button>
                                </Col>
                            </Row>
                            <MajorBulkUpdateModalChooseRestrictions
                                setComboBox={this.setComboBox}
                                setMinimumStayLen={evt => this.setState({ minimumStayLen: evt.target.value })}
                                setMaximumStayLen={evt => this.setState({ maximumStayLen: evt.target.value })}
                                setMinAdvancedBookingOffset={evt => this.setState({ minAdvancedBookingOffset: evt.target.value })}
                                setMaxAdvancedBookingOffset={evt => this.setState({ maxAdvancedBookingOffset: evt.target.value })}
                                stopSales={stopSales}
                                closedToArrival={closedToArrival}
                                closedToDeparture={closedToDeparture}
                                minimumStayLen={minimumStayLen}
                                maximumStayLen={maximumStayLen}
                                minAdvancedBookingOffset={minAdvancedBookingOffset}
                                maxAdvancedBookingOffset={maxAdvancedBookingOffset}
                            >
                                {
                                    channels.length === 0 ?
                                        <Row className="mb-2">
                                            <Col>
                                                <CustomInput type="switch" name="applyRestrictionsToChannels" id="applyRestrictionsToChannels" bsSize="sm" className="text-sm " label={<FormattedMessage id="BulkUpdate.RestrictionsToChannels" />} checked={applyRestrictionsToChannels} onChange={evt => this.setState({ applyRestrictionsToChannels: evt.target.checked })} />
                                            </Col>
                                        </Row>
                                        :
                                        <div />
                                }
                            </MajorBulkUpdateModalChooseRestrictions>
                        </div>
                        <div style={{ display: steps[3] ? 'block' : 'none', height: '100%' }}>
                            {
                                steps[3] ?
                                    <div>
                                        <Row className="mb-2">
                                            <Col>
                                                <Button className="btn btn-host btn-sm" onClick={_ => this.handleChangeStep(2, 3)}>
                                                    <FormattedMessage id="MajorBulkUpdateModal.Back" />
                                                </Button>
                                            </Col>
                                            <Col className="text-right">
                                                <Button className="btn btn-sm btn-host float-right" onClick={this.restrictionValidations} disabled={!this.state.dates || this.state.dates.length === 0}>
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        <MajorBulkUpdateModalConfirm
                                            roomCategories={roomCategories}
                                            rateCodeIds={rateCodeIds}
                                            channelInstances={channelInstances}
                                            channels={channels}
                                            stopSales={stopSales}
                                            closedToArrival={closedToArrival}
                                            closedToDeparture={closedToDeparture}
                                            minimumStayLen={minimumStayLen}
                                            maximumStayLen={maximumStayLen}
                                            minAdvancedBookingOffset={minAdvancedBookingOffset}
                                            maxAdvancedBookingOffset={maxAdvancedBookingOffset}
                                        />
                                    </div>
                                    :
                                    <div />
                            }
                        </div>
                    </CardBody>
                </BlockUi>
            </Card>
        );
    };
}
export default injectIntl(MajorBulkUpdate);
